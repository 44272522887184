import { useState, useEffect, useRef } from 'react';
// router
import { Link } from 'react-router-dom';
// material
import {
  Grid,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Tabs,
  Tab,
  Box,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-hot-toast';
// i18n
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import TabPanel, { a11yProps } from '../components/TabPanel';
import { StyledContainer } from '../components/styled-global/StyledGlobal';
//
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// store
import { userStore } from '../store/userStore';
// api
import api from '../service/api';
import { ID_SUPERUSER } from '../enum/SuperUser';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userList, user, theme) {
  return {
    fontWeight: user.indexOf(userList) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function ManageAccessUser() {
  const [isLoading, setisLoading] = useState(true);
  const [value, setValue] = useState('');
  const [usersName, setUsersName] = useState([]);
  const [usersObject, setUsersObject] = useState([]);
  const [user, setUser] = useState('');
  const [userId, setUserId] = useState(0);
  const [listRolesName, setRolesName] = useState([]);
  // const [roles, setRoles] = useState([]);
  const [valueTab, setValueTab] = useState(1);
  const UserId = useRef(null);
  // const getUserPermissions = userStore((state) => state.getUser);
  const theme = useTheme();
  const { t } = useTranslation();
  const prevRoleChecked = useRef();
  const prevRoleIDChecked = useRef();
  const getUserPermissions = userStore((state) => state.getUser);

  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  const arrayIdsPerfilForDeleteAll = ['1', '2', '3'];

  // async function getUser() {
  //   const userPermissions = await getUserPermissions();
  //   setRoles(userPermissions.roles);
  // }
  // useEffect(() => {
  //   getUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  async function requestApiUsers() {
    setisLoading(true);
    try {
      const responseUsers = await api.get('/usersall');
      // eslint-disable-next-line prefer-destructuring
      const users = responseUsers.data.data.users;
      setUsersObject(users);
      setisLoading(false);

      if (accountCache !== undefined) {
        // eslint-disable-next-line consistent-return, array-callback-return
        const listUser = users?.map((usuario) => {
          if (usuario?.idusuario !== accountCache.idusuario) {
            return usuario.usuarionome;
          }
        });
        setUsersName(listUser);
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function getIdUser() {
    const userPermissions = await getUserPermissions();

    UserId.current = userPermissions.idusuario;
  }

  useEffect(() => {
    getIdUser();
  });

  async function requestApiRoles() {
    setisLoading(true);
    try {
      const responseRoles = await api.get('/perfis');
      // eslint-disable-next-line prefer-destructuring, no-unused-vars
      const roles = responseRoles.data.data.perfis;

      // Para fazer a tradução, setarei manualmente as três roles
      const listRoles = [t('manage-access.administrator'), t('manage-access.manager'), t('manage-access.common')];
      //  const listRoles = [AdminObj,ManegerObj, CommomObj];

      setRolesName(listRoles);

      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  useEffect(() => {
    requestApiUsers();
    requestApiRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUsersName, setRolesName, accountCache]);

  const handleChangeUser = async (event) => {
    const {
      target: { value },
    } = event;
    // eslint-disable-next-line arrow-body-style
    const user = usersObject.find((user) => {
      return user.usuarionome === value;
    });
    setUserId(user.idusuario);

    const responseUserRoles = await api.get(`/userperfis/${user.usuarioemail}`);
    const rolesApi = responseUserRoles.data.data.user.roles;
    if (rolesApi.length === 0) {
      setValue(3);
      prevRoleIDChecked.current = 3;
      await api.post(`/users/${userId}`, {
        roles: [String(prevRoleIDChecked.current)],
      });
    }
    if (rolesApi.length > 0) {
      setValue(rolesApi[0].idperfil);
      prevRoleIDChecked.current = rolesApi[0].idperfil;
    }

    const listChecked = {};
    listRolesName.forEach((nameRole) => {
      const exists = rolesApi.map((perfil) => perfil.perfilnome).includes(nameRole);
      if (exists) {
        listChecked[nameRole] = true;
        prevRoleChecked.current = nameRole;
      } else {
        listChecked[nameRole] = false;
      }
    });

    setUser(typeof value === 'string' ? value : value);
  };

  const handleChange = async (event) => {
    try {
      const responseDelete = await api.delete(`/users/${userId}`, {
        data: { roles: arrayIdsPerfilForDeleteAll },
      });
      const response = await api.post(`/users/${userId}`, {
        roles: [String(event.target.value)],
      });

      if (response.status === 201 && responseDelete.status === 200) {
        setValue(event.target.value);
        prevRoleChecked.current = String(event.target.value);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(Number(newValue));
  };

  return (
    <Page title={t('sidebar.manageacess')}>
      <DashboardNavbar />
      <DashboardSidebar />
      <StyledContainer>
        {isLoading && (
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: '99',
            }}
          >
            <CircularProgress size="3rem" />
          </Box>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('manage-access.title')}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={Number(valueTab)} onChange={handleChangeTab} aria-label="basic tabs example">
              {UserId?.current !== ID_SUPERUSER || UserId?.current === null ? (
                <></>
              ) : (
                <Tab component={Link} label={t('manage-access.tab1')} to="/managerprofile" {...a11yProps(0)} />
              )}
              <Tab component={Link} label={t('manage-access.tab2')} to="/manageruser" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={Number(valueTab)} index={1}>
            <Grid container spacing={3} sx={{ marginTop: '2%' }}>
              <Card sx={{ width: '100%' }}>
                <Scrollbar>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          {/* Usuário */}
                          <TableCell>
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="user-acess-label">{t('manage-access.pleacehold-user')}</InputLabel>
                              <Select
                                labelId="user-acess-label"
                                id="user-acess"
                                value={user === '' ? '' : user}
                                onChange={handleChangeUser}
                                input={<OutlinedInput label="Usuário" />}
                                MenuProps={MenuProps}
                              >
                                {usersName.map((userList, index) => {
                                  if (userList !== accountCache.usuarionome) {
                                    return (
                                      <MenuItem
                                        key={`${userList}_${index}`}
                                        value={userList}
                                        style={getStyles(userList, user, theme)}
                                      >
                                        {userList}
                                      </MenuItem>
                                    );
                                  }
                                  return null;
                                })}
                              </Select>
                            </FormControl>
                          </TableCell>

                          {/* Role */}
                          <TableCell>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                              >
                                {listRolesName.map((valueLabel, i) => (
                                  <FormControlLabel
                                    key={valueLabel}
                                    value={i + 1}
                                    control={<Radio />}
                                    label={valueLabel}
                                    labelPlacement="start"
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Grid>
          </TabPanel>
        </Box>
      </StyledContainer>
    </Page>
  );
}
