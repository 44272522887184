// @mui
import { TextField, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function NewFolder({ handleClose, newFolderName, setNewFolderName, onClickFunction }) {
  const { t } = useTranslation();
  return (
    <div>
      <TextField
        autoFocus
        value={newFolderName}
        onChange={(event) => {
          setNewFolderName(event.target.value);
        }}
      />
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: '15px' }}>
        <Button variant="contained" onClick={onClickFunction} color="success">
          {t('repo.new-folder-modal.create')}
        </Button>
        <Button variant="contained" onClick={handleClose}>
          {t('repo.new-folder-modal.cancel')}
        </Button>
      </DialogActions>
    </div>
  );
}
