/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Button, Box, } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import i18next from 'i18next'; 
import { moduleProperties } from './functionsManagementNode';

export default function PopoverHelp() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Button
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      variant="contained"
      color="info"
      sx={{ display: 'flex', gap: '0.2rem' }}
    >
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
       {i18next.t('types-modules-help.help')}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ padding: '1rem' }}>
          {moduleProperties.map((node) => (
            <Box key={node.type} sx={{ display: 'flex', gap: '0.2rem' }}>
              <img width="30" src={node.img} />
              <Typography sx={{ p: 1 }}> - </Typography>
              <Typography sx={{ p: 1 }}>{node.descHelp}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
      <QuestionMarkIcon sx={{ color: 'white', borderRadius: '50%', border: '2px solid white' }} />
    </Button>
  );
}
