import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { toast } from 'react-hot-toast';
import { FormGroup, InputLabel, Stack, Switch, Avatar, Tooltip, Modal, Autocomplete } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CurrentModuleContext } from '../../../../store/CurrentModuleContext';
import { ModuleStatus } from '../../../../enum/ModuleStatusEnum';
import { moduleProperties } from '../../utils/functionsManagementNode';
import api from '../../../../service/api';
import { ConfirmApproveModal } from './Modal/ConfirmApproveModal';
import { ConfirmProjectStatusModal } from '../../../tables/table-components/projects/modals/ConfirmProjectStatusModal';
import Label from '../../../Label';
import { ConfirmReturnModal } from './Modal/ConfirmReturnModal';
import ModalRefAnexo from './Modal/ModalRefAnexo';
// store
import { userStore } from '../../../../store/userStore';
import ModalFormulario from './Modal/ModalFormulario';
import { ProjectStatus } from '../../../../enum/ProjectStatusEnum';
import ModalReferenciaExecution from './Modal/ModalReferenciaExecution';

const stylesFlex = {
  display: 'flex',
  justifyContent: 'start',
  width: '25%',
  gap: '0.3rem',
};

const containerFlex = {
  display: 'flex',
  justifyContent: 'space-between',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 900,
    xl: 1200,
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#FECD0E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 50,
  height: 50,
  border: `2px solid ${theme.palette.background.paper}`,
}));

export const NodeSelectTimeline = createContext();

export default function BasicModalTimeline({ isOpen, handleClose, node, nodes, edges, idprojeto, project, roleAdmin }) {
  // const [open, setOpen] = React.useState(isOpen);
  const [infos, setInfos] = useState([]);
  const [nodesNotActionForChangeStatus, setNodesNotActionForChangeStatus] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [openModalCancelModal, setOpenModalCancelModal] = useState(false);
  const [returnModalOpen, setReturnConfirmModalOpen] = useState(false);
  const [propertiesConfirm, setPropertiesConfirm] = useState({});
  const [statusProject, setStatusProject] = useState(-1);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [annexs, setAnnexs] = useState([]);

  const [deadLineBack, setDeadLineBack] = useState();
  const [checked, setChecked] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [blockPriv, setBlockPriv] = useState(true);

  const currentModuleCtx = useContext(CurrentModuleContext);
  const getUser = userStore((state) => state.getUser);
  const User = useRef();

  async function getIdUser() {
    const userPermissions = await getUser();
    User.current = userPermissions;
  }

  useEffect(() => {
    getIdUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const currentUser = JSON.parse(localStorage.getItem('User'));

  const findLabelParent = (parentNode, nodes) => {
    const parentNodeFind = nodes.find((node) => node.id === parentNode);
    if (parentNodeFind !== undefined) return parentNodeFind.data.label;
    return '';
  };

  // eslint-disable-next-line consistent-return
  async function getDate() {
    try {
      const response = await api.get(`/modules/timelimit/${node.id}/${idprojeto}`, {});
      const { tempolimite } = response.data.data;
      if (response.status === 200) {
        setDeadLineBack(tempolimite);
        return tempolimite;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        return i18next.t('infos-flow.no-deadline');
      }
    }
  }

  // eslint-disable-next-line consistent-return
  const checkDeadLineOfNode = () => {
    if (deadLineBack !== undefined) {
      if (
        node.status === ModuleStatus.awaitexecute &&
        deadLineBack.split(' ')[0] === '0' &&
        deadLineBack.split(' ')[2] === '00:00:00'
      )
        return (
          <Label variant="ghost" color="error">
            {i18next.t('statusNodes.overdue')}
          </Label>
        );

      if (node.status === ModuleStatus.awaitexecute)
        return (
          <Label variant="ghost" color="success">
            {i18next.t('statusNodes.ontime')}
          </Label>
        );

      if (node.status === ModuleStatus.executed)
        return (
          <Label variant="ghost" color="success">
            {i18next.t('statusNodes.done')}
          </Label>
        );
      if (node.status === ModuleStatus.notexecuted)
        return (
          <Label variant="ghost" color="info">
            {i18next.t('statusNodes.waiting')}
          </Label>
        );
      if (node.status === ModuleStatus.canceled)
        return (
          <Label variant="ghost" color="error">
            {i18next.t('projects.project-status.canceled')}
          </Label>
        );
    }
  };

  const checaRespUser = (arrresp) => {
    if (roleAdmin || project.projetoiniciador.idusuario === User.current?.idusuario) {
      setHidden(false);
    } else if (arrresp.length > 0) {
      const findUserResp = arrresp.includes(User.current?.idusuario);
      setHidden(!findUserResp);
    }
  };

  const checaPrivacidadeUser = (arrprivacidade) => {
    if (roleAdmin || project.projetoiniciador.idusuario === User.current?.idusuario) {
      setBlockPriv(false);
    } else if (arrprivacidade.length > 0) {
      const findUserResp = arrprivacidade.includes(User.current?.idusuario);
      setBlockPriv(!findUserResp);
    }
  };

  const checkInicializador = () => {
    if (project.projetoiniciador.idusuario === User.current?.idusuario) {
      return true;
    }
    return false;
  };

  // eslint-disable-next-line consistent-return
  const checkMethodsNodeVisible = () => {
    if (node.status === ModuleStatus.awaitexecute && project.projetostatus === ProjectStatus.InProgress) {
      if (node.id === 'a' && (checkInicializador() || roleAdmin)) {
        return (
          <Box sx={stylesFlex}>
            {findModal(node)}
            {annexs.length > 0 && <ModalRefAnexo annexs={annexs} userCurrent={User.current} />}
            {<ModalReferenciaExecution key={`modal-Ref-Execution-node-${node.id}`} userCurrent={User.current} />}
          </Box>
        );
      }
      if (node.id === 'a' && !checkInicializador()) {
        return <></>;
      }
      if (!hidden) {
        return (
          <Box sx={stylesFlex}>
            {findModal(node)}
            {annexs.length > 0 && <ModalRefAnexo annexs={annexs} userCurrent={User.current} />}
            {<ModalReferenciaExecution key={`modal-Ref-Execution-node-${node.id}`} userCurrent={User.current} />}
          </Box>
        );
      }
    }

    if (node.status === ModuleStatus.awaitexecute && project.projetostatus !== ProjectStatus.InProgress) {
      if (!hidden || !blockPriv) {
        return (
          <Box sx={stylesFlex}>
            {node.typeNode === 'FORM' && <ModalFormulario key={`modal-form-${node.id}`} fieldAble />}
            {annexs.length > 0 && <ModalRefAnexo annexs={annexs} />}
            {<ModalReferenciaExecution key={`modal-Ref-Execution-node-${node.id}`} userCurrent={User.current} />}
          </Box>
        );
      }
    }

    if (node.status === ModuleStatus.awaitexecute && (!hidden || !blockPriv)) {
      return (
        <Box sx={stylesFlex}>
          {node.typeNode === 'FORM' && <ModalFormulario key={`modal-form-${node.id}`} fieldDisable />}
          {annexs.length > 0 && <ModalRefAnexo annexs={annexs} userCurrent={User.current} />}
          {<ModalReferenciaExecution key={`modal-Ref-Execution-node-${node.id}`} userCurrent={User.current} />}
        </Box>
      );
    }

    if (node.status === ModuleStatus.executed && (!hidden || !blockPriv)) {
      return (
        <Box sx={stylesFlex}>
          {node.typeNode === 'FORM' && <ModalFormulario key={`modal-form-${node.id}`} fieldDisable />}
          {annexs.length > 0 && <ModalRefAnexo annexs={annexs} userCurrent={User.current} />}
          {<ModalReferenciaExecution key={`modal-Ref-Execution-node-${node.id}`} userCurrent={User.current} />}
        </Box>
      );
    }

    if (node.status === ModuleStatus.canceled) {
      if (!hidden || !blockPriv) {
        return (
          <Box sx={stylesFlex}>
            {node.typeNode === 'FORM' && <ModalFormulario key={`modal-form-${node.id}`} fieldAble />}
            {annexs.length > 0 && <ModalRefAnexo annexs={annexs} />}
            {<ModalReferenciaExecution key={`modal-Ref-Execution-node-${node.id}`} userCurrent={User.current} />}
          </Box>
        );
      }
    }
  };

  const getselectAreasAndUsers = async (id, idprojeto) => {
    try {
      const response = await api.get(`/modules/resp/${id}/${idprojeto}`);
      setAreas([...response.data.data.responsible.area]);
      setUsers([...response.data.data.responsible.usuario]);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  useEffect(() => {
    const load = async () => {
      const responseModule = await api.get(`modules/module/${node.id}/${idprojeto}`);
      const { moduloprivacidade } = responseModule.data.data.module;
      const { moduloresponsavel } = responseModule.data.data.module;
      const { annexs } = responseModule.data.data.module;
      setAnnexs(annexs);
      checaRespUser(moduloresponsavel.userslinked);
      checaPrivacidadeUser(moduloprivacidade.userslinked);
    };
    load();
    getselectAreasAndUsers(node.id, idprojeto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  const filterNodesParent = (nodeSelect) => {
    const nodesParent = nodes.filter((nod) => nod.parentNode === nodeSelect.parentNode && nod.categoryNode !== 'ACAO');
    nodesParent?.forEach((node) => {
      if (node.status === ModuleStatus.awaitexecute) {
        const edgeNodeConnect = edges.find((edge) => edge.target === node.id);
        const nodePreviusStage = nodes.find(
          (nod) => nod.id === edgeNodeConnect?.source && nod.status === ModuleStatus.executed
        );
        if (nodePreviusStage) {
          setNodesNotActionForChangeStatus(nodesParent);
        }
      }
    });
  };
  useEffect(() => {
    filterNodesParent(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  useEffect(() => {
    const loadModal = async () => {
      currentModuleCtx.setIdModule(node.id);

      const information = [
        {
          name: i18next.t('infos-flow.stage-node'),
          value: findLabelParent(node.parentNode, nodes),
        },
        {
          name: i18next.t('infos-flow.category-node'),
          value: (() => moduleProperties.find((nds) => nds.typeBack === node.typeNode).categ)(),
        },
        {
          name: i18next.t('infos-flow.module-type'),
          value: (() => moduleProperties.find((nds) => nds.typeBack === node.typeNode).type)(),
        },
        {
          name: i18next.t('infos-flow.deadline'),
          value: await getDate(),
        },
      ];
      if (node.status === ModuleStatus.notexecuted || node.status === ModuleStatus.executed) {
        information.pop();
        const deadLineSet = {
          name: 'Prazo definido',
          value: `${node.tempolimite === 0 ? `${i18next.t('infos-flow.no-deadline')}` : node.tempolimite}`,
        };
        information.push(deadLineSet);
      }

      if (node.categoryNode !== 'ACAO') information.pop();
      setInfos(information);

      if (node.typeNode === 'UNIAO') {
        const propsModule = node.properties;
        if (propsModule.UNIAO.logic === 'OR') setChecked(false);
        else setChecked(true);
      }
    };
    loadModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approveModal = async () => {
    if (node.typeNode === 'INICIAL') {
      const props = node.properties;
      if (props !== null && props !== undefined && props.FORM.standard === 'ON') {
        const responseProject = await api.get(`modules/form/${node.id}/${idprojeto}`);
        const { form } = responseProject.data.data;
        const arrForm = JSON.parse(form.form);
        // eslint-disable-next-line no-restricted-syntax
        for await (const data of arrForm) {
          if (data.required[0] === true && (data.answer === null || data.answer === undefined)) {
            return toast.error(`${i18next.t('flow-Modals-form-toast.emptyAnswer')}`);
          }
        }
      }
    }

    if (node.typeNode === 'FORM') {
      const responseProject = await api.get(`modules/form/${node.id}/${idprojeto}`);
      const { form } = responseProject.data.data;
      const arrForm = JSON.parse(form.form);
      // eslint-disable-next-line no-restricted-syntax
      for await (const data of arrForm) {
        if (data.required[0] === true && (data.answer === null || data.answer === undefined)) {
          return toast.error(`${i18next.t('flow-Modals-form-toast.emptyAnswer')}`);
        }
      }
    }

    if (node.typeNode === 'ANEXO') {
      const response = await api.get(`/moduleanexos/${idprojeto}`);
      const { anexos } = response.data.data;
      const annexSelected = (annex) => annex.moduloanexoescolhido === 1;
      const hasAnnexSelected = anexos.some(annexSelected);
      if (!hasAnnexSelected) {
        return toast.error(`${i18next.t('flow-Modals-choiceFiles.toast-error')}`);
      }
    }

    setPropertiesConfirm({
      title: i18next.t('flow-Modals-approve.title'),
      content: i18next.t('flow-Modals-approve.text'),
    });
    return setConfirmModalOpen(true);
  };
  const cancelModal = () => {
    setPropertiesConfirm({
      title: i18next.t('CancelProjectModal.title'),
      content: i18next.t('CancelProjectModal.text'),
    });
    setStatusProject(5);
    setOpenModalCancelModal(true);
  };
  const returnModal = () => {
    setPropertiesConfirm({
      title: i18next.t('flow-Modals-approve.return'),
      content: i18next.t('flow-Modals-approve.return-question'),
      contentCheckPoint: i18next.t('flow-Modals-approve.return-question-checkpoint'),
    });
    setReturnConfirmModalOpen(true);
  };

  const findModal = (nodeModal) => moduleProperties.find((nds) => nds.typeBack === nodeModal.typeNode).modalTimeline;

  const verificaCateg = (module) => {
    if (module.status === ModuleStatus.awaitexecute && project.projetostatus === ProjectStatus.InProgress) {
      if (
        module.categoryNode === 'ACAO' &&
        (roleAdmin ||
          checkInicializador() ||
          module.moduloresponsaveis.userslinked.some((id) => id === User.current?.idusuario))
      ) {
        return (
          <Box sx={{ display: 'flex', maxHeight: 50, gap: '0.5rem' }}>
            <Button onClick={cancelModal} color="error" variant="contained">
              {i18next.t('flow-Modals-approve.cancel')}
            </Button>
            <Button onClick={returnModal} variant="contained">
              {i18next.t('flow-Modals-approve.return')}
            </Button>
            <Button onClick={approveModal} color="success" variant="contained">
              {i18next.t('flow-Modals-approve.approve')}
            </Button>
          </Box>
        );
      }
      if (module.typeNode === 'INICIAL' && (roleAdmin || checkInicializador())) {
        return (
          <Box sx={{ display: 'flex', maxHeight: 50, gap: '0.5rem' }}>
            <Button onClick={approveModal} color="success" variant="contained">
              {i18next.t('flow-Modals-approve.approve')}
            </Button>
          </Box>
        );
      }
    }

    return <></>;
  };

  const viewExecuter = (module) => {
    if (module.data.executer) {
      return (
        <Box sx={{ display: 'flex', gap: '0.2rem' }}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={
              module.data.executer === undefined ? `${i18next.t('infos-flow.notExecuter')}` : `${module.data.executer}`
            }
          >
            <SmallAvatar alt={module.data.executer} src={module.data.executerIcon} />
          </Tooltip>
          <Typography variant="subtitle2">
            {' '}
            {i18next.t('infos-flow.executedDate')} {module.data.dateExecuted.split(' ')[0]} às{' '}
            {module.data.dateExecuted.split(' ')[1]}{' '}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const moduleTime = (module) => {
    if (module.typeNode === 'TEMPO') {
      let timeModule = 0;
      if (node.properties !== undefined && node.properties !== null) {
        const date = node.properties.TEMPO.time;
        const dateCurrent = new Date().toJSON().slice(0, 10);
        const dateInMs = new Date(date) - new Date(dateCurrent);
        const dateInDays = dateInMs / (1000 * 60 * 60 * 24);
        if (dateInDays > 0) {
          timeModule = dateInDays;
        }
      }
      return (
        <TextField
          margin="dense"
          id="date"
          label={i18next.t('flow-Modals.left-days-label')}
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={`${timeModule} ${''} ${i18next.t('flow-Modals.leftDays')}`}
          disabled
        />
      );
    }
    return <></>;
  };

  const logicUnion = () => {
    if (node.typeNode === 'UNIAO') {
      return (
        <FormGroup aria-label="position" row>
          <InputLabel id="demo-multiple-name-label" sx={{ marginRight: '2%' }}>
            {`${i18next.t('logicUnion.label')} ${''}`}
          </InputLabel>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{i18next.t('logicUnion.or')}</Typography>
            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={checked} disabled />
            <Typography>{i18next.t('logicUnion.and')}</Typography>
          </Stack>
        </FormGroup>
      );
    }
    return null;
  };

  const standardForm = (node) => {
    if (node.typeNode === 'INICIAL') {
      const props = node.properties;
      if (
        props !== null &&
        props !== undefined &&
        props.FORM.standard === 'ON' &&
        (checkInicializador() || roleAdmin)
      ) {
        if (node.status === ModuleStatus.executed) {
          return <ModalFormulario key={`modal-form-standard`} fieldDisable />;
        }

        return <ModalFormulario key={`modal-form-standard`} />;
      }
      if (props !== null && props !== undefined && props.FORM.standard === 'ON') {
        return <ModalFormulario key={`modal-form-standard`} fieldDisable />;
      }
      return null;
    }
    return null;
  };

  return (
    <div>
      <ConfirmApproveModal
        isModalOpen={confirmModalOpen}
        setModalOpen={setConfirmModalOpen}
        moduleApproved={node}
        idprojeto={idprojeto}
        propertiesConfirm={propertiesConfirm}
        onClose={handleClose}
        nodesNotActionForChangeStatus={nodesNotActionForChangeStatus}
      />

      <ConfirmProjectStatusModal
        isModalOpen={openModalCancelModal}
        setModalOpen={setOpenModalCancelModal}
        propertiesConfirm={propertiesConfirm}
        status={statusProject}
        idprojeto={idprojeto}
        project={project}
        // setProjects={setProjects}
      />
      <ConfirmReturnModal
        isModalOpen={returnModalOpen}
        setModalOpen={setReturnConfirmModalOpen}
        moduleReturns={node}
        idprojeto={idprojeto}
        propertiesConfirm={propertiesConfirm}
        onClose={handleClose}
        nodes={nodes}
        edges={edges}
      />

      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={style}>
            <Box sx={containerFlex}>
              <Box>
                <Typography
                  id="modal-modal-title"
                  variant="h2"
                  component="h1"
                  sx={{ textAlign: 'center', marginBottom: 5 }}
                >
                  {i18next.t('flow-Modals.module-infos')}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: { md: '1rem', xl: '2rem' },
                    marginBotton: '1rem',
                    width: { md: '100%', xl: '115%' },
                  }}
                >
                  {infos.map((info) => (
                    <TextField
                      margin="dense"
                      id={info.name}
                      label={info.name}
                      key={info.name}
                      type="text"
                      variant="outlined"
                      defaultValue={info.value}
                      disabled
                    />
                  ))}
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6">Status:</Typography>
                    {checkDeadLineOfNode()}
                  </Box>
                </Box>
                {node.categoryNode === 'ACAO' && (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, width: '100%' }}>
                    <Autocomplete
                      sx={{ margin: '0.5rem 0 ', width: '50%' }}
                      multiple
                      id="tags-readOnly"
                      options={users.map((option) => option.usuarionome)}
                      value={
                        users.length > 0
                          ? users.map((option) => option.usuarionome)
                          : [`${i18next.t('basicModalTimeline.notUser')}`]
                      }
                      readOnly
                      popupIcon=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={i18next.t('basicModalTimeline.usersResponse')}
                        />
                      )}
                    />
                    <Autocomplete
                      sx={{ margin: '0.5rem 0 ', width: '50%' }}
                      multiple
                      id="tags-readOnly"
                      options={areas.map((option) => option.areanome)}
                      value={
                        areas.length > 0
                          ? areas.map((option) => option.areanome)
                          : [`${i18next.t('basicModalTimeline.notArea')}`]
                      }
                      readOnly
                      popupIcon=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={i18next.t('basicModalTimeline.areasResponse')}
                        />
                      )}
                    />
                  </Box>
                )}
              </Box>
              {viewExecuter(node)}
            </Box>
            <Box sx={{ display: 'flex', gap: '.5rem', flexDirection: 'column', marginTop: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={i18next.t('flow-Modals.nameModule')}
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={node.modulotxt === '' ? `${i18next.t('basicModalTimeline.notNameNode')}` : node.modulotxt}
                disabled
              />
              {!hidden && (
                <TextField
                  margin="dense"
                  id="name"
                  label={i18next.t('flow-Modals.descriptionModule')}
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue={
                    node.modulodesc === null || node.modulodesc === ''
                      ? `${i18next.t('flow-Modals.no-description')}`
                      : node.modulodesc
                  }
                  disabled
                />
              )}
              {moduleTime(node)}
            </Box>

            {logicUnion()}

            <NodeSelectTimeline.Provider value={{ node, idprojeto, project, nodes }}>
              <Box sx={{ display: 'flex' }}>
                {checkMethodsNodeVisible()}
                {standardForm(node)}
              </Box>
              {/* {annexs.length > 0 && <ModalRefAnexo annexs={annexs} />} */}
            </NodeSelectTimeline.Provider>

            <DialogActions sx={{ justifyContent: 'end' }}>{verificaCateg(node)}</DialogActions>
          </Box>
        </>
      </Modal>
    </div>
  );
}
