/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
// protected routes
import ProtectedRoutes from './ProtectedRoutes';
import PerfilPermissionRoute from './PerfilPermissionRoute';
import PerfilPermissionRouteArea from './PerfilPermissionRouteArea';
import PerfilPermissionSuperUser from './PerfilPermissionSuperUser';
import Redirect from './Redirect';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// pages
import NotFound from '../pages/Page404';
import Login from '../pages/Login';
import RegisterNew from '../pages/RegisterNew';
import DashboardApp from '../pages/DashboardApp';
import ResetPassword from '../pages/ResetPassword';
import TwoFactor from '../pages/TwoFactor';
import NewPassword from '../pages/NewPassword';
import Config from '../pages/Config';
import Tags from '../pages/Tags';
import Perfil from '../pages/Perfil';
import User from '../pages/User';
import Areas from '../pages/ManageAreas';
import ManageAccessProfile from '../pages/ManageAccessProfile';
import ManageAccessUser from '../pages/ManageAccessUser';
import Products from '../pages/Products';
import Projects from '../pages/Projects';
import ActiveDirectory from '../pages/ActiveDirectory';
import LoginActiveDirectory from '../pages/LoginActiveDirectory';
import Workflow from '../pages/Workflow';
import Timeline from '../pages/Timeline';
import Alerts from '../pages/Alerts';
import News from '../pages/News';
import Repository from '../pages/Repository';
import Report from '../pages/Report';
import MyJobsMore from '../pages/MyJobsMore';

// ----------------------------------------------------------------------

export default function Router() {
  const urlrelease = 'https://artwork-dev.notion.site/releases-b10b7faf9dee4ad9afeeb60bcb309cb8';
  const urlplanning = 'https://artwork-dev.notion.site/planning-fe48e5afbdde44758cbf11e6701dfbc7';
  const urltest = 'https://docs.google.com/document/d/1W5dz2rqaSCW201H65DYdZ612BGcVMTTVsAmDqauTljA/edit';

  return (
    <Routes>
      {/* arquivos externos */}
      <Route path="/release" element={<Redirect url={urlrelease} />} />
      <Route path="/planning" element={<Redirect url={urlplanning} />} />
      <Route path="/teste" element={<Redirect url={urltest} />} />
      {/* componente para verificar a autenticação */}
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route path="/" element={<Navigate to="/dashboard/app" />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>

        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="/dashboard/app" element={<DashboardApp />} />
        </Route>

        <Route path="/repository" element={<Repository />} />

        <Route path="/projects" element={<Projects />} />

        <Route path="/projects/:projetoid/workflow" element={<Workflow />} />
        <Route path="/projects/:projetoid/timeline" element={<Timeline />} />

        <Route element={<PerfilPermissionRoute />}>
          <Route path="/registernew" element={<RegisterNew />} />
          <Route path="/user" element={<User />} />
          <Route path="/config" element={<Config />} />
          <Route path={'/manageruser'} element={<ManageAccessUser />} />
        </Route>
        <Route element={<PerfilPermissionSuperUser />}>
          <Route path={'/managerprofile'} element={<ManageAccessProfile />} />
        </Route>

        <Route element={<PerfilPermissionRouteArea />}>
          <Route path="/manageareas" element={<Areas />} />
        </Route>

        <Route path={`/perfil/:id`} element={<Perfil />} />
        <Route path="/tag" element={<Tags />} />

        <Route path={'/productsTable'} element={<Products />} />
        <Route path={'/alerts'} element={<Alerts />} />
        <Route path={'/news'} element={<News />} />
        <Route path={'/report'} element={<Report />} />
        <Route path={'/morejobs'} element={<MyJobsMore />} />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/twofactor" element={<TwoFactor />} />
      <Route path="/newpassword" element={<NewPassword />} />
      <Route path="/forgotpassword" element={<ResetPassword />} />
      <Route>
        <Route path="/loginad" element={<LoginActiveDirectory />} />
        <Route path="/registerad" element={<ActiveDirectory />} />
      </Route>

      <Route path="*" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
