import { useState, useEffect, useRef } from 'react';
// router
import { Link } from 'react-router-dom';
// material
import {
  Grid,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Card,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  ListItem,
  ListItemButton,
  List,
  Tabs,
  Tab,
  Box,
  Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-hot-toast';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import Loading from '../components/Loading';
import Scrollbar from '../components/Scrollbar';
import TabPanel, { a11yProps } from '../components/TabPanel';
import { StyledContainer } from '../components/styled-global/StyledGlobal';
//
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// api
import api from '../service/api';
import { userStore } from '../store/userStore';
import { ID_SUPERUSER } from '../enum/SuperUser';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(profileList, profile, theme) {
  return {
    fontWeight:
      profile.indexOf(profileList) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function ManageAccessProfile() {
  const [isLoading, setisLoading] = useState(true);
  const [profilesName, setProfilesName] = useState([]);
  const [profilesObject, setProfilesObject] = useState([]);
  const [profile, setProfile] = useState('');
  const [profileId, setProfileId] = useState(0);
  const [listPermissionsName, setPermissionsName] = useState([]);
  const [permissionChecked, setPermissionChecked] = useState({});
  const [permissionObject, setPermissionObject] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [open, setOpen] = useState(true);
  const UserId = useRef(null);
  const getUserPermissions = userStore((state) => state.getUser);

  const theme = useTheme();
  const { t } = useTranslation();

  async function requestProfile() {
    try {
      const responseProfile = await api.get('/perfis');
      // eslint-disable-next-line prefer-destructuring
      const perfis = responseProfile.data.data.perfis;
      setProfilesObject(perfis);
      const listProfile = perfis.map((perfil) => perfil.perfilnome);
      setProfilesName(listProfile);
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      console.log(error);
    }
  }

  async function getIdUser() {
    const userPermissions = await getUserPermissions();

    UserId.current = userPermissions.idusuario;
  }

  useEffect(() => {
    getIdUser();
  });

  async function requestPermissions() {
    setisLoading(true);
    try {
      const responsePermissions = await api.get('/permissions');
      // eslint-disable-next-line prefer-destructuring
      const permissoes = responsePermissions.data.data.permissions;

      const listPermission = permissoes.map((permissao) => permissao.permissaonome);
      const listChecked = {};
      listPermission.forEach((perm) => {
        listChecked[perm] = false;
      });

      setPermissionChecked(listChecked);
      setPermissionsName(listPermission);
      setPermissionObject(permissoes);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  function description(value) {
    const permissionCurrent = permissionObject.find((permission) => permission.permissaonome === value);
    return permissionCurrent.permissaodescricao;
  }

  useEffect(() => {
    requestProfile();
    requestPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProfilesName, setPermissionsName]);

  const handleChangeProfile = async (event) => {
    const {
      target: { value },
    } = event;
    // eslint-disable-next-line arrow-body-style
    const perfil = profilesObject.find((profile) => {
      return profile.perfilnome === value;
    });
    setProfileId(perfil.idperfil);

    const responseProfilePermissions = await api.get(`/perfis/${perfil.idperfil}`);

    // eslint-disable-next-line prefer-destructuring
    const permissoes = responseProfilePermissions.data.data.perfil.permissions;
    const listChecked = {};
    listPermissionsName.forEach((namePermission) => {
      const exists = permissoes.map((permission) => permission.permissaonome).includes(namePermission);
      if (exists) {
        listChecked[namePermission] = true;
      } else {
        listChecked[namePermission] = false;
      }
    });
    setPermissionChecked(listChecked);
    setProfile(typeof value === 'string' ? value : value);
  };

  const handleToggle = (value) => async (event) => {
    const permission = permissionObject.find((permission) => permission.permissaonome === value);
    const listChecked = structuredClone(permissionChecked);
    if (event.target.checked === true) {
      listChecked[value] = true;
      await api.post(`/perfis/${profileId}`, {
        permissions: [String(permission.idpermissao)],
      });
    } else {
      listChecked[value] = false;
      await api.delete(`/perfis/${profileId}`, {
        data: { permissions: [String(permission.idpermissao)] },
      });
    }

    setPermissionChecked(listChecked);
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <Page title={t('sidebar.manageacess')} sx={{ height: '100vh' }}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <StyledContainer>
        <Loading loading={isLoading} />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          // sx={{ margin: '10% auto 0', position: 'relative' }}
        >
          <Typography variant="h4" component="span" gutterBottom>
            {t('manage-access.title')}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={valueTab} onChange={handleChangeTab} scrollButtons="auto" aria-label="basic tabs example">
              {UserId?.current !== ID_SUPERUSER || UserId?.current === null ? (
                <></>
              ) : (
                <Tab component={Link} label={t('manage-access.tab1')} to="/managerprofile" {...a11yProps(0)} />
              )}
              <Tab component={Link} label={t('manage-access.tab2')} to="/manageruser" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={valueTab} index={0}>
            <Grid container spacing={2} sx={{ marginTop: '2%' }}>
              <Card sx={{ width: '100%' }}>
                <Scrollbar>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          {/* Profile */}
                          <TableCell>
                            {/* <TableCell sx={{ verticalAlign: 'center' }}> */}
                            <FormControl sx={{ m: 1, width: 300 }}>
                              <InputLabel id="profile-acess-label">{t('manage-access.pleacehold-profile')}</InputLabel>
                              <Select
                                labelId="profile-acess-label"
                                id="profile-acess"
                                defaultValue=""
                                value={profile === '' ? '' : profile}
                                onChange={handleChangeProfile}
                                input={<OutlinedInput label="Perfil" />}
                                MenuProps={MenuProps}
                              >
                                {profilesName.map((profileList) => (
                                  <MenuItem
                                    key={profileList}
                                    value={profileList}
                                    style={getStyles(profileList, profile, theme)}
                                  >
                                    {profileList}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          {/* Permission */}
                          <TableCell sx={{ margin: 1 }}>
                            <List
                              sx={{
                                width: '100%',
                                maxWidth: 400,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 600,
                              }}
                            >
                              {listPermissionsName.map((value) => {
                                const labelId = `checkbox-list-secondary-label-${value}`;
                                return (
                                  <ListItem
                                    key={value}
                                    disablePadding
                                    secondaryAction={
                                      <Checkbox
                                        edge="end"
                                        onChange={handleToggle(value)}
                                        checked={permissionChecked[value]}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                      />
                                    }
                                  >
                                    <ListItemButton>
                                      <Tooltip title={description(value)} arrow>
                                        <ListItemText id={labelId} primary={`${value}`} />
                                      </Tooltip>
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Grid>
          </TabPanel>
        </Box>
      </StyledContainer>
    </Page>
  );
}
