import i18next from 'i18next';

export const propertiesStandardForm = [
  {
    id: 'field1',
    type: 'textbox',
    question: i18next.t('propertiesStandardForm.questionName'),
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [1],
  },
  {
    id: 'field2',
    type: 'textbox',
    question:i18next.t('propertiesStandardForm.questionNameProd'),
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [2],
  },
  {
    id: 'field3',
    type: 'date',
    question: `${i18next.t('propertiesStandardForm.questionDateInit')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [3],
  },
  {
    id: 'field4',
    type: 'date',
    question: `${i18next.t('propertiesStandardForm.questionDeadLine')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [4],
  },
  {
    id: 'field5',
    type: 'textboxmultiline',
    question: `${i18next.t('propertiesStandardForm.questionAlt')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [5],
  },
  {
    id: 'field6',
    type: 'combobox',
    question:  `${i18next.t('propertiesStandardForm.questionMod')}`,
    options: [[ `${i18next.t('defaultOption.yes')}`,  `${i18next.t('defaultOption.no')}`]],
    answer: null,
    required: [true],
    able: true,
    position: [6],
  },
  {
    id: 'field7',
    type: 'combobox',
    question:  `${i18next.t('propertiesStandardForm.questionAltUnfeasible')}`,
    options: [[ `${i18next.t('defaultOption.yes')}`,  `${i18next.t('defaultOption.no')}`]],
    answer: null,
    required: [true],
    able: true,
    position: [7],
  },
  {
    id: 'field8',
    type: 'textbox',
    question:  `${i18next.t('propertiesStandardForm.questionPackaging')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [8],
  },
  {
    id: 'field9',
    type: 'textboxmultiline',
    question:  `${i18next.t('propertiesStandardForm.questionPresentation')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [9],
  },
  {
    id: 'field10',
    type: 'date',
    question:  `${i18next.t('propertiesStandardForm.questionDateRepo')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [10],
  },
  {
    id: 'field11',
    type: 'date',
    question:  `${i18next.t('propertiesStandardForm.questionDateImplArt')}`,
    options: [],
    answer: null,
    required: [true],
    able: true,
    position: [11],
  },
  {
    id: 'field12',
    type: 'combobox',
    question:  `${i18next.t('propertiesStandardForm.questionSituation')}`,
    options: [[ `${i18next.t('defaultOption.creation')}`,  `${i18next.t('defaultOption.replacement')}`]],
    // options: [[ `${i18next.t('defaultOption.creation')}`,  `${i18next.t('defaultOption.replacement')}`]],
    answer: null,
     required: [true],
    able: true,
    position: [12],
  },
  {
    id: 'field13',
    type: 'textbox',
    question:  `${i18next.t('propertiesStandardForm.questionBond')}`,
    options: [],
    answer: null,
     required: [true],
    able: true,
    position: [13],
  },
];