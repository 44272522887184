import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Tooltip,
  Container,
  IconButton,
  CircularProgress,
  Avatar
} from '@mui/material';
import { Icon } from '@iconify/react';
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';
import { toast } from 'react-hot-toast';
// i18n
import { useTranslation } from 'react-i18next';
// components
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import Page from '../components/Page';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// utils
import { fToNow } from '../utils/formatTime';
// api
import api from '../service/api';

// ----------------------------------------------------------------------

export default function Alerts() {
  const { t } = useTranslation();
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const [alertsLoading, setAlertsLoading] = useState(true);

  const [alertList, setAlertList] = useState([]);

  const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.background,
    color: theme.palette.text.secondary,
  }));

  const handleVisibledAlert = (idalerta, index) => {
    requestChangeVisibledAlert(idalerta, index);
  };
  async function requestChangeVisibledAlert(idalerta, index) {
    try {
      const response = await api.put(`/alertvisibled/${idalerta}`);
      // eslint-disable-next-line prefer-destructuring
      if (response.status === 200) {
        const alertListtmp = [...alertList];
        alertListtmp.splice(index, 1);
        setAlertList(alertListtmp);
      } else {
        console.log('erro');
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function requestAlerts() {
    try {
      const response = await api.get('/alerts');
      // eslint-disable-next-line prefer-destructuring
      if (response.status === 200) {
        const alertlisttmp = response.data.data.alerts;
        setAlertList(alertlisttmp);
        setAlertsLoading(false);
      } else {
        console.log('erro');
        setAlertsLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      setAlertsLoading(false);
    }
  }

  useEffect(() => {
    requestAlerts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAlertList]);

  function tipoAlertShow(alert) {
    if (alert.alertatipo === 'AVISO') {
      return (
        <StyledAccordion sx={{ width: '100%' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'left', color: 'text.secondary' }}>
                  {alert.alertatitulo}
                </Typography>
              </Box>
              <Box sx={{ marginRight: 2 }}>
                <Typography variant="caption">{fToNow(alert.alertadata)}</Typography>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>{alert.alertatxt}</AccordionDetails>
        </StyledAccordion>
      );
    }

    return (
      <StyledAccordion sx={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ minWidth: 240, flexGrow: 1 }}>
              <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'left' }}>
                {alert.alertatitulo}
              </Typography>
            </Box>
            <Box sx={{ marginRight: 2 }}>
              <Typography variant="caption">{fToNow(alert.alertadata)}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title={alert.usuarionome} arrow>
              <Avatar
                alt={alert.usuarionome}
                src={alert.usuarioicon}
                sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
              />
            </Tooltip>
            <Box sx={{ minWidth: 240, flexGrow: 1 }}>
              {alert.alertatxt}
              <Link to={alert.alertalink}>
                <LaunchIcon sx={{ color: 'rgb(25, 118, 210)' }} />
              </Link>
            </Box>

            <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
              {/* {fToNow(execdate)} */}
            </Typography>
          </Stack>
        </AccordionDetails>
      </StyledAccordion>
    );
  }

  return (
    <Page title="Alerts">
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} navigateBack={-1} />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 8,
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            {t('alerts.page-heading')}
          </Typography>
        </Stack>

        {alertsLoading && (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}

        {!alertsLoading && alertList.length ? (
          <Masonry columns={1} spacing={2}>
            {alertList.map((alert, index) => (
              // eslint-disable-next-line react/jsx-key
              <Stack
                key={`alert${alert.idalerta}c`}
                direction="row"
                spacing={2}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Paper
                  key={`alert${alert.idalerta}a`}
                  sx={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}
                  elevation={3}
                >
                  {tipoAlertShow(alert)}
                </Paper>
                <Tooltip title={t('alerts.mark-as-read')} enterDelay={500} disableInteractive placement="bottom-end">
                  <IconButton
                    onClick={() => {
                      handleVisibledAlert(alert.idalerta, index);
                    }}
                  >
                    <Icon icon="material-symbols:check-circle" width={30} height={30} color="#25D366" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ))}
          </Masonry>
        ) : null}
        {!alertsLoading && !alertList.length && (
          <Paper elevation={3} sx={{ width: '100%' }}>
            <br />
            <Typography variant="h4" align="center" color="primary" sx={{ display: 'flex', justifyContent: 'center' }}>
              {t('alerts.msg-view')}
            </Typography>
            <br />
          </Paper>
        )}
      </Container>
    </Page>
  );
}
