import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Card, CardContent, CardActionArea, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { styled } from '@mui/material/styles';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import api from '../service/api';

// modal
import ModalReportUser from './modal/modal-report/ModalReportUser';
import ModalReportCustom from './modal/modal-report/ModalReportCustom';
import ModalReportPerformaceIndicator from './modal/modal-report/ModalReportPerformaceIndicator';
import ModalReportStatusUser from './modal/modal-report/ModalReportStatusUser';
import ModalReportProjectStatus from './modal/modal-report/ModalReportProjectStatus';
import ModalReportProjectByStatus from './modal/modal-report/ModalReportProjectByStatus';
import ModalReportProjectsOverdueArea from './modal/modal-report/ModalReportProjectsOverdueArea';
import ModalReportlaggingAreas from './modal/modal-report/ModalReportlaggingAreas';
import ModalReportlaggingProduct from './modal/modal-report/ModalReportlaggingProduct';
import ModalReportAuditTrial from './modal/modal-report/ModalReportAuditTrial';
import ModalReportOverdueUser from './modal/modal-report/ModalReportOverdueUser';

const ImgBoxStyle = styled(Box)(() => ({
  width: 36,
  height: 36,
}));

export default function ReportCard({
  title,
  description,
  icon,
  user,
  performanceIndicator,
  userStatus,
  projectStatus,
  projectsOverdueArea,
  laggingAreas,
  laggingProduct,
  projectsStatus,
  customReport,
  auditTrail,
  overdueUser
}) {
  const [userReportOpen, setUserReportOpen] = useState(false);
  const [statusUserReportOpen, setStatusUserReportOpen] = useState(false);
  const [projectStatusReportOpen, setProjectStatusReportOpen] = useState(false);
  const [projectsOverdueAreaReportOpen, setProjectsOverdueAreaReportOpen] = useState(false);
  const [performanceIndicatorReportOpen, setPerformanceIndicatorReportOpen] = useState(false);
  const [laggingAreasReportOpen, setLaggingAreasReportOpen] = useState(false);
  const [laggingProductReportOpen, setLaggingProductReportOpen] = useState(false);
  const [projectsStatusOpen, setProjectsStatusOpen] = useState(false);
  const [customReportOpen, setCustomReportOpen] = useState(false);
  const [auditTrailOpen, setAuditTrailReportOpen] = useState(false);
  const [overdueUseOpen, setoverdueUserReportOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    if (user) setUserReportOpen(true);
    if (userStatus) setStatusUserReportOpen(true);
    if (projectStatus) setProjectStatusReportOpen(true);
    if (projectsOverdueArea) setProjectsOverdueAreaReportOpen(true);
    if (performanceIndicator) setPerformanceIndicatorReportOpen(true);
    if (laggingAreas) setLaggingAreasReportOpen(true);
    if (laggingProduct) setLaggingProductReportOpen(true);
    if (projectsStatus) setProjectsStatusOpen(true);
    if (customReport) setCustomReportOpen(true);
    if (auditTrail) setAuditTrailReportOpen(true);
    if (overdueUser) setoverdueUserReportOpen(true);
  };
  
  const handleClose = () => {
    if (user) setUserReportOpen(false);
    if (userStatus) setStatusUserReportOpen(false);
    if (projectStatus) setProjectStatusReportOpen(false);
    if (projectsOverdueArea) setProjectsOverdueAreaReportOpen(false);
    if (performanceIndicator) setPerformanceIndicatorReportOpen(false);
    if (laggingAreas) setLaggingAreasReportOpen(false);
    if (laggingProduct) setLaggingProductReportOpen(false);
    if (projectsStatus) setProjectsStatusOpen(false);
    if (customReport) setCustomReportOpen(false);
    if (auditTrail) setAuditTrailReportOpen(false);
    if (overdueUser) setoverdueUserReportOpen(false);
  };

  // eslint-disable-next-line consistent-return
  const getUsers = async () => {
    try {
      const response = await api.get(`/users`);
      // const response = await api.get(`/usersall`);
      return response.data.data.users;
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      else{
        toast.error(`${error.response.data.data.errors[0]}`);

      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(['usersForReport'], () => getUsers(), {
    refetchOnWindowFocus: true,
  });

  return (
    <Box sx={{ height: '100%' }}>
      <Card sx={{ width: '100%', height: '100%' }}>
        <CardActionArea onClick={handleClickOpen} sx={{ height: '100%' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 1, marginTop: -0.3 }}>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
                paddingX: 1,
                marginBottom: 0,
              }}
            >
              <ImgBoxStyle component="img" src={icon} sx={{ alignSelf: 'flex-start', marginTop: 0.6 }} />
              <Typography component="span" variant="h5" sx={{ width: '100%' }}>
                {title}
              </Typography>
            </Box>
            <Typography component="span" variant="body2" color="text.secondary" sx={{ padding: 1 }}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>

        {/* atividades por usuario */}
        <ModalReportUser
          open={userReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* relárotio customizado */}
        <ModalReportCustom
          open={customReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* status de performace do usuario */}
        <ModalReportPerformaceIndicator
          open={performanceIndicatorReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* status dos usuarios */}
        <ModalReportStatusUser
          open={statusUserReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* status dos projetos */}
        <ModalReportProjectStatus
          open={projectStatusReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />
        {/* projetos por status */}
        <ModalReportProjectByStatus
          open={projectsStatusOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* status das areas com projetos vencidos */}
        <ModalReportProjectsOverdueArea
          open={projectsOverdueAreaReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* Áreas com mais atrasos */}
        <ModalReportlaggingAreas
          open={laggingAreasReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />

        {/* Produtos com mais atrasos */}
        <ModalReportlaggingProduct
          open={laggingProductReportOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />
        {/* Audit Trail */}
        <ModalReportAuditTrial
          open={auditTrailOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />
        <ModalReportOverdueUser
          open={overdueUseOpen}
          onClose={handleClose}
          handleClose={handleClose}
          title={title}
          description={description}
          icon={icon}
        />
      </Card>
    </Box>
  );
}

ReportCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
};
