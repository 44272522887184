/* eslint-disable react/prop-types */
import { useRef, useState, useContext } from 'react';
// material
import { Menu, IconButton } from '@mui/material';
// component
import { Icon } from '@iconify/react';
import verticalFillIcon from '@iconify/icons-eva/more-vertical-fill';
import i18next from 'i18next';
import { toast } from 'react-hot-toast';
// import editFillIcon from '@iconify/icons-eva/edit-fill';

import Modal from '../../../modal/modal-user/ModalUser';
import ModalBlockUser from '../../../modal/modal-user/ModalBlockUser';
import api from '../../../../service/api';
import { UsuarioContext } from '../../../../pages/User';

// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const usuarioContext = useContext(UsuarioContext);

  const { usuario, setUsuario } = usuarioContext;

  let statusUser;
  usuario.forEach((usuario) => {
    if (usuario.idusuario === props.id) {
      statusUser = usuario.usuariostatus;
    }
  });

  const activeUser = async (status, id) => {
    let newStatus = 0;
    if (status === 1) {
      newStatus = 0;
    } else {
      newStatus = 1;
    }

    try {
      // eslint-disable-next-line no-unused-vars
      const response = await api.put(`/users/status/${id}`, {
        status: newStatus,
      });
      const usuario2 = usuario.map((element) =>
        element.idusuario === props.id ? { ...element, usuariostatus: newStatus } : element
      );

      setUsuario(usuario2);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const blockUser = async (status, id) => {
    let newStatus = 0;
    if (status === 2) {
      newStatus = 1;
    } else {
      newStatus = 2;
    }
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await api.put(`/users/status/${id}`, {
        status: newStatus,
      });
      const usuario2 = usuario.map((element) =>
        element.idusuario === props.id ? { ...element, usuariostatus: newStatus } : element
      );

      setUsuario(usuario2);

      setIsOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      console.log(error);
    }
  };

  return (
    <>
      <IconButton sx={{ color: 'text.secondary' }} ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={verticalFillIcon} height={20} width={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 150, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Modal id={props.id} statusUser={statusUser} activeUser={activeUser} onClick={() => setIsOpen(false)} />

        {/* <MenuItem component={RouterLink} to={`/perfil/${props.id}`} sx={{ color: 'text.secondary' }}> */}
        {/* <MenuItem component={RouterLink} to={`/perfil/${props.id}`} state={{ id: props.id }} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFillIcon} height={24} width={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
        <ModalBlockUser onClick={() => setIsOpen(false)} id={props.id} statusUser={statusUser} blockUser={blockUser} />
      </Menu>
    </>
  );
}
