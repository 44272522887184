// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box, Grid, CardMedia } from '@mui/material';
// hooks
import { useTranslation } from 'react-i18next';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';

// sections
import { LoginActiveDirectoryForm } from '../sections/auth/loginactivedirectory';
// images
// import Background from '../assets/logos-login/background.png';
import Background from '../assets/logos-login/new-background.jpg';
import BackgroundFlow from '../assets/logos-login/flow.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  lineHeight: 0,
  zIndex: -1,
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  position: 'absolute',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: theme.spacing(0, 2, 0, 3),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 490,
  height: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 0, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  marginTop: 70,
}));

const stylesBackground = {
  display: 'flex',
  justifyContent: 'center',
  zIndex: -1,
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundImage: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
};

// ----------------------------------------------------------------------

export default function LoginActiveDirectory() {
  const smUp = useResponsive('up', 'sm');
  const { t } = useTranslation();

  return (
    <Page title="Login">
      <Grid sx={stylesBackground} alt="login">
        <RootStyle>
          {smUp && (
            <Box sx={{ px: 15, mt: 0, mb: 0, height: 699, width: 836 }}>
              <Typography variant="h3" sx={{ color: 'text.logintitle' }}>
                {t('login.welcome')} <br /> Artwork Management
              </Typography>
              <CardMedia component="img" image={BackgroundFlow} />
            </Box>
          )}
          <SectionStyle>
            <Container maxWidth="sm">
              <HeaderStyle>
                <Logo />
              </HeaderStyle>
              <ContentStyle>
                <Typography variant="h5" sx={{ color: 'text.login', mb: 3 }}>
                  {t('login.ad-header')}
                </Typography>
                <LoginActiveDirectoryForm />
              </ContentStyle>
            </Container>
          </SectionStyle>
        </RootStyle>
      </Grid>
    </Page>
  );
}
