import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Paper,
} from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';
// components
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-fill';
import { useQueryClient, useQuery } from 'react-query';
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import { StyledContainer } from '../styled-global';
import { ProductsListToolbar, ProductsTableListHead, ProductsTableMoreMenu } from './table-components/products';
import { EditProductsModal, AddProductsModal, ConfirmChangeStatusModal } from './table-components/products/modals';
import { StyledTagBox } from '../styled-global/StyledGlobal';
// api
import api from '../../service/api';
// store
import { usePermission } from '../../store/usePermission';
import Loading from '../Loading';

// ----------------------------------------------------------------------

export default function ProductsTable() {
  const [page, setPage] = useState(0);
  const [isNotFound, setIsNotFound] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('produtonome');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tags, setTags] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editProduct, setEditProduct] = useState({
    produtonome: '',
    produtocodigo: '',
    idproduto: '',
    idtag: '',
    tags: [],
  });
  const [itemId, setItemId] = useState(null);
  const [confirmChangeStatusModalOpen, setConfirmChangeStatusModalOpen] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);
  const [filterProduct, setFilterProduct] = useState('');
  const { t } = useTranslation();
  const idFilter = useRef('produtonome');

  const queryClient = useQueryClient();

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: 'produtonome', label: t('products.table-head.name'), alignRight: false },
    { id: 'produtocodigo', label: t('products.table-head.code'), alignRight: false },
    { id: 'idtag', label: 'Tag', alignRight: false },
    { id: 'produtostatus', label: 'Status', alignRight: false },
  ];

  // ----------------------------------------------------------------------

  const { data: products, isLoading } = useQuery(
    ['products', page, rowsPerPage, idFilter.current, order, filterProduct],
    () => requestProducts(page, rowsPerPage, idFilter.current, order, filterProduct),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const handleRequestSort = (event, property) => {
    idFilter.current = property;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const noProducts = products?.length === 0;

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const productAdm = userPermissions.includes('productAdministrating');
      setPermissionAdmin(productAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const requestProducts = async () => {
  //   try {
  //     const productsResponse = await api.get(`/product`);
  //     const product = productsResponse.data.data.products;
  //     setPRODUCTSLIST(product);
  //     setisLoading(false);
  //   } catch (error) {
  //     setisLoading(false);
  //     if (error.response.status === 409) {
  //       toast.error(`${error.response.data.data.errors[0]}`);
  //     } else {
  //       toast.error(`${error.response.data.data[0]}`);
  //     }
  //   }
  // };

  const requestProducts = async (page, rowsPerPage, filter, order, filterProject) => {
    try {
      const productsResponse = await api.get(
        `/product/${rowsPerPage}/${rowsPerPage * page}/${filter}/${order}/${filterProject}`
      );
      return productsResponse.data.data.products;
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
    return null;
  };

  const requestTags = async () => {
    try {
      const tagsResponse = await api.get(`/tag`);
      setTags(tagsResponse.data.data.tags);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  useEffect(() => {
    requestTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editProductHandler = async (
    newProductName,
    newProductCode,
    newProductTag,
    removedTagsSKU,
    addedTagsSKU,
    newProductStatus,
    productId
  ) => {
    try {
      // eslint-disable-next-line dot-notation

      const response = await api.put(`/product/${productId}`, {
        produtonome: newProductName,
        produtocodigo: newProductCode,
        idtag: newProductTag,
        tagsskuremovidas: removedTagsSKU,
        tagsskuadicionadas: addedTagsSKU,
        produtostatus: newProductStatus,
      });

      queryClient.invalidateQueries(['products']);
      // requestProducts();
      toast.success(response.data.data.message);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  function openEditProductModalHandler(productId, product) {
    setEditModalOpen(true);
    setItemId(productId);
    setEditProduct(product);
  }

  const changeProductStatusHandler = (productId, product) => {
    setConfirmChangeStatusModalOpen(true);
    setItemId(productId);
    setEditProduct(product);
  };

  const confirmStatusChangeHandler = (
    productName,
    productCode,
    productTag,
    projectTagSKU,
    productStatus,
    productId
  ) => {
    let newStatus;
    if (productStatus === 1) {
      newStatus = 0;
    }
    if (productStatus === 0) {
      newStatus = 1;
    }
    editProductHandler(productName, productCode, productTag, [], [], newStatus, productId);
    setConfirmChangeStatusModalOpen(false);
  };

  const handleFilterProduct = (event) => {
    setPage(0);
    setFilterProduct(event.target.value);
  };

  useEffect(() => {
    if (products?.products.length === 0 && filterProduct.length > 0) {
      setIsNotFound(true);
    }
    if (products?.products.length > 0 && filterProduct.length > 0) {
      setIsNotFound(false);
    }
  }, [filterProduct, products?.products.length]);

  return (
    <StyledContainer>
      <Loading loading={isLoading} />
      <AddProductsModal
        isModalOpen={addModalOpen}
        setModalOpen={() => setAddModalOpen(false)}
        currentItems={products?.products}
        triggerPageRerender={requestProducts}
        tags={tags}
      />
      <EditProductsModal
        isModalOpen={editModalOpen}
        setModalOpen={() => setEditModalOpen(false)}
        itemToBeEdited={editProduct}
        itemId={itemId}
        currentItems={products?.products}
        editProductFunction={editProductHandler}
        tags={tags}
      />
      <ConfirmChangeStatusModal
        isModalOpen={confirmChangeStatusModalOpen}
        setModalOpen={setConfirmChangeStatusModalOpen}
        itemToBeEdited={editProduct}
        confirmButtonHandler={confirmStatusChangeHandler}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('products.heading')}
        </Typography>
        {permissionAdmin === true ? (
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusIcon} />}
            onClick={() => setAddModalOpen(true)}
          >
            {t('products.new-product')}
          </Button>
        ) : (
          ''
        )}
      </Stack>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <ProductsListToolbar filterProduct={filterProduct} onFilterProduct={handleFilterProduct} />
            <Table>
              <ProductsTableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={products?.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {/* {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { */}
                {products?.products.map((row) => {
                  const { idproduto, produtonome, idtag, produtocodigo, produtostatus } = row;
                  const [productTag] = tags.filter((tag) => tag.idtag === idtag);
                  return (
                    <TableRow hover key={idproduto + 1} tabIndex={-1} role="checkbox">
                      <TableCell component="th" scope="row" padding="none" sx={{ paddingLeft: 2 }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {produtonome}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{produtocodigo}</TableCell>
                      <TableCell align="left">
                        <Box sx={{ width: '100%' }}>
                          {tags.length > 0 && (
                            <StyledTagBox tagColor={productTag.tagcolor}>
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: '#fff',
                                  textShadow: '1px 1px 1px #000',
                                }}
                              >
                                {productTag.tagnome}
                              </Typography>
                            </StyledTagBox>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Label variant="ghost" color={(produtostatus === 0 && 'error') || 'success'}>
                          {produtostatus === 1 ? t('products.status.active') : t('products.status.disabled')}
                        </Label>
                      </TableCell>

                      <TableCell align="right">
                        {permissionAdmin === true ? (
                          <ProductsTableMoreMenu
                            productStatus={produtostatus}
                            openEditModal={() => openEditProductModalHandler(idproduto, row)}
                            changeProductStatusFunction={() => changeProductStatusHandler(idproduto, row)}
                          />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          {t('products.search-bar.not-found')}
                        </Typography>

                        <Typography variant="body2">
                          {t('products.search-bar.not-found-text')} &nbsp;
                          <strong>&quot;{filterProduct}&quot;</strong>.
                          <br /> {t('products.search-bar.not-found-text-complement')}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {noProducts && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Typography>{t('products.no-products-yet')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Number(products?.total) ? Number(products?.total) : 5}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('products.rows-per-page')}
        />
      </Card>
    </StyledContainer>
  );
}
