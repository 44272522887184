import { Avatar, Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import WorkflowIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import Label from './Label';
import { fToNow } from '../utils/formatTime';
import { imagens } from './ReactFlow/assets/imagens';

export default function MyJobsItems({ job, sx, final,checked }) {
  const [deadline, setDeadline] = useState(undefined);
  const location = useLocation();

  const theme = useTheme();

  const navigate = useNavigate();

  const { t } = useTranslation();

  function selectModuleIcons(moduleName) {
    if (moduleName === 'INICIAL') return { icon: imagens.emptyFile, type: t('types-modules.initial') };
    if (moduleName === 'FORM') return { icon: imagens.form, type: t('types-modules.form') };
    if (moduleName === 'ACOMPANHA') return { icon: imagens.fileFill, type: t('types-modules.attendance') };
    if (moduleName === 'ANEXO') return { icon: imagens.anexo, type: t('types-modules.attachment') };
    if (moduleName === 'SUBST') return { icon: imagens.substituir, type: t('types-modules.replacement') };
    if (moduleName === 'REPO') return { icon: imagens.repositorio, type: t('types-modules.repository') };
    if (moduleName === 'EMAIL') return { icon: imagens.email, type: t('types-modules.email') };
    if (moduleName === 'ALERTA') return { icon: imagens.alert, type: t('types-modules.alert') };
    if (moduleName === 'TAG') return { icon: imagens.tags, type: t('types-modules.tag') };
    if (moduleName === 'TEMPO') return { icon: imagens.time, type: t('types-modules.time') };
    if (moduleName === 'UNIAO') return { icon: imagens.merge, type: t('types-modules.unity') };
    if (moduleName === 'CHECK') return { icon: imagens.checkList, type: t('types-modules.checkpoint') };
    if (moduleName === 'SEQ') return { icon: imagens.next, type: t('types-modules.sequence') };
    return null;
  }

  function getDeadline(prazoType) {
    if (prazoType === '0') {
      return {
        text: t('my-jobs.deadline.on-time'),
        backgroundColor: theme.palette.success.lighter,
        label: theme.palette.success.dark,
      };
    }
    if (prazoType === '1') {
      return {
        text: t('my-jobs.deadline.close-to-end'),
        backgroundColor: theme.palette.warning.light,
        label: theme.palette.warning.dark,
      };
    }
    if (prazoType === '2') {
      return {
        text: t('my-jobs.deadline.late'),
        backgroundColor: theme.palette.error.lighter,
        label: theme.palette.error.dark,
      };
    }
    return null;
  }

  useEffect(() => {
    if (!final) setDeadline(getDeadline(job.tipoprazo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (final) {
    return (
      <Paper
        sx={{
          padding: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxHeight: 100,
          backgroundColor: theme.palette.finalizado.lighter,
          ...sx,
        }}
        square
        elevation={3}
      >
        <Box
          id="left-box"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: 60,
              height: 60,
              backgroundColor: theme.palette.finalizado.darker,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'inset 2px 2px 5px #666666',
            }}
          >
            <Typography color="white" textAlign="center" fontWeight="bolder" fontSize={20}>
              {`${job.projpercentual}%`}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="black" variant="subtitle2" noWrap>
              {job.projetonome}
            </Typography>
            <Typography variant="body2" color="black">{`${t('my-jobs.more.action-executed')} ${
              job.modulotxt
            }`}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alingItems: 'center', gap: 1 }}>
              <Typography variant="body2" color="black">{`${t('my-jobs.last-update')} ${fToNow(
                new Date(job.moduloacaodata)
              )}`}</Typography>
              <Label sx={{ backgroundColor: theme.palette.finalizado.darker, color: 'white' }}>
                {t('my-jobs.more.executed')}
              </Label>
            </Box>
            {location.pathname !== '/dashboard/app' && checked === true && job.usuarionome !== "" ? (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <Avatar src={job?.usuarioicon} alt={job?.usuarionome} sx={{ width: 24, height: 24 }} />
                <Typography color="black" variant="subtitle2" noWrap>
                  -
                </Typography>
                <Typography color="black" variant="subtitle2" noWrap>
                  {job?.usuarionome}
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        <Box
          id="rightBox"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxHeight: '100%',
            justifyContent: 'flex-end',
            gap: 3,
            marginRight: 2,
          }}
        >
          <Tooltip disableInteractive title={selectModuleIcons(job.modulotipo).type}>
            <img alt="sim" src={selectModuleIcons(job.modulotipo).icon} height={30} width={30} />
          </Tooltip>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper
      sx={{
        padding: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: 100,
        backgroundColor: deadline?.backgroundColor,
        ...sx,
      }}
      square
      elevation={3}
    >
      <Box
        id="left-box"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 3,
        }}
      >
        <Box
          sx={{
            width: 60,
            height: 60,
            backgroundColor: deadline?.label,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 'inset 2px 2px 5px #666666',
          }}
        >
          <Typography color="white" textAlign="center" fontWeight="bolder" fontSize={20}>
            {`${job.projpercentual}%`}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Typography color="black" variant="subtitle2" noWrap>
              {job.projetonome}
            </Typography>
          </Box>
          <Typography variant="body2" color="black">{`${t('my-jobs.action')} ${job.modulotxt}`}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alingItems: 'center', gap: 1 }}>
            <Typography variant="body2" color="black">{`${t('my-jobs.last-update')} ${fToNow(
              new Date(job.moduloacaodatault)
            )}`}</Typography>
            <Label sx={{ backgroundColor: deadline?.label, color: 'white' }}>{deadline?.text}</Label>
          </Box>
          {location.pathname !== '/dashboard/app' && checked === true && job.usuarionome !== ""  ? (
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Avatar src={job?.usuarioicon} alt={job?.usuarionome} sx={{ width: 24, height: 24 }} />
              <Typography color="black" variant="subtitle2" noWrap>
                -
              </Typography>
              <Typography color="black" variant="subtitle2" noWrap>
                {job?.usuarionome}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box
        id="rightBox"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '100%',
          justifyContent: 'flex-end',
          gap: 3,
        }}
      >
        <Tooltip disableInteractive title={selectModuleIcons(job.modulotipo).type}>
          <img alt="sim" src={selectModuleIcons(job.modulotipo).icon} height={30} width={30} />
        </Tooltip>
        <Box id="Workflow Icon" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Tooltip title={t('my-jobs.workflow')} disableInteractive>
            <IconButton
              onClick={() => {
                navigate(`/projects/${job.idprojeto}/timeline`, { state: { idprojeto: job.idprojeto } });
              }}
            >
              <WorkflowIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
}
