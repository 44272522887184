import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Icon } from '@iconify/react';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import api from '../../../../../service/api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function ModalEscolhaFormExecution({ module, node }) {
  const [open, setOpen] = useState(false);
  const [arrPropertiesForm, setArrPropertiesForm] = useState([]);
  const loadForm = async () => {
    const responseProject = await api.get(`modules/form/${module.modulonodeid}/${module.idprojeto}`);
    const { form } = responseProject.data.data;
    const allFieldsForm = JSON.parse(form.form);

    if (node.properties.FIELDS !== undefined) {
      if (node.properties.FIELDS.length > 0) {
        const formRef = node.properties.FIELDS.find((field) => Number(field.nodeid) === Number(module.modulonodeid));
        // formRef.infosRefs.forEach((field) => {
        //   if (allFieldsForm.some((fieldNoFilter) => fieldNoFilter.id === field.id)) {
        //     setArrPropertiesForm((fieldSelect) => fieldSelect.concat(field));
        //   }
        // });
        allFieldsForm.forEach((fieldNoFilter) => {
          if (formRef.infosRefs.some((fieldSelected) => fieldSelected.id === fieldNoFilter.id)) {
            setArrPropertiesForm((fieldSelect) => fieldSelect.concat(fieldNoFilter));
          }
        });
        // setArrPropertiesForm(formRef.infosRefs);
      }
      if (node.properties.FIELDS.length <= 0) {
        setArrPropertiesForm(JSON.parse(module.moduloformulario.form));
      }
    }
    if (node.properties.FIELDS === undefined || node.properties.FIELDS === null) {
      setArrPropertiesForm(JSON.parse(module.moduloformulario.form));
    }
  };

  useEffect(() => {
    loadForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const loadValueAnswer = (field) => (field.answer !== null ? field.answer : '');
  const loadValueAnswer = (field) => {
    if (field.answer !== null && field.answer !== undefined && field.answer !== '') {
      return field.answer;
    }
    return '';
  };

  const loadValueOption = (prop) => {
    if (prop.answer === null || prop.answer === undefined) return '';
    return prop.answer;
  };

  const textbox = (field) => (
    <Card key={`card-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label="Resposta"
          fullWidth
          margin="dense"
          required={field.required[0]}
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );
  const textboxmultiline = (field) => (
    <Card key={`card-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label="Resposta"
          fullWidth
          margin="dense"
          required={field.required[0]}
          multiline
          rows={4}
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );
  const multiselect = (field) => (
    <Card key={`card-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <FormControl required={field.required[0]} sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">{i18next.t('flow-Modals-form.optionsAnswer')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id={field.id}
            name={field.id}
            multiple
            value={loadValueOption(field)}
            input={<OutlinedInput label={i18next.t('flow-Modals-form.optionsAnswer')} />}
            MenuProps={MenuProps}
            disabled
          >
            {field.options[0].map((option) => (
              <MenuItem key={`position-${option}`} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
  const combobox = (field) => (
    <Card key={`card-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <FormControl required={field.required[0]} sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">{i18next.t('flow-Modals-form.optionsAnswer')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id={field.id}
            name={field.id}
            value={loadValueOption(field)}
            input={<OutlinedInput label={i18next.t('flow-Modals-form.optionsAnswer')} />}
            MenuProps={MenuProps}
            disabled
          >
            {field.options[0].map((option) => (
              <MenuItem key={`position-${option}`} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
  const email = (field) => (
    <Card key={`card-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label="Resposta"
          fullWidth
          margin="dense"
          required={field.required[0]}
          type="email"
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );

  const date = (field) => (
    <Card key={`card-${field.id}`} sx={{ minWidth: 275, margin: '1% auto' }}>
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position[0]}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label=""
          margin="dense"
          required={field.required[0]}
          type="date"
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );

  const filterField = (field) => {
    if (field.able) {
      if (field.type === 'textbox') return textbox(field);
      if (field.type === 'textboxmultiline') return textboxmultiline(field);
      if (field.type === 'multiselect') return multiselect(field);
      if (field.type === 'combobox') return combobox(field);
      if (field.type === 'email') return email(field);
      if (field.type === 'date') return date(field);
    }
    return null;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('refFormExecution.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={fileTextFill} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="1200px" fullWidth sx={{ margin: '0 auto', width: '60%' }}>
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ backgroundColor: '#DCDCD0' }}>{i18next.t('refFormExecution.title')}</DialogTitle>
          <DialogContent sx={{ backgroundColor: '#DCDCD0' }}>
            {arrPropertiesForm.map((field) => filterField(field))}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#DCDCD0' }}>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
