import { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  Avatar,
  Typography,
  Tooltip,
  IconButton,
  AccordionDetails,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { useQuery } from 'react-query';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import api from '../../../service/api';
import { MetionsComments } from './MetionsComments';

export const NewsForTimeline = ({ idprojeto, User }) => {
  const [expanded, setExpanded] = useState(false);
  const [subComentario, setSubComentario] = useState([]);
  const [subOpen, setSubOpen] = useState([]);
  const [goComment, setGoComment] = useState([]);
  const location = useLocation();
  // const [news, setNews] = useState([]);
  const { t } = useTranslation();

  const regexIdUser = /[[\]:\d]/g;

  const getprojectNews = async (idprojeto) => {
    try {
      const project = await api.get(`projectnews/${idprojeto}`);
      return project.data.data.projectsNews;
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
    return null;
  };

  const { data: news, isLoading } = useQuery(['newsProject', idprojeto], () => getprojectNews(idprojeto), {
    enable: !!idprojeto,
  });

  useEffect(() => {
    setSubOpen(new Array(news?.length).fill(false));
    setGoComment(new Array(news?.length).fill(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news?.length]);

  useEffect(() => {
    if (location.search) {
      const idComenntMention = location.search.split('=')[1];
      if (news?.length > 0) {
        const comment = document.getElementById(idComenntMention);
        const commentMetion = news.find((news) => news.idmoduloacao === Number(idComenntMention));
        const positionInArray = news.indexOf(commentMetion);
        if (positionInArray !== null || positionInArray !== undefined) {
          handleChangeAccordion(
            `panel${positionInArray}`,
            commentMetion.idmoduloacao,
            commentMetion.temcomentario,
            positionInArray
          )(null, true);
        }

        comment.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, news]);

  const getSubComentarios = async (id) => {
    const subComentarios = await api.get(`modulecomentario/${id}`);
    const comments = subComentarios.data.data.modulecomentario;

    const commentsReplace = comments.map((comment) => ({
      ...comment,
      modulocomentariotxt: comment.modulocomentariotxt.replace(regexIdUser, ''),
    }));
    setSubComentario(commentsReplace);
  };

  const publicComent = (idmoduloacao, comentario) => {
    const date = new Date().toJSON();
    if (comentario.length > 0) {
      const newComent = {
        idmoduloacao,
        modulocomentariotxt: comentario.replace(regexIdUser, ''),
        usuarionome: User.current?.usuarionome,
        usuarioicon: User.current?.iconpath,
        modulocomentariodata: `${date}`,
      };
      setSubComentario((arr) => arr.concat(newComent));
    }
  };

  const achaModulo = (id) => {
    const moduloComentado = news.find((comentario) => comentario.idmoduloacao === id);
    moduloComentado.temcomentario = true;
  };

  const sendComent = async (id, comment) => {
    try {
      const response = await api.post(`/modulecomentario`, {
        idmoduloacao: id,
        modulocomentariotxt: comment,
      });

      if (response.status === 200 || response.status === 201) {
        achaModulo(id);
        publicComent(id, comment);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      toast.error(`${t('tabsTimeline.toastErroComment')}`);
    }
  };

  const showSubComentarios = (idmoduloacao) => {
    if (subOpen) {
      return (
        <Box
          sx={{
            marginBottom: '1rem',
            width: '95%',
            marginLeft: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {subComentario.map((subComentario, i) => {
            if (idmoduloacao === subComentario.idmoduloacao) {
              return (
                <Box
                  key={`comentarioBox__${idprojeto}--${i}`}
                  sx={{ border: '2px solid #E6E6E9', padding: '1rem', borderRadius: '5px' }}
                >
                  <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Avatar alt={subComentario.usuarionome} src={subComentario.usuarioicon} />{' '}
                    <Typography
                      gutterBottom
                      variant="body1"
                      sx={{ margin: '1rem 0' }}
                      key={`newInfo__usuario-${subComentario.usuarionome}`}
                    >
                      {subComentario.usuarionome} - {new Date(subComentario.modulocomentariodata).toLocaleDateString()}{' '}
                      às {new Date(subComentario.modulocomentariodata).toLocaleTimeString().slice(0, 5)}
                    </Typography>
                  </Box>
                  <Box sx={{ dispplay: 'flex' }}>
                    <Box>
                      <Typography gutterBottom variant="body1" key={`comentario__${idprojeto}--${i}`}>
                        {t('tabsTimeline.comment')} {subComentario.modulocomentariotxt}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return <></>;
          })}
        </Box>
      );
    }
    return <></>;
  };

  const goToComment = async (position) => {
    const falseArray = await Promise.all(new Array(news?.length).fill(false));
    setGoComment(falseArray);
    const updateCheckedState = await Promise.all(goComment.map((item, index) => (index === position ? !item : item)));
    setGoComment(updateCheckedState);
  };

  const handleClick = (position) => {
    const updateCheckedState = subOpen.map((item, index) => (index === position ? !item : item));
    setSubOpen(updateCheckedState);
  };

  const handleChangeAccordion = (panel, idmodulo, subComment, position) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (subComment) {
      getSubComentarios(idmodulo);
      handleClick(position);
    }
    if (!isExpanded) {
      setGoComment(false);
      handleClick(position);
    }
    if (isExpanded && subComment) {
      setGoComment(true);
    }
  };



  return useMemo(
    () =>
      news && isLoading === false ? (
        <Box sx={{ marginTop: 1 }}>
          {news?.map((newInfo, i) => (
            <Accordion
              expanded={expanded === `panel${i}`}
              key={`comentarioBox__${idprojeto}--${i}`}
              expandicon={<ExpandMore />}
              aria-controls={`panel${i}-content--${idprojeto}`}
              // id={`panel${i}a-header--${idprojeto}`}
              id={newInfo.idmoduloacao}
              onChange={handleChangeAccordion(`panel${i}`, newInfo.idmoduloacao, newInfo.temcomentario, i)}
            >
              <AccordionSummary>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Avatar alt={newInfo.usuarionome} src={newInfo.usuarioicon} />{' '}
                    <Typography
                      gutterBottom
                      variant="body1"
                      sx={{ margin: '1rem 0' }}
                      key={`newInfo__usuario-${newInfo.usuarionome}`}
                    >
                      {newInfo.usuarionome} {' - '} {new Date(newInfo.moduloacaodata).toLocaleDateString()}
                      {` às`} {new Date(newInfo.moduloacaodata).toLocaleTimeString().slice(0, 5)}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ width: '200%' }}>
                      <Typography gutterBottom variant="h6" key={`comentario__titulo-${idprojeto}--${i}`}>
                        {newInfo.moduloacaotitulo}
                      </Typography>
                      <Typography gutterBottom variant="body1" key={`comentario__${idprojeto}--${i}`}>
                        {t('tabsTimeline.comment')}
                        {newInfo.moduloacaotxt}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      {newInfo.temcomentario && (
                        <Tooltip title={t('tabsTimeline.ViewComments')}>
                          <IconButton
                            sx={{ color: 'text.secondary' }}
                            onClick={() => {
                              handleChangeAccordion(`panel${i}`, newInfo.idmoduloacao, newInfo.temcomentario, i);
                            }}
                          >
                            {!subOpen[i] ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title={t('tabsTimeline.tooltipComment')}>
                        <IconButton sx={{ color: 'text.secondary' }} onClick={() => goToComment(i)}>
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>{showSubComentarios(newInfo.idmoduloacao)}</AccordionDetails>
              <Divider />
              {goComment[i] === true ? (
                <MetionsComments
                  User={User}
                  idprojeto={idprojeto}
                  sendComent={sendComent}
                  idmoduloacao={newInfo.idmoduloacao}
                />
              ) : (
                <></>
              )}
            </Accordion>
          ))}
        </Box>
      ) : (
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          {t('tabsTimeline.notactivitiesProject')}
        </Typography>
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [news, showSubComentarios, goComment]
  );
};
