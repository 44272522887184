import { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import CalendarInput from '../../CalendarInput';
import { LoadingForReports } from '../../LoadingForReports';
import api from '../../../service/api';
import ModalReportAuth from './ModalReportAuth';

const ImgBoxStyle = styled(Box)(() => ({
  width: 42,
  height: 42,
}));

export default function ModalReportlaggingProduct({ open, handleClose, title, description, icon }) {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [dateError, setDateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [checked, setChecked] = useState(true);

  const [reportAuth, setReportAuth] = useState(true);
  const [fieldValue, setFieldValue] = useState(false);

  const { t } = useTranslation();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpenAuth = (event) => {
    setReportAuth(true);
    setFieldValue(event.isTrusted);
  };

  const handleCloseAuth = () => {
    setReportAuth(false);
  };

  const handleGenarator = async () => {
    setIsLoading(true);
    try {
      const startDate = dateStart?.format('YYYY-MM-DD');
      const endDate = dateEnd?.format('YYYY-MM-DD');

      const response = await api.get(`/report/laggingproduct/${startDate}/${endDate}`, {
        responseType: 'blob',
      });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];

      fileLink.setAttribute('download', `${fileName}.${extension}`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('report.error'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenaratorPDF = async () => {
    setIsLoading(true);
    try {
      const startDate = dateStart?.format('YYYY-MM-DD');
      const endDate = dateEnd?.format('YYYY-MM-DD');

      const response = await api.get(`/report/laggingproductpdf/${startDate}/${endDate}`, {
        responseType: 'blob',
      });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;

      const fileName = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
      const extension = response.headers['content-disposition'].split('.')[1].split(';')[0];

      fileLink.setAttribute('download', `${fileName}.${extension}`);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('report.error'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle
            component="span"
            sx={{ display: 'flex', flexDirection: 'row', alignSelf: '', marginTop: '2rem' }}
          >
            <ImgBoxStyle component="img" src={icon} sx={{ alignSelf: 'start', marginTop: 0.5 }} />
            <Typography
              component="span"
              variant="h6"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 1, marginLeft: 1 }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText component="span" sx={{ marginTop: '1rem' }}>
              {description}
            </DialogContentText>
            <DialogContentText component="span">
              <Stack>
                <Typography component="span" sx={{ marginTop: '1rem', color: '#637381' }}>
                  {t('report.modal-report.type')}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography component="span">XLSX</Typography>
                  <Switch checked={checked} inputProps={{ 'aria-label': 'ant design' }} onChange={handleChange} />
                  <Typography component="span">PDF</Typography>
                </Stack>
              </Stack>
            </DialogContentText>
            <DialogContentText component="span" sx={{ marginTop: '2rem' }}>
              <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Stack component="span" direction="row" spacing={1} alignItems="center">
                  <CalendarInput
                    setDate={setDateStart}
                    date={dateStart}
                    error={dateError}
                    setError={setDateError}
                    dateReport
                    label={t('report.modal-report.date-start')}
                  />
                </Stack>
                <Stack component="span" direction="row" spacing={1} alignItems="center">
                  <CalendarInput
                    setDate={setDateEnd}
                    date={dateEnd}
                    error={dateError}
                    setError={setDateError}
                    dateReport
                    label={t('report.modal-report.date-end')}
                  />
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {t('report.button.cancel')}
            </Button>
            {checked === false ? (
              <Button variant="contained" onClick={handleGenarator} color="success">
                {t('report.button.generate')}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleClickOpenAuth} color="success">
                {t('report.button.generate')}
              </Button>
            )}
          </DialogActions>
          <LoadingForReports isLoading={isLoading} />
          {fieldValue === true && (
            <ModalReportAuth
              open={reportAuth}
              onClose={handleCloseAuth}
              handleClose={handleCloseAuth}
              genaratorPDF={handleGenaratorPDF}
            />
          )}
        </Dialog>
      </Box>
    </div>
  );
}
