import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../../service/api';
import ModalViewAnnex from '../../../ModalViewAnnex';
import { userStore } from '../../../../store/userStore';
import { CurrentModuleContext } from '../../../../store/CurrentModuleContext';
import AnnexWithDownload from './Modal/AnnexWithDownload';
import { InfosForTimeline } from '../../utils/InfosForTimeline';
import { NewsForTimeline } from '../../utils/newsForTimeline';
import Scrollbar from '../../../Scrollbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsTimeline({ idprojeto }) {
  const [value, setValue] = useState(0);
  const [path, setpath] = useState('');
  const [imageFilename, setImageFilename] = useState();
  const [openModalPdf, setModalPdf] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const getUserPermissions = userStore((state) => state.getUser);
  const [roleUser, setRoleUser] = useState('');
  const [idModuleAnnex, setIdModuleAnnex] = useState(null);
  const currentModuleCtx = useContext(CurrentModuleContext);
  const User = useRef();

  const queryClient = useQueryClient();

  const TIME_REFRESH = 1000 * 60 * 5;

  const { data: imagens } = useQuery(['projectAnnex', idprojeto], () => requestFiles(idprojeto), {
    enable: !!idprojeto,
    refetchOnWindowFocus: true,
    refetchInterval: TIME_REFRESH,
  });
  const { data: imagensReturn } = useQuery(['projectAnnexReturn', idprojeto], () => requestFilesReturn(idprojeto), {
    enable: !!idprojeto,
    refetchOnWindowFocus: true,
    refetchInterval: TIME_REFRESH,
  });

  const { data: imagensRepo } = useQuery(['projectAnnexRepo', idprojeto], () => requestFilesRepo(idprojeto), {
    enable: !!idprojeto,
    refetchOnWindowFocus: true,
    refetchInterval: TIME_REFRESH,
  });


  const projectAnnexCache = queryClient.getQueryData(['projectFlow', idprojeto]);

  const getUser = async () => {
    const userPermissions = await getUserPermissions();
    User.current = userPermissions;
    const admin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
    setRoleUser(admin);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps, consistent-return
  const requestFiles = async (idprojeto) => {
    try {
      const response = await api.get(`/moduleanexos/${idprojeto}`);
      return response.data.data.anexos;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(t('annexToast.notLoad'));
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps, consistent-return
  const requestFilesReturn = async (idprojeto) => {
    try {
      const response = await api.get(`/moduleanexosreturneds/${idprojeto}`);
      return response.data.data.anexos;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(t('annexToast.notLoad'));
      }
    }
  };
  // eslint-disable-next-line consistent-return
  const requestFilesRepo = async (idprojeto) => {
    try {
      const response = await api.get(`/moduleanexosrepository/${idprojeto}`);
      return response.data.data.anexos;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(t('annexToast.notLoad'));
      }
    }
  };

  const onDoubleClickHandler = (selectedDocument) => {
    const baseUrlAndPathfile = `${`/${selectedDocument.moduloanexocaminhopdf}`}`;
    setIdModuleAnnex(selectedDocument.idmoduloanexo);
    setpath(baseUrlAndPathfile);
    setImageFilename(selectedDocument.moduloanexonome);
    setModalPdf(true);
    toast.dismiss();
  };

  // eslint-disable-next-line consistent-return
  const checkUserPriv = (arrPrivUser) => {
    const checkUser = arrPrivUser.some((user) => user === User.current?.idusuario);
    if (!checkUser) {
      return false;
    }
    if (checkUser) {
      return true;
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tabNews = 3;
    if (location.search) {
      setValue(tabNews);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const choiceAnnexs = [];
  const choiceAnnexsSub = [];

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 2,
        backgroundColor: 'background.paper',
        borderRadius: '16px',
        boxShadow:
          ' 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={t('flow-timeline.info-geral')} {...a11yProps(0)} />
          <Tab label={t('flow-timeline.participants')} {...a11yProps(1)} />
          <Tab label={t('flow-timeline.attachment')} {...a11yProps(2)} />
          <Tab label={t('flow-timeline.activities')} {...a11yProps(3)} />
          <Tab label={t('chosen-attachments.title')} {...a11yProps(4)} />
          <Tab label={t('repo-attachments.tabTitle')} {...a11yProps(5)} />
          <Tab label={t('repo-attachments-old.tabTitle')} {...a11yProps(6)} />
        </Tabs>
      </Box>
      <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
        <TabPanel value={value} index={0}>
          {useMemo(
            () => (
              <InfosForTimeline project={projectAnnexCache} />
            ),
            [projectAnnexCache]
          )}
          <Divider />
        </TabPanel>

        <TabPanel value={value} index={1}>
          {useMemo(
            () => (
              <>
                <Typography variant="h4">{t('tabsTimeline.partyProject')}</Typography>
                <Box sx={{ marginBottom: '1rem' }}>
                  <Typography variant="h6">{t('tabsTimeline.areaProject')}</Typography>
                  {projectAnnexCache?.areausuario.area.map((area, i) => (
                    <Typography key={`areanome-${i}`}>- {area.areanome}</Typography>
                  ))}
                </Box>
                <Divider />
                <Box sx={{ marginTop: '1rem' }}>
                  <Typography variant="h6">{t('tabsTimeline.userProject')}</Typography>
                  {projectAnnexCache?.areausuario.usuario.map((usuario, i) => (
                    <Typography key={`areanome-${i}`}>- {usuario.usuarionome}</Typography>
                  ))}
                </Box>
              </>
            ),
            [projectAnnexCache?.areausuario.area, projectAnnexCache?.areausuario.usuario, t]
          )}
        </TabPanel>
      </Scrollbar>
      <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
        <TabPanel value={value} index={2}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            {t('tabsTimeline.annexProject')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <ModalViewAnnex
              path={path}
              openModalPdf={openModalPdf}
              setModalPdf={setModalPdf}
              filename={imageFilename}
              id={imageFilename}
              idModule={currentModuleCtx.idModule}
              idModuleAnnex={idModuleAnnex}
              idProject={projectAnnexCache?.idprojeto}
            />
            {imagens?.map((imagem, index) => {
              if (
                ( checkUserPriv(imagem.moduloanexoprivacidade.userslinked) ||
                imagem.moduloanexoprivacidade.userslinked.length <= 0 ||
                projectAnnexCache?.projetoiniciador.idusuario === User.current?.idusuario ||
                roleUser === 'Administrador') && imagem.moduloanexostatus === '1'
              ) {
                return (
                  <AnnexWithDownload
                    key={`annexx_with_download_${imagem.moduloanexonome}`}
                    index={index}
                    imagem={imagem}
                    onDoubleClickHandler={onDoubleClickHandler}
                  />
                );
              }
              return <></>;
            })}
          </Box>
        </TabPanel>
      </Scrollbar>
      <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
        <TabPanel value={value} index={3}>
          <NewsForTimeline idprojeto={idprojeto} User={User} />
        </TabPanel>
      </Scrollbar>
      <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
        <TabPanel value={value} index={4}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            {t('chosen-attachments.title')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <ModalViewAnnex
              path={path}
              openModalPdf={openModalPdf}
              setModalPdf={setModalPdf}
              filename={imageFilename}
              id={imageFilename}
              idModule={currentModuleCtx.idModule}
              idModuleAnnex={idModuleAnnex}
              idProject={projectAnnexCache?.idprojeto}
            />
            {imagens?.map((imagem, index) => {
              if  (
                (checkUserPriv(imagem.moduloanexoprivacidade.userslinked) ||
                  imagem.moduloanexoprivacidade.userslinked.length <= 0 ||
                  projectAnnexCache?.projetoiniciador.idusuario === User.current?.idusuario ||
                  roleUser === 'Administrador') &&
                imagem.moduloanexoescolhido === 1
              ) {
                choiceAnnexs.push('imagem');
                return (
                  <AnnexWithDownload
                    key={`annexx_with_download_choice_${imagem.moduloanexonome}`}
                    index={index}
                    imagem={imagem}
                    onDoubleClickHandler={onDoubleClickHandler}
                  />
                );
              }
              return <></>;
            })}
            {choiceAnnexs.length === 0 && (
              <Typography variant="h4" sx={{ marginBottom: 2 }}>
                {t('chosen-attachments.notAnnexs')}
              </Typography>
            )}
          </Box>
        </TabPanel>
      </Scrollbar>
      <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
        <TabPanel value={value} index={5}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            {t('repo-attachments.title')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <ModalViewAnnex
              path={path}
              openModalPdf={openModalPdf}
              setModalPdf={setModalPdf}
              filename={imageFilename}
              id={imageFilename}
              idModule={currentModuleCtx.idModule}
              idModuleAnnex={idModuleAnnex}
              idProject={projectAnnexCache?.idprojeto}
            />
            {imagensRepo?.map((imagem, index) => {
              if (
                checkUserPriv(imagem.moduloanexoprivacidade.userslinked) ||
                imagem.moduloanexoprivacidade.userslinked.length <= 0 ||
                projectAnnexCache?.projetoiniciador.idusuario === User.current?.idusuario ||
                roleUser === 'Administrador'
              ) {
                choiceAnnexsSub.push('imagem');
                return (
                  <AnnexWithDownload
                    key={`annexx_with_download_choice_${imagem.moduloanexonome}`}
                    index={index}
                    imagem={imagem}
                    onDoubleClickHandler={onDoubleClickHandler}
                  />
                );
              }
              return <></>;
            })}
            {choiceAnnexsSub.length === 0 && (
              <Typography variant="h4" sx={{ marginBottom: 2 }}>
                {t('repo-attachments.notAnnexs')}
              </Typography>
            )}
          </Box>
        </TabPanel>
      </Scrollbar>
      <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
        <TabPanel value={value} index={6}>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            {/* Teste */}
            {t('repo-attachments-old.title')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              width: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            <ModalViewAnnex
              path={path}
              openModalPdf={openModalPdf}
              setModalPdf={setModalPdf}
              filename={imageFilename}
              id={imageFilename}
              idModule={currentModuleCtx.idModule}
              idModuleAnnex={idModuleAnnex}
              idProject={projectAnnexCache?.idprojeto}
            />
            {imagensReturn?.map((imagem, index) => {
              if (
               checkUserPriv(imagem.moduloanexoprivacidade.userslinked) ||
                imagem.moduloanexoprivacidade.userslinked.length <= 0 ||
                projectAnnexCache?.projetoiniciador.idusuario === User.current?.idusuario ||
                roleUser === 'Administrador'
              ) {
                return (
                  <AnnexWithDownload
                    key={`annexx_with_download_${imagem.moduloanexonome}`}
                    index={index}
                    imagem={imagem}
                    onDoubleClickHandler={onDoubleClickHandler}
                  />
                );
              }
              return <></>;
            })}
          </Box>
        </TabPanel>
      </Scrollbar>
    </Box>
  );
}
