import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import {
  Stack,
  ListItemText,
  ListItem,
  ListItemButton,
  List,
  Checkbox,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// api
import api from '../../../service/api';
import validations from '../../../service/validations';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [userNameMinimal, setUserNameMinimal] = useState();
  const [listRolesName, setRolesName] = useState([]);
  const [checkedPerfil, setCheckedPerfil] = useState(3);
  // const [rolesObject, setRolesObject] = useState([]);

  const [listArea, setlistArea] = useState([]);
  const [checkedArea, setCheckedArea] = useState([]);
  const [areaObject, setAreaObject] = useState([]);

  useEffect(() => {
    async function settings() {
      const settings = await validations('users');
      setUserNameMinimal(settings.userNameMinimal);
    }
    settings();
    requestApiRoles();
    requestApiManageAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function requestRegister(data) {
    const emaillowercase = data.email.toLowerCase();
    try {
      const req = {
        name: data.name,
        email: emaillowercase,
      };
      const response = await api.post('/users', req);
      if (response.data.status === 'success') {
        toast.success(`${t('userRegister.toastSuccessUser')}`);
        requestRegisterPerfil(response.data.data.user.idusuario);
        requestRegisterArea(response.data.data.user.idusuario);
        navigate('/dashboard/app', { replace: true });
      }
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }
  async function requestRegisterPerfil(userId) {
    if (checkedPerfil > 0) {
      // const listIdPerfil = checkedPerfil.map((value) => {
      //   const perfil = rolesObject.find((perfil) => perfil.perfilnome === value);
      //   return perfil.idperfil;
      // });

      try {
        const req = {
          roles: [checkedPerfil],
        };

        const response = await api.post(`/users/${userId}`, req);
        if (response.data.status === 'success') {
          toast.success(`${t('userRegister.toastSuccessPerfil')}`);
          navigate('/dashboard/app', { replace: true });
        }
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(`${error.response.data.data.errors[0]}`);
        }
        if (error.response.status === 401) {
          // toast.error(`${error.response.data.data.errors[0]}`);
          toast.error(`${t('not-Access')}`);
        } else {
          toast.error(`${error.response.data.data.errors[0]}`);
        }
      }
    } else {
      navigate('/dashboard/app', { replace: true });
    }
  }

  async function requestApiRoles() {
    try {
      // const responseRoles = await api.get('/perfis');
      // eslint-disable-next-line prefer-destructuring
      // const roles = responseRoles.data.data.perfis;
      // const listRoles = roles.map((perfil) => perfil.perfilnome);
      // const listChecked = {};
      // listRoles.forEach((role) => {
      //   listChecked[role] = false;
      // });

      // Fazendo desse jeito para manter os perfils traduzidos
      const listRoles = [t('manage-access.administrator'), t('manage-access.manager'), t('manage-access.common')];

      setRolesName(listRoles);

      // setRolesObject(roles);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function requestApiManageAreas() {
    try {
      const responseAreas = await api.get(`/areas`);

      const area = responseAreas.data.data.areas;
      setAreaObject(area);
      const listAreas = area.map((value) => value.areanome);
      setlistArea(listAreas);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function requestRegisterArea(idUser) {
    if (checkedArea.length > 0) {
      const listIdArea = checkedArea.map((value) => {
        const areas = areaObject.find((area) => area.areanome === value);
        return areas.idarea;
      });

      try {
        listIdArea.forEach(async (areaIdSelected) => {
          const response = await api.post(`/areainslistausers/${areaIdSelected}`, {
            listauser: [idUser],
          });
          if (response.data.status === 'success') {
            toast.success(`${t('userRegister.toastSuccessArea')}`);
            navigate('/dashboard/app', { replace: true });
          }
        });
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(`${error.response.data.data.errors[0]}`);
        }
        if (error.response.status === 401) {
          // toast.error(`${error.response.data.data.errors[0]}`);
          toast.error(`${t('not-Access')}`);
        } else {
          toast.error(`${error.response.data.data.errors[0]}`);
        }
      }
    } else {
      navigate('/dashboard/app', { replace: true });
    }
  }

  const handleChange = (event) => {
    // Usando o index +1 para ser o valor, 1 admin, 2 gerente , 3 comum
    setCheckedPerfil(event.target.value);
  };

  const handleToggleArea = (value) => () => {
    const currentIndex = checkedArea.indexOf(value);
    const newChecked = [...checkedArea];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedArea(newChecked);
  };

  const nameRequired = t('validation.register.name-required');
  const nameInvalid = t('validation.register.name-validate');
  const nameTooShort = `${t('validation.register.name-short')} ${userNameMinimal}`;
  const emailRequired = t('validation.register.email-required');
  const emailInvalid = t('validation.register.email-validate');

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-/.]+$/, nameInvalid)
      .max(40)
      .required(nameRequired)
      .min(userNameMinimal, nameTooShort),
    email: Yup.string().email(emailInvalid).required(emailRequired),
  });

  const defaultValues = {
    name: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (checkedPerfil !== 0 && checkedArea.length > 0) {
      requestRegister(data);
    } else {
      toast.error(`${t('userRegister.toastError')}`);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label={t('register.pleacehold-name')} />
        </Stack>

        <RHFTextField name="email" label={t('register.pleacehold-email')} />
        <Typography variant="subtitle1">Perfil do Usuário</Typography>
        <List
          dense
          sx={{
            width: '100%',
            maxWidth: 480,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 200,
          }}
          name="perfil"
        >
          <FormControl sx={{ width: '95%' }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={checkedPerfil}
              onChange={handleChange}
            >
              {listRolesName.map((valueLabel, i) => (
                <FormControlLabel
                  key={valueLabel}
                  value={i + 1}
                  control={<Radio />}
                  label={valueLabel}
                  labelPlacement="start"
                  sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </List>
        <Typography variant="subtitle1">Área Relacionada</Typography>
        <List
          dense
          sx={{
            width: '100%',
            maxWidth: 480,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 200,
          }}
          name="area"
        >
          {listArea.map((value, index) => {
            const labelId = `checkbox-list-secondary-label-${value}`;
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <Checkbox edge="end" onChange={handleToggleArea(value)} inputProps={{ 'aria-labelledby': labelId }} />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText id={labelId} primary={`${value}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('register.button-resgister')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
