import { Button, Dialog, Box, Avatar, Typography, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import i18next from 'i18next';
import DialogTitle from '@mui/material/DialogTitle';
import api from '../../../../../service/api';
import { ProjectStatus } from '../../../../../enum/ProjectStatusEnum';

export default function CancelProject({ setCancelProjectModalOpen, cancelProjectModalOpen, project }) {
  const queryClient = useQueryClient();
  const handleChangeStatus = async (newStatus) => {
    try {
      const body = {
        status: newStatus,
      };

      const projectsResponse = await api.put(`projectstatus/${project?.idprojeto}`, body);

      if (projectsResponse.status === 201) {
        toast.success(i18next.t('projects.edit-projects.edited-with-success'));
        handleClose();
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      console.log(error);
    }
  };

  const postProjectMutation = useMutation((newStatus) => handleChangeStatus(newStatus), {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects']);
    },
    onError: (error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    },
  });

  const handleClose = () => {
    setCancelProjectModalOpen(false);
  };

  return (
    <div>
      <Dialog
        open={cancelProjectModalOpen}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{i18next.t('flow-Modals-changeStatusProject.title-rate-cancelProject')}</DialogTitle>
        <DialogContent>
          {cancelProjectModalOpen && (
            <Box id="alert-dialog-slide-description">
              <Typography variant="h5" component="span">
                {project?.projetonome}
              </Typography>
              <Box key={`argsBox__${project?.idprojeto}`} sx={{ padding: '1rem', borderRadius: '5px' }}>
                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <Avatar alt={project.projetosolicitor?.usuarionome} src={project.projetosolicitor?.iconpath} />
                  <Typography
                    gutterBottom
                    variant="body1"
                    sx={{ margin: '1rem 0' }}
                    key={`argscancel__${project?.idprojeto}`}
                  >
                    {project?.projetosolicitor.usuarionome} -{' '}
                    {new Date(project?.projetodatasolicitacaocancelamento).toLocaleDateString()} às{' '}
                    {new Date(project?.projetodatasolicitacaocancelamento).toLocaleTimeString().slice(0, 5)}
                  </Typography>
                </Box>
                <Box sx={{ dispplay: 'flex' }}>
                  <Box sx={{ border: '2px solid #eee ', padding: 2, borderRadius: '5px' }}>
                    <Typography gutterBottom variant="body1" key={`argscancel__${project?.idprojeto}`}>
                      {project?.projetojustificacancel}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Stack sx={{ margin: '5% auto ', flexDirection: 'row', justifyContent: 'center', gap: '3rem' }}>
            <Button onClick={() => handleChangeStatus(ProjectStatus.InProgress)} variant="contained">
              {i18next.t('flow-Modals-changeStatusProject.negate-cancelProject')}
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => postProjectMutation.mutate(ProjectStatus.Canceled)}
            >
              {i18next.t('flow-Modals-changeStatusProject.accept-cancelProject')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
}
