import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import toast from 'react-hot-toast';
// material
import {
  Stack,
  Typography,
  InputAdornment,
  List,
  Box,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import infoIcon from '@iconify/icons-mdi/information-variant';
import styled from '@emotion/styled';
import JSZip from 'jszip';
import { saveAs } from 'save-as';
import { Icon } from '@iconify/react';
import arrowBackIcon from '@iconify/icons-material-symbols/arrow-back-rounded';
import arrowForwardIcon from '@iconify/icons-material-symbols/arrow-forward-rounded';
import folderAddIcon from '@iconify/icons-mdi/folder-add';
import uploadIcon from '@iconify/icons-ic/round-file-upload';
import downloadIcon from '@iconify/icons-ic/round-file-download';
import fileRefreshIcon from '@iconify/icons-mdi/file-refresh';
import searchIcon from '@iconify/icons-eva/search-fill';
// React Query
import { useQueryClient } from 'react-query';
// i18n
import { useTranslation } from 'react-i18next';
// icon
import { useTheme } from '@emotion/react';
// components
import RepositoryBreadcrumbs from '../components/RepositoryBreadcrumbs';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import UploadFiles from '../components/UploadFiles';
import RepositoryFiles from '../components/RepositoryFiles';
// layouts
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// api
import api, { baseURLFile } from '../service/api';
import NewFolder from '../components/NewFolder';
import RepositoryProductField from '../components/RepositoryProductField';
import ModalViewAnnex from '../components/ModalViewAnnex';

// ----------------------------------------------------------------------

// Style
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  fontSize: '0.8rem',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const ExplorerBoxStyle = styled(Box)(() => ({
  marginTop: '120px',
  marginLeft: '66px',
  height: `calc(100% - ${92}px)`,
  display: 'grid',
  gridTemplateRows: '8% 92%',
  gridTemplateColumns: '20% 80%',
  gridTemplateAreas: `"header header"
  "sidebar main"
  `,
}));

const Sidebar = styled(Box)(({ theme }) => ({
  gridArea: 'sidebar',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  borderRight: `1px solid ${theme.palette.table_filler}`,
}));

const MainContent = styled(Box)(({ theme }) => ({
  gridArea: 'main',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  padding: '5px',
}));

const HeaderContent = styled(Box)(({ theme }) => ({
  gridArea: 'header',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.table_filler}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '5px',
}));

const BreadCrumbsBox = styled(Box)(() => ({
  display: 'flex',
  height: '85%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginLeft: 3,
  border: '1px solid #ccc',
  borderRadius: 50,
  padding: '0 20px 0 20px',
  flexGrow: 1,
  position: 'relative',
}));

// ----------------------------------------------------------------------
export default function Repository() {
  const [produtos, setProdutos] = useState([]);
  const [open, setOpen] = useState(true);
  const [filterProductTagSKU, setFilterProductTagSKU] = useState('');
  const [tagsSKU, setTagsSKU] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [order, setOrder] = useState('asc');
  // eslint-disable-next-line no-unused-vars
  const [orderBy, setOrderBy] = useState('produtonome');
  const [newFile, setNewFile] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('Pasta Sem Título');
  // eslint-disable-next-line no-unused-vars
  const [folders, setFolders] = useState([]);
  const [repoFolder, setRepoFolder] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [directory, setDirectory] = useState(null);
  const [navigatedDirectory, setNavigatedDirectory] = useState([]);
  const [navigationIndex, setNavigationIndex] = useState(-1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [iconsSize, setIconsSize] = useState({ width: 90, height: 112.5 });
  const [selectedProductObj, setSelectedProductObj] = useState(null);
  const [loggedUser, setLoggedUser] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(false);
  const [modalAnnexData, setModalAnnexData] = useState(null);
  const [openModalPdf, setModalPdf] = useState(false);
  const [areAllItemsObsolete, setAreAllItemsObsolete] = useState(false);

  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  const theme = useTheme();

  const { t } = useTranslation();

  const zip = new JSZip();

  const matches1920UP = useMediaQuery(theme.breakpoints.up(1920));
  const matches1920 = useMediaQuery(theme.breakpoints.down(1920));
  const matches1745 = useMediaQuery(theme.breakpoints.down(1745));
  const matches1536 = useMediaQuery(theme.breakpoints.down(1536));

  const checkResolutionMatchesForIcons = () => {
    if (matches1536) return { width: 60, height: 75 };
    if (matches1745) return { width: 70, height: 87.5 };
    if (matches1920) return { width: 80, height: 100 };
    if (matches1920UP) return { width: 90, height: 112.5 };
    return iconsSize;
  };

  // Setar o objeto do produto selecionado para passar ao Breadcrumbs
  useEffect(() => {
    if (selectedProduct) {
      const filteredProduct = produtos.filter((product) => product.idproduto === selectedProduct);
      setSelectedProductObj(...filteredProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  // Atualizar a função que deixa os ícones responsivos
  useEffect(() => {
    setIconsSize(checkResolutionMatchesForIcons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches1920UP, matches1920, matches1745, matches1536]);

  // Função que verificar o perfil do usuário
  const RepoPermission = async () => {
    // const userPermissions = await getUserPermissions();
    if (accountCache !== undefined && accountCache !== null) {
      const isAdmin = await accountCache.roles.some((role) => role.perfilnome === 'Administrador');
      setLoggedUser(accountCache?.idusuario);
      setIsAdmin(isAdmin);
    }
  };

  const requestProduct = async () => {
    try {
      const response = await api.get('productfolders');
      const product = response.data.data.products;
      setProdutos(product);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('repo.errors.request-products'));
      }
    }
  };

  const requestTagsSKU = async () => {
    try {
      const tagsResponse = await api.get(`/tag`);
      setTagsSKU(tagsResponse.data.data.tags.filter((tag) => tag.tagtipo === 'Tag-SKU'));
    } catch (error) {
      if (error.response.status === 409) {
        console.log(error);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const requestRepo = async (selectedProductId, directory) => {
    try {
      const response = await api.get(`repository/product/${selectedProductId}`);

      // if (!response.data.data.productRepository) {
      // requestRepo(selectedProductId);
      // }

      // if (response.data.data.productRepository) {
      setNavigatedDirectory([]);
      setRepoFolder(response.data.data.productRepository);
      setNavigatedDirectory((prevState) => [...prevState, response.data.data.productRepository.path]);

      if (!directory) setDirectory(response.data.data.productRepository.path);

      if (directory) setDirectory(directory);
      // }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('repo.errors.request-files'));
      }
    }
  };

  const createNewFolder = async (path) => {
    try {
      const postNewFolderResponse = await api.post('repositorydirectory', {
        path,
        namedirectory: newFolderName,
      });

      requestRepo(selectedProduct, directory);

      if (postNewFolderResponse.data.data.message === t('repo.errors.folder-name')) {
        toast.error(`${postNewFolderResponse.data.data.message}`);
      } else {
        toast.success(`${postNewFolderResponse.data.data.message}`);
      }
    } catch (error) {
      if (error.response.status === 409) {
        console.log(error);
        toast.error(t('repo.errors.folder-creation'));
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const moveDirectoryHandler = async (targetItem, sourceItem) => {
    if (targetItem.path === sourceItem.path) return;
    try {
      // eslint-disable-next-line no-unused-vars
      const moveResponse = await api.put(`/repository`, {
        source: sourceItem.path,
        target: `${targetItem.path}/${sourceItem.name}`,
        type: sourceItem.type,
      });

      requestRepo(selectedProduct, directory);
    } catch (error) {
      if (error.response.status === 409) {
        console.log(error);
        toast.error(t('repo.errors.move'));
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  const downloadFile = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.path}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileObj.filename;
        alink.click();
      });
    });
  };

  const getFileBlob = async (baseURLFile, filePath) => {
    const fileResponse = await fetch(`${baseURLFile}/${filePath}`);
    const blob = await fileResponse.blob();
    const newBlob = new Blob([blob]);
    return newBlob;
  };

  const createZipNestedFolders = (folderObj, zipFolder) => {
    folderObj.children.forEach((child) => {
      if (child.type === 'file') {
        if (child.status !== 'O' && child.status !== 'P')
          zipFolder?.file(child.filename, getFileBlob(baseURLFile, child.path));
        if ((child.status === 'O' || child.status === 'P') && isAdmin)
          zipFolder?.file(child.filename, getFileBlob(baseURLFile, child.path));
      }

      if (child.type === 'directory' && child.isEmpty) {
        zipFolder.folder(child.name);
      }
      if (child.type === 'directory' && !child.isEmpty) {
        const childFolder = zipFolder.folder(child.name);
        createZipNestedFolders(child, childFolder);
      }
    });
  };

  const downloadFileHandler = async (selectedItems) => {
    const [productObject] = produtos.filter((product) => product.idproduto === selectedProduct);

    if (selectedItems.length === 1) {
      const [selectedItem] = selectedItems;
      if (selectedItem.type === 'file') downloadFile(baseURLFile, selectedItem);

      if (selectedItem.type === 'directory') {
        const mainFolder = zip.folder(selectedItem.name);
        createZipNestedFolders(selectedItem, mainFolder);
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${productObject.produtonome}`);
        });
      }
    }

    if (selectedItems.length > 1) {
      selectedItems.forEach((item) => {
        if (item.type === 'file') zip.file(item.filename, getFileBlob(baseURLFile, item.path));

        if (item.type === 'directory') createZipNestedFolders(item, zip.folder(item.name));
      });
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${productObject.produtonome}`);
      });
    }
    setSelectedItems([]);
  };

  const reactivateFileHandler = async (selectedItems) => {
    try {
      const promises = selectedItems.map((item) => api.put(`repository/${item.id}`, { status: 'V' }));

      await Promise.all(promises);

      requestRepo(selectedProduct, directory);
      if (selectedItems.length === 1) toast.success(t('reactiveFileRepo.toastSuccessOne'));
      else toast.success(t('reactiveFileRepo.toastSuccessMore'));
    } catch (error) {
      if (selectedItems.length === 1) toast.error(t('reactiveFileRepo.toasterrorOne'));
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else toast.error(t('reactiveFileRepo.toasterrorMore'));
    }
    setSelectedItems([]);
  };

  // Checar se todos os items selecionados são obsoletos para permitir a função de reativar
  useEffect(() => {
    const allItemsAreObsolete = selectedItems.every((item) => item.status === 'O');
    setAreAllItemsObsolete(allItemsAreObsolete);
  }, [selectedItems]);

  useEffect(() => {
    requestProduct();
    requestTagsSKU();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    RepoPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountCache]);

  // Atualizar index de navegação caso volte pela seta e clique uma pasta
  useEffect(() => {
    setNavigationIndex(-1);
  }, [navigatedDirectory]);

  // Se repoFolder não estiver setado, tira a seleção da sidebar de produtos
  useEffect(() => {
    if (directory === 'products') {
      setSelectedProduct(null);
      setNavigatedDirectory([]);
      setRepoFolder(null);
    }
  }, [directory]);

  // Resetar o index de navegação
  useEffect(() => {
    setNavigationIndex(-1);
  }, [repoFolder]);

  // Limpar o state de arquivos selecionados
  useEffect(() => {
    setSelectedItems([]);
  }, [directory]);

  const handleFilterByProjectTagSKU = (event) => {
    setFilterProductTagSKU(event.target.value);
  };

  const filteredProducts = applySortFilter(produtos, getComparator(order, orderBy), filterProductTagSKU);

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      const arrayOfFilteredProducts = [];

      const arrayOfFilteredTagSKU = [];
      filter(tagsSKU, (_tag) => _tag.tagnome.toLowerCase().indexOf(query.toLowerCase()) !== -1).map((tag) =>
        array.map((product) =>
          product.tags.forEach((tagSKU) => tagSKU.idtag === tag.idtag && arrayOfFilteredTagSKU.push(product))
        )
      );

      const filteredByName = filter(
        array,
        (_product) => _product.produtonome.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );

      arrayOfFilteredProducts.push(...filteredByName);

      arrayOfFilteredProducts.push(...arrayOfFilteredTagSKU);

      const finalArrayOfFilteredProjects = [...new Set(arrayOfFilteredProducts)];

      return finalArrayOfFilteredProjects;
    }
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const productClickHandler = (selectedProductId) => {
    requestRepo(selectedProductId);
    setSelectedProduct(selectedProductId);
  };

  const uploadFileHandler = () => {
    setNewFile(true);
  };

  const clickNewFolderHandler = () => {
    setNewFolderName(t('repo.default-folder-name'));
    setNewFolder(true);
  };

  const createNewFolderHandler = () => {
    createNewFolder(directory);
    setNewFolder(false);
    setNewFolderName(t('repo.default-folder-name'));
  };

  const navigateBackHandler = () => {
    const currentIndex = navigationIndex;

    setDirectory(navigatedDirectory.at(currentIndex - 1));
    const newArrayDirectory = navigatedDirectory.pop();
    setNavigatedDirectory(navigatedDirectory.filter((path) => path !== newArrayDirectory));
    setNavigationIndex((prevState) => prevState - 1);
  };

  const navigateForwardHandler = () => {
    const currentIndex = navigationIndex;

    setDirectory(navigatedDirectory.at(currentIndex + 1));

    setNavigationIndex((prevState) => prevState + 1);
  };

  const openModalHandler = (path, filename, id, itemStatus) => {
    setModalAnnexData({ path, filename, id, itemStatus });
    setModalPdf(true);
  };

  return (
    <Page sx={{ height: '100vh', width: '100%', overflow: 'hidden' }} title={t('sidebar.repo')}>
      {openModalPdf && (
        <ModalViewAnnex
          path={modalAnnexData?.path}
          openModalPdf={openModalPdf}
          setModalPdf={setModalPdf}
          filename={modalAnnexData?.filename}
          id={modalAnnexData?.id}
          isAdmin={isAdmin}
          itemStatus={modalAnnexData?.itemStatus}
          readOnly
        />
      )}
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <Box sx={{ height: '97%', position: 'relative' }}>
        <Stack
          sx={{ position: 'absolute', top: -40, left: 75, zIndex: 100 }}
          direction="row"
          alignItems="start"
          justifyContent="space-around"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t('repo.title')}
          </Typography>
        </Stack>

        {/* Create Folder */}
        <Box>
          <Dialog onClose={() => setNewFolder(false)} open={newFolder} sx={{ width: 1200, margin: '0 auto' }}>
            <DialogTitle>{t('repo.new-folder')}</DialogTitle>
            <DialogContent>
              <NewFolder
                setNewFolderName={setNewFolderName}
                newFolderName={newFolderName}
                handleClose={() => setNewFolder(false)}
                onClickFunction={newFolder ? createNewFolderHandler : undefined}
              />
            </DialogContent>
          </Dialog>
        </Box>

        {/* Modal upload files */}
        <Box>
          <Dialog onClose={() => setNewFile(false)} open={newFile} sx={{ width: 1200, margin: '0 auto' }}>
            <DialogTitle>{t('repo.upload-file')}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t('repo.upload-text')}</DialogContentText>
              <UploadFiles
                handleClose={() => setNewFile(false)}
                produtosId={selectedProduct}
                path={directory}
                requestRepo={requestRepo}
              />
            </DialogContent>
          </Dialog>
        </Box>

        {/* Lista de Produtos */}
        <ExplorerBoxStyle>
          <HeaderContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', height: '100%' }}>
              <Tooltip title={t('repo.navigation-backward')} disableInteractive>
                <span>
                  <IconButton
                    color="greyButton"
                    disabled={navigationIndex === -navigatedDirectory.length || !repoFolder}
                    onClick={navigateBackHandler}
                    sx={{ height: '100%', width: 50, borderRadius: 0 }}
                  >
                    <Icon icon={arrowBackIcon} sx={{ fontSize: 100 }} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t('repo.navigation-forward')} disableInteractive>
                <span>
                  <IconButton
                    color="greyButton"
                    disabled={navigationIndex === -1}
                    onClick={navigateForwardHandler}
                    sx={{ height: '100%', width: 50, borderRadius: 0 }}
                  >
                    <Icon icon={arrowForwardIcon} sx={{ fontSize: 100 }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <BreadCrumbsBox sx={{ position: 'relative' }}>
              <RepositoryBreadcrumbs
                product={selectedProductObj}
                directory={directory}
                setDirectory={setDirectory}
                moveDirectoryHandler={moveDirectoryHandler}
                setNavigatedDirectory={setNavigatedDirectory}
                setNavigationIndex={setNavigationIndex}
              />
              <Box sx={{ position: 'absolute', top: '50%', right: 0, transform: 'translateX(-50%)' }}>
                <Tooltip title={t('repo.breadcrumbs-info')} disableInteractive>
                  <InputAdornment position="start">
                    <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                </Tooltip>
              </Box>
            </BreadCrumbsBox>
            <Box sx={{ height: '100%' }}>
              {isAdmin && (
                <>
                  <Tooltip title={t('repo.create-folder')} disableInteractive>
                    <span>
                      <IconButton
                        disabled={!repoFolder}
                        color="greyButton"
                        sx={{ height: '100%', width: 50, borderRadius: 0 }}
                        onClick={clickNewFolderHandler}
                      >
                        <Icon icon={folderAddIcon} sx={{ fontSize: 100 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('repo.do-upload-file')} disableInteractive>
                    <span>
                      <IconButton
                        color="greyButton"
                        disabled={!repoFolder}
                        sx={{ height: '100%', width: 50, borderRadius: 0 }}
                        onClick={uploadFileHandler}
                      >
                        <Icon icon={uploadIcon} sx={{ fontSize: 100 }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              )}
              <Tooltip title={t('repo.download-selected')} disableInteractive>
                <span>
                  <IconButton
                    color="greyButton"
                    disabled={!selectedItems.length}
                    onClick={() => downloadFileHandler(selectedItems)}
                    sx={{ height: '100%', width: 50, borderRadius: 0 }}
                  >
                    <Icon icon={downloadIcon} sx={{ fontSize: 100 }} />
                  </IconButton>
                </span>
              </Tooltip>
              {isAdmin && (
                <Tooltip title={t('repo.reactivate-selected')} disableInteractive>
                  <span>
                    <IconButton
                      color="greyButton"
                      disabled={!selectedItems.length || !areAllItemsObsolete}
                      sx={{ height: '100%', width: 50, borderRadius: 0 }}
                      onClick={() => reactivateFileHandler(selectedItems)}
                    >
                      <Icon icon={fileRefreshIcon} sx={{ fontSize: 100 }} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </HeaderContent>
          <Sidebar>
            {/* Motor de busca Produtos */}
            <Box sx={{ padding: '5px' }}>
              <SearchStyle
                value={filterProductTagSKU}
                onChange={(event) => handleFilterByProjectTagSKU(event)}
                placeholder={t('repo.search-products')}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon icon={searchIcon} sx={{ color: 'text.disabled', width: 20 }} />
                  </InputAdornment>
                }
                endAdornment={
                  <Tooltip title={t('repo.search-name-tagsku')} disableInteractive>
                    <InputAdornment position="start">
                      <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  </Tooltip>
                }
              />
            </Box>
            {/* PRODUTOS */}
            <Scrollbar>
              <List
                sx={{
                  width: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  {filteredProducts.map((value) => (
                    <RepositoryProductField
                      key={value.idproduto}
                      product={value}
                      productClickHandler={productClickHandler}
                      selectedProduct={selectedProduct}
                    />
                  ))}
                </Box>
              </List>
            </Scrollbar>
          </Sidebar>
          <MainContent>
            {repoFolder && (
              <RepositoryFiles
                SearchStyle={SearchStyle}
                folder={repoFolder}
                uploadFileHandler={uploadFileHandler}
                clickNewFolderHandler={clickNewFolderHandler}
                moveDirectoryHandler={moveDirectoryHandler}
                directory={directory}
                setDirectory={setDirectory}
                setNavigatedDirectory={setNavigatedDirectory}
                downloadFileHandler={downloadFileHandler}
                reactivateFileHandler={reactivateFileHandler}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                iconsSize={iconsSize}
                loggedUser={loggedUser}
                isAdmin={isAdmin}
                openModalHandler={openModalHandler}
              />
            )}
          </MainContent>
        </ExplorerBoxStyle>
      </Box>
    </Page>
  );
}
