import { styled } from '@mui/material/styles';

import { Grid, Modal, Typography, TextField, Box, FormControl, Autocomplete } from '@mui/material';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';

import moment from 'moment/moment';

import toast from 'react-hot-toast';

import IconButton from '@mui/material/IconButton';

import i18next from 'i18next';

import { useTranslation } from 'react-i18next';

import { useEffect, useLayoutEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { format } from 'date-fns';
import { NameProjectsEnum } from '../../../../../enum/NameProjectsEnum';
import { StyledButton } from '../../../../styled-global';
import { textfieldMaxLength } from '../../../../../utils/textfieldMaxLength';

import api from '../../../../../service/api';

import ConnectAreasAndUsers from '../../../../ConnectAreasAndUsers';
import CalendarInput from '../../../../CalendarInput';

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

const FormControlStyle = styled(FormControl)(() => ({
  display: 'flex',
  height: '90%',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const TagBox = styled(Box)(() => ({
  height: '1.3rem',
  width: 50,
  marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 8px #808080',
  minWidth: 'max-content',
}));

const TextFieldWithPositionedErrors = styled(TextField)(() => ({
  '.css-16d5wub-MuiFormHelperText-root': { position: 'absolute', top: 15, right: 20 },
}));

const EditProjectsModal = ({ isModalOpen, setModalOpen, project, AllTags, page, rowsPerPage, setDetailsModalOpen }) => {
  const [selectedProject, setSelectedProject] = useState({
    idproduto: null,
    idprojeto: null,
    idtag: null,
    projetobase: null,
    projetodescricao: '',
    projetocriacao: null,
    projetodtconclusao: null,
    projetoiniciador: null,
    projetonome: '',
    projetostatus: null,
    projetovinculo: null,
  });
  const [tags, setTags] = useState([]);
  const [date, setDate] = useState(null);
  const [dateError, setDateError] = useState('');
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [projectTag, setProjectTag] = useState({
    idtag: null,
    tagnome: '',
    tagcolor: '',
    tagstatus: null,
    tagtipo: 'Tag',
  });

  const [areasUsers, setAreasUsers] = useState(null);
  const [originalProject, setOriginalProject] = useState(null);
  const [equalNameError, setEqualNameError] = useState(false);

  const [similarNameWarning, setSimilarNameWarning] = useState(false);

  const [emptyNameError, setEmptyNameError] = useState(false);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const usersCache = queryClient.getQueryData(['users']);
  const areasCache = queryClient.getQueryData(['areas']);

  const closeModalHandler = () => {
    setModalOpen(false);
    clearInputs();
  };

  useEffect(() => {
    const tagsFilter = AllTags.filter((tag) => tag.tagstatus === 1);
    // const tagsFilter = AllTags.filter((tag) => tag.tagtipo === 'Tag' && tag.tagstatus === 1);
    setTags(tagsFilter);
  }, [AllTags]);

  useLayoutEffect(() => {
    clearInputs();
    if (project) {
      if (project.projetodtconclusao === 'NaN/NaN/NaN NaN:NaN:NaN') project.projetodtconclusao = null;
      setSelectedProject(project);
      setOriginalProject(project);
      if (project?.projetodtconclusao) {
        const isDateFormatted = project?.projetodtconclusao.split(' ').length === 2;
        let formattedDate = project?.projetodtconclusao;

        if (!isDateFormatted) {
          if (i18next.language === 'en') formattedDate = format(new Date(project?.projetodtconclusao), 'MM-dd-yyyy');
          else formattedDate = format(new Date(project?.projetodtconclusao), 'dd-MM-yyyy');
        }

        if (isDateFormatted) {
          if (i18next.language === 'en') {
            const splittedDate = formattedDate.split(' ')[0].split('/');
            formattedDate = `${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`;
          } else {
            formattedDate = formattedDate.split(' ')[0];
          }
        }

        const dateMomentObject = moment(formattedDate, `${i18next.language === 'en' ? 'MM-DD-YYYY' : 'DD-MM-YYYY'}`);
        setSelectedProject((prevProject) => ({ ...prevProject, projetodtconclusao: dateMomentObject }));
        setDate(dateMomentObject);
      }

      if (project.projetovinculo?.area.length > 0) {
        const filteredAreas = project.projetovinculo?.area?.map((idArea) => {
          const [returnedAreasObject] = areasCache?.filter((area) => area.idarea === idArea);
          return returnedAreasObject;
        });
        setSelectedAreas(filteredAreas);
      }

      if (project.areausuario?.usuario.length > 0) {
        setSelectedUsers(project.areausuario.usuario);
      }
      if (project?.idtag) {
        const [filteredTag] = AllTags.filter((tag) => tag.idtag === project.idtag);
        setProjectTag(filteredTag);
      }
      const onlyTagType = AllTags.filter((tag) => tag.tagtipo === 'Tag');
      setTags(onlyTagType);
    }
  }, [AllTags, areasCache, project, isModalOpen]);

  const changeProjectProp = (prop, inputValue) => {
    setSelectedProject((prevProject) => ({ ...prevProject, [prop]: inputValue }));
  };

  // useEffect para atualizar o valor de data de conclusão
  useEffect(() => {
    setSelectedProject((prevProject) => ({ ...prevProject, projetodtconclusao: moment(date, 'YYYY-MM-DD') }));
  }, [date]);

  const tagInputChageHandler = (value) => {
    if (value === null) {
      setProjectTag({
        idtag: null,
        tagnome: '',
        tagcolor: '',
        tagstatus: null,
        tagtipo: 'Tag',
      });
    } else {
      setProjectTag(value);
    }
  };

  const editProjectHandler = () => {
    if (selectedProject.projetonome.trim() === '') {
      setEmptyNameError(true);
      return;
    }

    if (equalNameError) return;

    postMutation.mutate();
  };

  const postMutation = useMutation(() => postEditedProject(), {
    onSuccess: () => {
      queryClient.invalidateQueries(['projects', page, rowsPerPage]);
      setDetailsModalOpen();
    },
    onError: (error) => {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    },
  });

  const postEditedProject = async () => {
    try {
      const tagId = projectTag.idtag;
      const newProject = {
        projetonome: selectedProject.projetonome,
        projetodescricao: selectedProject.projetodescricao,
        projetovinculo: areasUsers,
      };
      if (tagId !== null) newProject.idtag = tagId;
      if (tagId === null) newProject.idtag = null;
      if (date !== null) newProject.projetodtconclusao = date.format('YYYY-MM-DD');
      if (date === null || date === '') newProject.projetodtconclusao = null;
      if (
        originalProject.projetonome === newProject.projetonome &&
        originalProject.projetodescricao === newProject.projetodescricao &&
        JSON.stringify(originalProject.projetovinculo) === newProject.projetovinculo &&
        originalProject.idtag === newProject.idtag &&
        newProject.projetodtconclusao === null &&
        originalProject.projetodtconclusao === newProject.projetodtconclusao
        // (originalProject.projetodtconclusao !== null ? originalProject.projetodtconclusao.split('T')[0] : null) ===
        //   newProject.projetodtconclusao
      ) {
        setModalOpen(false);
        return;
      }

      if (selectedAreas.length > 0) {
        const projectsResponse = await api.put(`project/${selectedProject.idprojeto}`, newProject);
        if (projectsResponse.status === 201) {
          toast.success(t('projects.edit-projects.edited-with-success'));
          setModalOpen(false);
          clearInputs();
        }
      } else {
        toast.error(t('notAreaBondProject'));
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } else if (selectedProject.projetonome.length > textfieldMaxLength) {
        toast.error(t('projects.edit-projects.project-name-maxLength', { textfieldMaxLength }));
      } else if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const clearInputs = () => {
    setSelectedProject({
      idproduto: null,
      idprojeto: null,
      idtag: null,
      projetobase: null,
      projetocriacao: '',
      projetodescricao: '',
      projetodtconclusao: null,
      projetoiniciador: null,
      projetonome: '',
      projetostatus: null,
      projetovinculo: null,
    });
    setProjectTag({
      idtag: null,
      tagnome: '',
      tagcolor: '',
      tagstatus: null,
      tagtipo: 'Tag',
    });
    setSelectedAreas([]);
    setSelectedUsers([]);
    setDate(null);
  };

  const checkNameInputValidation = async (inputValue) => {
    if (inputValue.length > 0) {
      if (inputValue.trim() === project.projetonome.trim()) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
        setEmptyNameError(false);
        return;
      }

      const hasProjectWithNameEqual = await getAllNamesProject(inputValue);

      if (hasProjectWithNameEqual === NameProjectsEnum.similar) {
        setSimilarNameWarning(true);
      }
      if (hasProjectWithNameEqual === NameProjectsEnum.equal) {
        setEqualNameError(true);
      }
      if (hasProjectWithNameEqual === NameProjectsEnum.notExist) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
      }
      setEmptyNameError(false);
    }

    if (inputValue.length === 0) {
      setEqualNameError(false);
      setSimilarNameWarning(false);
      setEmptyNameError(true);
    }
  };

  const selectNameErrorMessage = () => {
    if (equalNameError) return t('projects.new-project-form.errors.already-has-name');
    if (similarNameWarning) return t('projects.new-project-form.errors.similar-project-name');
    if (emptyNameError) return t('projects.new-project-form.errors.project-must-have-name');
    if (selectedProject?.projetonome?.length > textfieldMaxLength)
      return t('projects.edit-projects.project-name-maxLength', { textfieldMaxLength });
    return null;
  };

  const getAllNamesProject = async (name) => {
    const response = await api.get(`/projectsname/${name}`);
    return response.data.data.exists;
  };

  useEffect(() => {
    if (selectedProject.projetonome !== undefined && selectedProject.projetonome !== null) {
      checkNameInputValidation(selectedProject.projetonome);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject.projetonome]);

  return (
    <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
      <GridStyle sx={{ position: 'relative', height: 'max-content' }}>
        <Typography variant="h3" align="center">
          {t('projects.edit-projects.heading')}
        </Typography>
        <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
          <IconButton size="large" onClick={() => closeModalHandler()}>
            <Icon icon={closeIcon} />
          </IconButton>
        </Box>
        <FormControlStyle sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 1 }}>
          <TextFieldWithPositionedErrors
            autoFocus
            id="project-name"
            error={equalNameError || emptyNameError}
            helperText={selectNameErrorMessage()}
            label={t('projects.edit-projects.project-name-label')}
            variant="outlined"
            color="primary"
            value={selectedProject.projetonome}
            onChange={(event) => changeProjectProp('projetonome', event.target.value)}
            inputProps={{ maxLength: textfieldMaxLength }}
          />
          <TextField
            id="project-description"
            label={t('projects.edit-projects.project-description-label')}
            variant="outlined"
            color="primary"
            multiline
            value={selectedProject.projetodescricao !== undefined && selectedProject.projetodescricao}
            onChange={(event) => changeProjectProp('projetodescricao', event.target.value)}
          />
          <CalendarInput
            setDate={setDate}
            date={date}
            error={dateError}
            setError={setDateError}
            disabled={selectedProject.projetobase === 1}
          />
          <ConnectAreasAndUsers
            areas={areasCache}
            selectedAreas={selectedAreas}
            setSelectedAreas={setSelectedAreas}
            users={usersCache?.filter((user) => user?.usuariostatus === 1)}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            setAreasUsersData={setAreasUsers}
            style={{ flexDirection: 'column', gap: 2, height: '100%' }}
          />
          <Autocomplete
            onChange={(_, value) => tagInputChageHandler(value)}
            value={projectTag}
            noOptionsText={t('products.modals.add-products.no-tags')}
            id="project-tag"
            options={tags.filter((tag) => tag?.tagstatus === 1)}
            getOptionLabel={(option) => option?.tagnome}
            isOptionEqualToValue={(option, value) => option?.idtag !== value.idtag}
            renderInput={(params) => {
              let selectedTag;
              if (params.inputProps.value !== '') {
                [selectedTag] = tags.filter((tag) => tag.tagnome === params.inputProps.value);
              }
              return (
                <Box sx={{ position: 'relative' }}>
                  <TextField {...params} label="Tag" />
                  {tags.map((tag) => {
                    if (params.inputProps.value === tag.tagnome) {
                      return (
                        <TagBox
                          key={tag.idtag}
                          sx={{
                            backgroundColor: selectedTag?.tagcolor,
                            marginLeft: '12px',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            paddingX: 1,
                            paddingY: 0.5,
                            borderRadius: '16px',
                            display: 'flex',
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: 'center',
                              color: '#fff',
                              textShadow: '1px 1px 1px #000',
                            }}
                          >
                            {selectedTag.tagnome}
                          </Typography>
                        </TagBox>
                      );
                    }
                    return null;
                  })}
                </Box>
              );
            }}
            renderOption={(props, option) => (
              <li key={option.idtag} {...props}>
                <Box style={{ marginRight: 8 }}>
                  <TagBox
                    sx={{
                      backgroundColor: option.tagcolor,
                      marginLeft: '15px',
                      alignItems: 'center',
                      paddingX: 1,
                      paddingY: 0.5,
                      borderRadius: '16px',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#fff',
                        textShadow: '1px 1px 1px #000',
                      }}
                    >
                      {option.tagnome}
                    </Typography>
                  </TagBox>
                </Box>
              </li>
            )}
          />
          <StyledButton contained style={{ width: '100%' }} onClick={editProjectHandler}>
            {t('projects.edit-projects.confirm-button')}
          </StyledButton>
        </FormControlStyle>
      </GridStyle>
    </ModalStyle>
  );
};

export default EditProjectsModal;
