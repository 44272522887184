import React, { useContext, useEffect, useState } from 'react';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Icon } from '@iconify/react';
import clock from '@iconify/icons-eva/clock-outline';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import { DialogContentText, TextField } from '@mui/material';
import moment from 'moment/moment';
import { toast } from 'react-hot-toast';
import { NodeSelect } from '../BasicModal';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

export default function ModalTempo() {
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState('');

  const nodeSelect = useContext(NodeSelect);
  const { nodeSelectCheck, idprojeto } = nodeSelect;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (nodeSelectCheck.properties !== null && nodeSelectCheck.properties !== undefined) {
      if (nodeSelectCheck.properties.TEMPO !== undefined) {
        const timeProps = nodeSelectCheck.properties.TEMPO;
        setTime(timeProps.time);
      }
    }
  }, [nodeSelectCheck.properties]);

  const handleSendSaveForm = () => {
    const now = moment(new Date(), 'YYYY-MM-DD');
    const dateTime = moment(time, 'YYYY-MM-DD');

    if (dateTime !== null) {
      if (dateTime <= now) {
        toast.error(`${i18next.t('dateValid.prevDate')}`);
        return;
      }
      if (!dateTime._isValid) {
        toast.error(`${i18next.t('dateValid.notValidDate')}`);
        return;
      }
      nodeSelectCheck.properties = { TEMPO: { time } };
      integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
      setOpen(false);
    }
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-time.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={clock} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-time.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{i18next.t('flow-Modals-time.text')}</DialogContentText>
            <TextField
              key={`textfield-date`}
              id={'date-id'}
              label=""
              margin="dense"
              required
              type="date"
              value={time}
              onChange={(event) => setTime(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button variant="contained" onClick={handleSendSaveForm} color="success">
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
