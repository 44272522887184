import React, { useState, useContext, useEffect } from 'react';
import i18next from 'i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import clockFill from '@iconify/icons-eva/clock-fill';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
import { NodeSelect } from '../BasicModal';
import api from '../../../../../service/api';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

export default function ModalTempoLimite() {
  const [open, setOpen] = useState(false);
  const [deadLine, setDeadLine] = useState();
  const [deadLineBack, setDeadLineBack] = useState();
  const nodeSelect = useContext(NodeSelect);
  const { nodeSelectCheck } = nodeSelect;
  const { idprojeto } = nodeSelect;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTime = (event) => {
    const date = event.target.value;

    if (date > 0 && date !== 'e') {
      setDeadLine(date);
    } else if (date <= 0 || date === 'e') {
      toast.error(`${i18next.t('dateValid.prevDate')}`);
    }
  };

  const saveTime = () => {
    if (deadLine > 0) {
      nodeSelectCheck.tempolimite = deadLine;
      integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
      handleClose();
    }
    if (deadLine < 0 || deadLine === undefined) {
      toast.error(`${i18next.t('dateValid.notValidDate')}`);
    }
  };

  const timeLeft = () => {
    if (deadLineBack !== undefined && deadLineBack > 0) {
      return `${i18next.t('dateValid.deadLine', { deadLineBack: `${deadLineBack}` })}`;
    }
    if (deadLineBack !== undefined && deadLineBack < 0) {
      return `${i18next.t('dateValid.deadLineOverdue', {
        deadLineBack: `${-deadLineBack}`,
        interpolation: { escapeValue: false },
      })}`;
    }
    return `${i18next.t('dateValid.notdeadLine')}`;
  };

  async function getDate() {
    try {
      const response = await api.get(`/modules/module/${nodeSelectCheck.id}/${idprojeto}`, {});
      const { module } = response.data.data;
      if (response.status === 200) {
        setDeadLineBack(module.modulotempolimite);
      }
    } catch (error) {
      console.log(error);
      if (error.respose.status === 400) {
        toast.error(`${i18next.t('dateValid.toastErrotime')}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${i18next.t('dateValid.toastErrotime')}`);
      }
    }
  }

  useEffect(() => {
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-limitTime.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={clockFill} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-limitTime.title')}</DialogTitle>
          <DialogContent>
            <Typography variant="h4" sx={{ marginBottom: 1 }}>
              {timeLeft()}
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="tempo"
              label={i18next.t('flow-Modals-limitTime.labelDate')}
              fullWidth
              type="number"
              onChange={(event) => handleTime(event)}
              helperText={i18next.t('flow-Modals-limitTime.labelDateHelpText')}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                saveTime();
              }}
              color="success"
            >
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
