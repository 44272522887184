// routes
import { Toaster } from 'react-hot-toast';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { CurrentModuleProvider } from './store/CurrentModuleContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <DndProvider backend={HTML5Backend}>
        <CurrentModuleProvider>
          <ScrollToTop />
          <Toaster
            containerStyle={{
              position: 'absolute',
              left: '80%',
            }}
          />
          <BaseOptionChartStyle />
          <Router />
        </CurrentModuleProvider>
      </DndProvider>
    </ThemeProvider>
  );
}
