import emptyFile from './file-outline.svg';
import fileAdd from './file-add-fill.svg';
// import fileFill from './file-fill.svg';
import home from './home-outline.svg';
import next from './next.svg';
import time from './clock-outline.svg';
import anexo from './attach-2-outline.svg';
import alert from './alert-circle-outline.svg';
// import form from './file-text-outline.svg';
import confirmar from './checkmark-circle-outline.svg';
import checkList from './check-square.svg';
import repositorio from './cloud-download-outline.svg';
import tags from './pricetags-outline.svg';
import substituir from './flip-outline.svg';
import cancel from './close.svg';
import email from './email-outline.svg';
import merge from './merge.svg';
import form from './clipboard.svg';
import fileFill from './checklist.svg';

export const imagens = {
  emptyFile,
  fileAdd,
  fileFill,
  home,
  next,
  time,
  anexo,
  alert,
  form,
  confirmar,
  checkList,
  repositorio,
  tags,
  substituir,
  cancel,
  email,
  merge,
};
