import * as React from 'react';
import i18next from 'i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';

import integrationsAPI from '../../../utils/functionsIntegrationAPI';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ModalCopyModule({ args }) {
  const [open, setOpen] = React.useState(false);
  const [arroption, setArrOption] = React.useState([]);
  const [readOnly, setReadOnly] = React.useState(true);
  const [moduleToCopy, setModuleToCpy] = React.useState('');
  const { setIsOpen, nodes, nodeSelectCheck, idprojeto, loadFlow } = args;

  const loadSelect = () => {
    const optionsSelect = nodes
      .map((nds) => {
        if (nds.typeNode === nodeSelectCheck.typeNode && nds.id !== nodeSelectCheck.id) return nds;
        return undefined;
      })
      .filter((elem) => elem !== undefined);
    if (optionsSelect.length > 0) setReadOnly(false);
    setArrOption(optionsSelect);
  };
  React.useEffect(() => {
    loadSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOpen = () => setOpen(true);

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setModuleToCpy(value);
  };

  const copy = async () => {
    await integrationsAPI.copyModule(idprojeto, nodeSelectCheck.id, moduleToCopy);
    loadFlow();
    setOpen(false);
    setIsOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  return (
    <div key={`div-${nodeSelectCheck.id}`}>
      <Button key={`button-${nodeSelectCheck.id}`} onClick={handleOpen} sx={{ color: 'text.secondary' }}>
        {i18next.t('flow-Modals-copy.tooltip')}
      </Button>
      <Modal
        key={`modal-${nodeSelectCheck.id}`}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {i18next.t('flow-Modals-copy.title')}
            </Typography>
            {readOnly && (
              <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ marginTop: 1 }}>
                {i18next.t('flow-Modals-copy.notModules')}
              </Typography>
            )}
            <Divider />

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">{i18next.t('flow-Modals-copy.text')}</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id={`field-select`}
                name={`field-select`}
                defaultValue=""
                onChange={handleChangeSelect}
                input={<OutlinedInput label={i18next.t('flow-Modals-copy.text')} />}
                MenuProps={MenuProps}
                disabled={readOnly}
              >
                {arroption.map((opt) => (
                  <MenuItem key={`module-${opt.id}`} value={opt.id}>
                    {opt.modulotxt}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions sx={{ justifyContent: 'end' }}>
              <Button onClick={copy} variant="contained">
                {i18next.t('flow-Modals-copy.copyProps')}{' '}
              </Button>
            </DialogActions>
          </Box>
        </>
      </Modal>
    </div>
  );
}
