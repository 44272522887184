import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import { Stack, Link, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import { FormProvider } from '../../../components/hook-form';
import RHFTwoFactor from '../../../components/hook-form/RHFTwoFactor';
// api
import api from '../../../service/api';
//
import { refreshToken } from '../../../utils/refreshToken';

// ----------------------------------------------------------------------

export default function TwoFactorForm() {
  const smUp = useResponsive('up', 'sm');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email, password } = state;
  const { t } = useTranslation();

  async function requestTwoFactor(data) {
    try {
      // eslint-disable-next-line dot-notation
      api.defaults.headers.common['Authorization'] = `${localStorage.getItem('tokenTemporary')}`;
      const req = {
        code: String(data.code),
      };
      const response = await api.post('/login/2factor', req);
      if (response.status === 200) {
        await localStorage.setItem('tokenTemporary', '');
        localStorage.setItem('token', `Bearer ${response.data.data.token}`);
        refreshToken();
        navigate('/dashboard/app', { replace: true });
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  const resendCode = async () => {
    try {
      const req = {
        email,
        password,
      };
      const responseLogin = await api.post('/login', req);
      localStorage.setItem('tokenTemporary', `Bearer ${responseLogin.data.data.token}`);
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${t('2factorErro')}`);
      }
    }
  };

  const codeRequired = t('validation.twofactor.code-required');

  const TwoFactorSchema = Yup.object().shape({
    code: Yup.number(codeRequired).required(codeRequired).positive().integer().nullable(codeRequired),
  });

  const defaultValues = {
    code: '',
  };

  const methods = useForm({
    resolver: yupResolver(TwoFactorSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestTwoFactor(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTwoFactor name="code" label={t('addCode')} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('twofactor.button-twofactor')}
        </LoadingButton>

        {smUp && (
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            {t('twofactor.menssage')}{' '}
            <Link component="button" variant="subtitle2" onClick={resendCode}>
              {t('twofactor.link')}
            </Link>
          </Typography>
        )}
      </Stack>
    </FormProvider>
  );
}
