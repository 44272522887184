import { useEffect, useState, useRef } from 'react';
import { Stack } from '@mui/system';
import { Box, Tab, Tabs, Typography, Button, useMediaQuery, CircularProgress, Switch } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import MyJobsItems from '../components/MyJobsItems';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import Page from '../components/Page';
import { StyledContainer } from '../components/styled-global';
import Scrollbar from '../components/Scrollbar';
import api from '../service/api';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`my-jobs-tabpanel-${index}`} {...other}>
      {value === index && (
        <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
          {children}
        </Scrollbar>
      )}
    </div>
  );
};

export default function MyJobsMore() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [maxJobsInBox, setMaxJobsInBox] = useState(12);
  const [maxJobsInBoxArea, setMaxJobsInBoxArea] = useState(12);
  const [maxJobsInBoxFinal, setMaxJobsInBoxFinal] = useState(12);
  const [maxJobsInBoxAreaFinal, setMaxJobsInBoxAreaFinal] = useState(12);
  const [checked, setChecked] = useState(false);
  const currentJobs = useRef(0);
  const currentJobsArea = useRef(0);
  const currentJobsFinal = useRef(0);
  const currentJobsFinalArea = useRef(0);

  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  const down915pxHeight = useMediaQuery('(max-height: 910px)');
  const down820pxHeight = useMediaQuery('(max-height: 820px)');
  const down790pxHeight = useMediaQuery('(max-height: 730px)');
  const down1540pxWidth = useMediaQuery('(max-width: 1530px)');

  useEffect(() => {
    if (down915pxHeight) {
      setMaxJobsInBox(10);
      setMaxJobsInBoxFinal(10);
      setMaxJobsInBoxArea(10);
      setMaxJobsInBoxAreaFinal(10);
    }
    if (down820pxHeight) {
      setMaxJobsInBox(8);
      setMaxJobsInBoxFinal(8);
      setMaxJobsInBoxArea(8);
      setMaxJobsInBoxAreaFinal(8);
    }
    if (down790pxHeight) {
      setMaxJobsInBox(6);
      setMaxJobsInBoxFinal(6);
      setMaxJobsInBoxArea(6);
      setMaxJobsInBoxAreaFinal(6);
    }
    if (!down915pxHeight && !down820pxHeight) {
      setMaxJobsInBox(12);
      setMaxJobsInBoxFinal(12);
      setMaxJobsInBoxArea(12);
      setMaxJobsInBoxAreaFinal(12);
    }
  }, [down915pxHeight, down820pxHeight, down790pxHeight]);

  const { t } = useTranslation();

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    // setChecked(false);
  };

  function a11yProps(index) {
    return {
      id: `my-jobs-tab-${index}`,
      'aria-controls': `my-jobs-tabpanel-${index}`,
    };
  }

  async function requestMyJobsFinal(limit, skip) {
    try {
      const response = await api.get(`/projectsmyjobsfinalpaginado/${limit - skip}/${skip}`);
      if (skip > 0) {
        return [...finalJobs, ...response.data.data.myjobs];
      }
      if (skip === 0) {
        return response.data.data.myjobs;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('my-jobs.error-finished'));
      }
    }
    return null;
  }
  async function requestMyJobsFinalArea(limit, skip) {
    try {
      const response = await api.get(`/projectsmyjobsfinalpaginadogerente/${limit - skip}/${skip}`);
      if (skip > 0) {
        return [...finalJobsArea, ...response.data.data.myjobs];
      }
      if (skip === 0) {
        return response.data.data.myjobs;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('my-jobs.error-finished'));
      }
    }
    return null;
  }

  async function requestMyJobs(limit, skip) {
    try {
      const response = await api.get(`/projectsmyjobspaginado/${limit - skip}/${skip}`);
      if (skip > 0) {
        return [...jobs, ...response.data.data.myjobs];
      }
      if (skip === 0) {
        return response.data.data.myjobs;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('my-jobs.error'));
      }
    }
    return null;
  }
  async function requestMyJobsArea(limit, skip) {
    try {
      const response = await api.get(`/projectsmyjobspaginadogerente/${limit - skip}/${skip}`);
      if (skip > 0) {
        return [...jobsArea, ...response.data.data.myjobs];
      }
      if (skip === 0) {
        return response.data.data.myjobs;
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(t('my-jobs.error'));
      }
    }
    return null;
  }

  const { data: jobs, isLoading: LoadingJobs } = useQuery(
    ['myjobs', maxJobsInBox],
    () => requestMyJobs(maxJobsInBox, currentJobs.current),
    {
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 60 * 5,
      keepPreviousData: true,
    }
  );
  const { data: jobsArea } = useQuery(
    ['myjobsArea', maxJobsInBoxArea],
    () => requestMyJobsArea(maxJobsInBoxArea, currentJobsArea.current),
    {
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 60 * 5,
      keepPreviousData: true,
      enable: checked === true,
    }
  );

  const { data: finalJobs, isLoading: LoadingFinalJobs } = useQuery(
    ['myfinalJobs', maxJobsInBoxFinal],
    () => requestMyJobsFinal(maxJobsInBoxFinal, currentJobsFinal.current),
    {
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 60 * 5,
      keepPreviousData: true,
    }
  );
  const { data: finalJobsArea } = useQuery(
    ['myfinalJobsArea', maxJobsInBoxAreaFinal],
    () => requestMyJobsFinalArea(maxJobsInBoxAreaFinal, currentJobsFinalArea.current),
    {
      staleTime: 1000 * 60,
      refetchInterval: 1000 * 60 * 5,
      keepPreviousData: true,
    }
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const noOpenJobs = jobs?.length === 0;
  const noFinalJobs = finalJobs?.length === 0;

  return (
    <Page title={t('sidebar.my-jobs')} sx={{ height: '100vh', width: '99vw' }}>
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} navigateBack={'/'} />
      <StyledContainer>
        <Stack direction="row" alignItems="center" justifyContent="center" mb={0} sx={{ width: '100%' }}>
          <Typography variant="h4" component="span" gutterBottom textAlign="center">
            {checked ? t('my-jobs.jobs-area') : t('my-jobs.heading')}
          </Typography>
        </Stack>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {accountCache?.roles[0].perfilnome === 'Gerente' && (
              <Stack justifyContent="flex-end" direction="row" spacing={1} alignItems="center">
                <Typography component={'span'}>{t('my-jobs.heading')}</Typography>
                <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                <Typography component={'span'}>{t('my-jobs.jobs-area')}</Typography>
              </Stack>
            )}
            <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={t('my-jobs.more.tab-open')} {...a11yProps(0)} />
              <Tab label={t('my-jobs.more.tab-finished')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} id="Trabalhos Abertos">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: 2,
                padding: 0.8,
              }}
            >
              {LoadingJobs && (
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <CircularProgress />
                </Box>
              )}
              {noOpenJobs && !LoadingJobs && (
                <Typography variant="h4" align="center" textAlign="center" width="100%" color="primary">
                  {t('my-jobs.more.no-jobs')}
                </Typography>
              )}
              {!noOpenJobs && !LoadingJobs && checked && (
                <>
                  {jobsArea?.map((job, index) => {
                    if (index > maxJobsInBoxArea - 1) return null;
                    return (
                      <MyJobsItems
                        sx={{ width: !down1540pxWidth ? '49.4%' : '49%' }}
                        key={job.idmodulo + index + job.modulotxt}
                        job={job}
                        checked={checked}
                      />
                    );
                  })}
                  {jobsArea?.length >= maxJobsInBoxArea && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          setMaxJobsInBoxArea((prevState) => {
                            currentJobsArea.current = prevState;
                            return prevState + 6;
                          })
                        }
                        align="center"
                      >
                        {t('my-jobs.more.more')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {!noOpenJobs && !LoadingJobs && !checked && (
                <>
                  {jobs?.map((job, index) => {
                    if (index > maxJobsInBox - 1) return null;
                    return (
                      <MyJobsItems
                        sx={{ width: !down1540pxWidth ? '49.4%' : '49%' }}
                        key={job.idmodulo + index + job.modulotxt}
                        job={job}
                        checked={checked}
                      />
                    );
                  })}
                  {jobs?.length >= maxJobsInBox && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          setMaxJobsInBox((prevState) => {
                            currentJobs.current = prevState;
                            return prevState + 6;
                          })
                        }
                        align="center"
                      >
                        {t('my-jobs.more.more')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} id="Trabalhos Finalizados">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                gap: 2,
                padding: 0.8,
              }}
            >
              {LoadingFinalJobs && (
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <CircularProgress />
                </Box>
              )}
              {noFinalJobs && !LoadingFinalJobs && (
                <Typography variant="h4" align="center" textAlign="center" width="100%" color="primary">
                  {t('my-jobs.more.no-finished-jobs')}
                </Typography>
              )}
              {!noFinalJobs && !LoadingFinalJobs && !checked && (
                <>
                  {finalJobs?.map((job, index) => {
                    if (index > maxJobsInBoxFinal - 1) return null;
                    return (
                      <MyJobsItems
                        sx={{ width: !down1540pxWidth ? '49.4%' : '49%' }}
                        key={`finalJobs_${job.idmodulo}_${index}_${job.modulotxt}`}
                        job={job}
                        final
                        checked={checked}
                      />
                    );
                  })}
                  {finalJobs?.length >= maxJobsInBoxFinal && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          setMaxJobsInBoxFinal((prevState) => {
                            currentJobsFinal.current = prevState;
                            return prevState + 6;
                          })
                        }
                        align="center"
                      >
                        {t('my-jobs.more.more')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {!noFinalJobs && !LoadingFinalJobs && checked && (
                <>
                  {finalJobsArea?.map((job, index) => {
                    if (index > maxJobsInBoxAreaFinal - 1) return null;
                    return (
                      <MyJobsItems
                        sx={{ width: !down1540pxWidth ? '49.4%' : '49%' }}
                        key={`finalJobsArea_${job.idmodulo + index}_${job.modulotxt}`}
                        job={job}
                        final
                        checked={checked}
                      />
                    );
                  })}
                  {finalJobsArea?.length >= maxJobsInBoxAreaFinal && (
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          setMaxJobsInBoxAreaFinal((prevState) => {
                            currentJobsFinalArea.current = prevState;
                            return prevState + 6;
                          })
                        }
                        align="center"
                      >
                        {t('my-jobs.more.more')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </TabPanel>
        </Box>
      </StyledContainer>
    </Page>
  );
}
