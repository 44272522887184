import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import i18next from 'i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Icon } from '@iconify/react';
import quickReference from '@iconify/icons-material-symbols/quick-reference';
import { toast } from 'react-hot-toast';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
// import toast from 'react-hot-toast';
// import managementNode, { moduleProperties } from '../../../utils/functionsManagementNode';
import { NodeSelect } from '../BasicModal';
import api from '../../../../../service/api';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

// npm install --save-dev @iconify/react @iconify/icons-material-symbols

export default function ModalConfigReferencia({ module }) {
  const [open, setOpen] = useState(false);
  // const [prevNodes, setPrevNodes] = useState([]);
  const [arrPropertiesForm, setArrPropertiesForm] = useState([]);
  const [infosRefs, setinfosRefs] = useState([]);
  const nodeSelect = useContext(NodeSelect);
  const { idprojeto, nodeSelectCheck } = nodeSelect;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const loadForm = async () => {
    const responseProject = await api.get(`modules/form/${module.modulonodeid}/${idprojeto}`);
    const { form } = responseProject.data.data;
    if (form === null) {
      //
    } else {
      const arrForm = JSON.parse(form.form);
      const newArrForm = arrForm.filter((field) => field.able === true);
      newArrForm.sort((a, b) => a.position - b.position);
      setArrPropertiesForm(newArrForm);

      nodeSelectCheck.properties.FIELDS.forEach((field) => {
        if (field.nodeid === module.modulonodeid) {
          setinfosRefs(field.infosRefs);
        }
      });
    }
  };

  useEffect(() => {
    loadForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const selectAllsField = () => {
    setinfosRefs(arrPropertiesForm);

    if (infosRefs.length > 0) {
      setinfosRefs([]);
    }
  };

  const selectFieldsToSend = (fileSelect) => {
    const infocheck = infosRefs.find((item) => item.id === fileSelect.id);
    if (!infocheck) {
      setinfosRefs((info) => info.concat(fileSelect));
      // infosRefs.push(fileSelect);
    }
    if (infocheck) {
      const newarrInfo = infosRefs.filter((item) => item.id !== fileSelect.id);
      setinfosRefs([]);
      setinfosRefs((info) => info.concat(newarrInfo));
    }
  };

  const refFields = async () => {
    const nodeFieldsRef = [
      {
        nodeid: module.modulonodeid,
        infosRefs,
      },
    ];

    try {
      const checkNodeFieldsRef = nodeFieldsRef[0];

    if (nodeSelectCheck.properties !== null && nodeSelectCheck.properties !== undefined) {
      if (nodeSelectCheck.properties.FIELDS !== undefined && nodeSelectCheck.properties.FIELDS !== null) {
        const moduleRepeat = nodeSelectCheck.properties.FIELDS.some(
          (moduleField) => Number(moduleField.nodeid) === Number(checkNodeFieldsRef.nodeid)
        );

        if (moduleRepeat) {
          const filterFields = nodeSelectCheck.properties.FIELDS.filter(
            (moduleField) => moduleField.nodeid !== checkNodeFieldsRef.nodeid
          );

          nodeSelectCheck.properties = {
            ...nodeSelectCheck.properties,
            FIELDS: [...filterFields, ...nodeFieldsRef],
          };
        } else if (!moduleRepeat) {
          nodeSelectCheck.properties = {
            ...nodeSelectCheck.properties,
            FIELDS: [...(nodeSelectCheck.properties.FIELDS || []), ...nodeFieldsRef],
          };
        }
      }
    } else {
      nodeSelectCheck.properties = {
        ...nodeSelectCheck.properties,
        FIELDS: [...nodeFieldsRef],
      };
    }

    /* if (nodeSelectCheck.properties.FIELDS === undefined || nodeSelectCheck.properties.FIELDS === null) {
      nodeSelectCheck.properties = {
        ...nodeSelectCheck.properties,
        FIELDS: [...nodeFieldsRef],
      };
    } */

    await integrationsAPI.updateModule(idprojeto, nodeSelectCheck);

    toast.success(`${i18next.t('configRef.fieldsSelectedSucceed')}`);
    setOpen(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }

    }

    
  };

  const finfInfo = (fileSelect) => infosRefs.find((item) => item.id === fileSelect.id);

  const loadValueAnswer = (field) => {
    if (field.answer !== null && field.answer !== undefined && field.answer !== '') {
      return field.answer;
    }
    return '';
  };

  const loadValueOption = (prop) => {
    if (prop.answer === null || prop.answer === undefined) return '';
    return prop.answer;
  };

  const textbox = (field) => (
    <Card
      key={`card-${field.id}`}
      sx={{ minWidth: 275, margin: '1% auto', border: finfInfo(field) ? '3px solid #FECD0E' : 'none' }}
      onClick={() => selectFieldsToSend(field)}
    >
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label="Resposta"
          fullWidth
          margin="dense"
          required={field.required}
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );
  const textboxmultiline = (field) => (
    <Card
      key={`card-${field.id}`}
      sx={{ minWidth: 275, margin: '1% auto', border: finfInfo(field) ? '3px solid #FECD0E' : 'none' }}
      onClick={() => selectFieldsToSend(field)}
    >
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label="Resposta"
          fullWidth
          margin="dense"
          required={field.required}
          multiline
          rows={4}
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );
  const multiselect = (field) => (
    <Card
      key={`card-${field.id}`}
      sx={{ minWidth: 275, margin: '1% auto', border: finfInfo(field) ? '3px solid #FECD0E' : 'none' }}
      onClick={() => selectFieldsToSend(field)}
    >
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position}. ${field.question}`}
        </Typography>
        <FormControl required={field.required} sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">{i18next.t('configRef.options')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id={field.id}
            name={field.id}
            // multiple
            value={loadValueOption(field)}
            input={<OutlinedInput label={i18next.t('configRef.options')} />}
            MenuProps={MenuProps}
            disabled
          >
            {field.options[0].map((option) => (
              <MenuItem key={`position-${option}`} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
  const combobox = (field) => (
    <Card
      key={`card-${field.id}`}
      sx={{ minWidth: 275, margin: '1% auto', border: finfInfo(field) ? '3px solid #FECD0E' : 'none' }}
      onClick={() => selectFieldsToSend(field)}
    >
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position}. ${field.question}`}
        </Typography>
        <FormControl required={field.required} sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">{i18next.t('configRef.options')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id={field.id}
            name={field.id}
            value={loadValueOption(field)}
            input={<OutlinedInput label={i18next.t('configRef.options')} />}
            MenuProps={MenuProps}
            disabled
          >
            {field.options[0].map((option) => (
              <MenuItem key={`position-${option}`} value={option}>
                {`${option}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
  const email = (field) => (
    <Card
      key={`card-${field.id}`}
      sx={{ minWidth: 275, margin: '1% auto', border: finfInfo(field) ? '3px solid #FECD0E' : 'none' }}
      onClick={() => selectFieldsToSend(field)}
    >
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label="Resposta"
          fullWidth
          margin="dense"
          required={field.required}
          type="email"
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );

  const date = (field) => (
    <Card
      key={`card-${field.id}`}
      sx={{ minWidth: 275, margin: '1% auto', border: finfInfo(field) ? '3px solid #FECD0E' : 'none' }}
      onClick={() => selectFieldsToSend(field)}
    >
      <CardContent key={`cardcontent-${field.id}`}>
        <Typography key={`typography-${field.id}`} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${field.position}. ${field.question}`}
        </Typography>
        <TextField
          key={`textfield-${field.id}`}
          id={field.id}
          label=""
          margin="dense"
          required={field.required}
          type="date"
          value={loadValueAnswer(field)}
          disabled
        />
      </CardContent>
    </Card>
  );

  const filterField = (field) => {
    if (field.able) {
      if (field.type === 'textbox') return textbox(field);
      if (field.type === 'textboxmultiline') return textboxmultiline(field);
      if (field.type === 'multiselect') return multiselect(field);
      if (field.type === 'combobox') return combobox(field);
      if (field.type === 'email') return email(field);
      if (field.type === 'date') return date(field);
    }
    return null;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setinfosRefs([]);
    setOpen(false);
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('configRef.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={quickReference} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog open={open} maxWidth="1200px" fullWidth sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}>
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ backgroundColor: '#DCDCD0' }}>{i18next.t('configRef.title')}</DialogTitle>
          <DialogContent sx={{ backgroundColor: '#DCDCD0' }}>
            {arrPropertiesForm.map((field) => filterField(field))}
            {arrPropertiesForm.length <= 0 && (
              <Typography variant="h4">{i18next.t('configRef.notFormConfig')}</Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#DCDCD0' }}>
            <Button variant="contained" color="info" onClick={selectAllsField} sx={{ marginRight: '55%' }}>
              {infosRefs.length > 0
                ? `${i18next.t('selectedFieldRef.deselectAll')}`
                : `${i18next.t('selectedFieldRef.selectedAll')}`}
            </Button>
            <Button variant="contained" onClick={handleClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                refFields();
                handleClose();
              }}
              color="success"
            >
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
