import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Icon } from '@iconify/react';
import moreIcon from '@iconify/icons-eva/more-vertical-fill';
import powerOffIcon from '@iconify/icons-bx/power-off';
import editIcon from '@iconify/icons-eva/edit-fill';
// store
import { usePermission } from '../../../../store/usePermission';

// ----------------------------------------------------------------------

export default function ProductsTableMoreMenu({ productStatus, changeProductStatusFunction, openEditModal }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);

  const { t } = useTranslation();

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const productMenuAdm = userPermissions.includes('productAdministrating');
      setPermissionAdmin(productMenuAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconButton sx={{ color: 'text.secondary' }} ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreIcon} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={changeProductStatusFunction}>
          <ListItemIcon>
            <Icon icon={powerOffIcon} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            // eslint-disable-next-line react/prop-types
            primary={productStatus === 0 ? t('areas.areas-more.activate') : t('areas.areas-more.deactivate')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {permissionAdmin === true ? (
          <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={openEditModal}>
            <ListItemIcon>
              <Icon icon={editIcon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={t('areas.areas-more.edit')} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        ) : (
          ''
        )}
      </Menu>
    </>
  );
}
