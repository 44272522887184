import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
// @mui
import { Link, Stack, IconButton, InputAdornment, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify/icons-eva/eye-fill';
import eyeOffIcon from '@iconify/icons-eva/eye-off-fill';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// api
import api from '../../../service/api';
//
import { refreshToken } from '../../../utils/refreshToken';
// ----------------------------------------------------------------------

export default function LoginActiveDirectoryForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  async function requestApiLoginAD(data) {
    try {
      const req = {
        userad: data.userad,
        password: data.password,
      };
      const responseLoginAD = await api.post('/loginad', req);
      if (responseLoginAD.data.data.token === '' && responseLoginAD.data.data.firstLogin === 1) {
        navigate(`/registerad`, { state: { userad: data.userad } });
      } else {
        // eslint-disable-next-line dot-notation
        api.defaults.headers.common['Authorization'] = `Bearer ${responseLoginAD.data.data.token}`;
        const responseUser = await api.get(`/users/${responseLoginAD.data.data.email}`);
        const { user } = responseUser.data.data;
        const userStr = JSON.stringify({
          // iduser: user.idusuario,
          // username: user.usuarionome,
          useremail: user.usuarioemail,
        });
        localStorage.setItem('tokenTemporary', '');
        localStorage.setItem('User', userStr);
        localStorage.setItem('token', `Bearer ${responseLoginAD.data.data.token}`);
        refreshToken();
        navigate('/dashboard/app', { replace: true });
      }
    } catch (error) {
      toast.error(`${error.response.data.data.errors[0]}`);
    }
  }

  const LoginSchema = Yup.object().shape({
    userad: Yup.string(),
    password: Yup.string(),
  });

  const defaultValues = {
    userad: '',
    password: '',
    remember: false,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestApiLoginAD(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="userad" label={t('login.ad-username')} />

        <RHFTextField
          name="password"
          label={t('login.pleacehold-senha')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Icon icon={showPassword ? eyeIcon : eyeOffIcon} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label={t('login.lembre')} />
        <Link variant="subtitle2" underline="hover" component={RouterLink} to="/forgotpassword">
          {t('login.forgotpassword')}
        </Link>
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('login.button-login')}
        </LoadingButton>
        <Button fullWidth size="large" variant="outlined" component={RouterLink} to="/login">
          {t('login.ad-return')}
        </Button>
      </Box>
    </FormProvider>
  );
}
