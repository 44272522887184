import * as React from 'react';
import Box from '@mui/material/Box';
import i18next from 'i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BasicSelect from './SelectTypeNode';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function BasicModalType({ tipo, categoria, changeType, typeBack, args }) {
  const [open, setOpen] = React.useState(false);
  const { setIsOpen } = args;
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };
  return (
    <div>
      <Button onClick={handleOpen} sx={{ color: '#757575' }}>
        {i18next.t('flow-options.changetype')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {i18next.t('flow-options.changetype')}
            </Typography>
            <Divider />
            <Typography variant="subtitle1" component="p" sx={{ margin: '1rem 0' }}>
              {i18next.t('infos-flow.category-node')} <strong>{categoria}</strong>
            </Typography>
            <Typography variant="subtitle1" component="p" sx={{ margin: '1rem 0' }}>
              {i18next.t('infos-flow.module-currenttype')} <strong>{typeBack}</strong>
            </Typography>
            <Divider />
            <BasicSelect type={tipo} changeType={changeType} args={args} />
            <DialogActions sx={{ justifyContent: 'end' }}>
              <Button onClick={handleClose} variant="contained">
                {i18next.t('flow-Modals.exit')}
              </Button>
            </DialogActions>
          </Box>
        </>
      </Modal>
    </div>
  );
}
