import React, { useState, useEffect, useContext } from 'react';
import i18next from 'i18next';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import eyeCheckOutline from '@iconify/icons-mdi/eye-check-outline';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
import { NodeSelect } from '../BasicModal';
import api from '../../../../../service/api';
import ConnectAreasAndUsers from '../../../../ConnectAreasAndUsers';
import integrationsAPI from '../../../utils/functionsIntegrationAPI';

export default function ModalPrivacidade() {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasUsers, setAreasUsers] = useState(null);
  const nodeSelect = useContext(NodeSelect);
  const { nodeSelectCheck } = nodeSelect;
  const { idprojeto, project } = nodeSelect;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const savePrivacy = () => {
    nodeSelectCheck.privacidade = areasUsers;
    integrationsAPI.updateModule(idprojeto, nodeSelectCheck);
  };

  const getAreasAndUsers = async () => {
    setAreas(project.current.areausuario.area);
    setUsers(project.current.areausuario.usuario);
  };

  const getselectAreasAndUsers = async (id, idprojeto) => {
    try {
      const response = await api.get(`/modules/privacidade/${id}/${idprojeto}`);

      setSelectedAreas([...response.data.data.privacidade.area]);
      setSelectedUsers([...response.data.data.privacidade.usuario]);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  useEffect(() => {
    getAreasAndUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getselectAreasAndUsers(nodeSelectCheck.id, idprojeto);
  }, [idprojeto, nodeSelectCheck.id]);

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-privacy.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={eyeCheckOutline} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        onClose={handleClickClose}
        open={open}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
          <IconButton
            onClick={handleClickClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-privacy.tooltip')}</DialogTitle>
          <DialogContent>
            <Typography variant="h4" sx={{ textAlign: 'center', marginTop: 1 }}>
              {i18next.t('flow-Modals-privacy.title')}
            </Typography>
            <ConnectAreasAndUsers
              areas={areas}
              selectedAreas={selectedAreas}
              setSelectedAreas={setSelectedAreas}
              users={users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              setAreasUsersData={setAreasUsers}
              style={{ marginTop: 3 }}
              label="Privacidade"
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'end' }}>
            <Button variant="contained" onClick={handleClickClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button
              onClick={() => {
                savePrivacy();
                handleClickClose();
              }}
              variant="contained"
              color="success"
            >
              {i18next.t('flow-Modals.confirm-button')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
