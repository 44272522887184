import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Tooltip } from '@mui/material';
import infoIcon from '@iconify/icons-mdi/information-variant';

import { useTranslation } from 'react-i18next';
// component
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-eva/search-fill';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 270,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 330, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ProductsListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterProduct: PropTypes.string,
  onFilterProduct: PropTypes.func,
};

export default function ProductsListToolbar({ numSelected, filterProduct, onFilterProduct }) {
  const { t } = useTranslation();
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SearchStyle
            value={filterProduct}
            onChange={onFilterProduct}
            placeholder={t('products.products-list-toolbar.search-label')}
            startAdornment={
              <InputAdornment position="start">
                <Icon icon={searchIcon} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
            endAdornment={
              <Tooltip title={t('products.products-list-toolbar.search-info')} disableInteractive>
                <InputAdornment position="start">
                  <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              </Tooltip>
            }
          />
        </>
      )}
    </RootStyle>
  );
}
