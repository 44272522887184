import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Box, Badge, CardMedia, Typography, MenuItem, Menu } from '@mui/material';
import { baseURLFile } from '../../../../../service/api';

export default function AnnexForModal({
  imagem,
  index,
  checkedState,
  onSingleClickHandler,
  annexSelectedHandle,
  checkAnnexInArrayAndUpdateCountBagde,
  testFile,
  onDoubleClick,
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const downloadFile = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.moduloanexocaminho}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileObj.moduloanexonome;
        alink.click();
      });
    });
  };
  const downloadFilePDF = (baseURLFile, fileObj) => {
    fetch(`${baseURLFile}/${fileObj.moduloanexocaminhopdf}`).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${fileObj.moduloanexonome.split('.')[0]}.pdf`;
        alink.click();
      });
    });
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };
  return (
    <FormControlLabel
      key={`checkbox-formLabel-${index}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '15%',
        height: 300,
        alignItems: 'flex-end',
        marginLeft:0
      }}
      control={
        <Checkbox
          sx={{ display: 'none' }}
          checked={checkedState[index]}
          onChange={() => onSingleClickHandler(index)}
          key={`checkbox-${index}`}
        />
      }
      label={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 2,
          }}
          key={`box-annex-${index}`}
          onClick={() => annexSelectedHandle(imagem)}
          onDoubleClick={() => onDoubleClick(imagem)}
          onContextMenu={handleContextMenu}
        >
          <Badge
            badgeContent={checkAnnexInArrayAndUpdateCountBagde(imagem)}
            color="primary"
            key={`badge-formLabel-${index}`}
          />
          <CardMedia
            key={`checkbox-image-${index}`}
            component="img"
            height="140"
            image={testFile(imagem)}
            alt={imagem.moduloanexonome}
            sx={{
              border: checkedState[index] ? '3px solid #FECD0E' : 'none',
              borderRadius: '4px',
              boxSizing: 'border-box',
              maxWidth: 140,
              margin: '0 auto',
            }}
          />
          <Typography
            key={`checkbox-title-${index}`}
            sx={{
              maxWidth: 140,
              overflowWrap: 'break-word',
              textAlign: 'center',
              marginTop: 1,
            }}
          >
            {imagem.moduloanexonome.replaceAll('_', '-')}
          </Typography>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
          >
            <MenuItem onClick={() => downloadFile(baseURLFile, imagem)}>Download Original</MenuItem>
            {imagem.moduloanexocaminhopdf !==null && (
              <MenuItem onClick={() => downloadFilePDF(baseURLFile, imagem)}>Download Pdf</MenuItem>
            )}
            {/* <MenuItem onClick={handleClose}>Print</MenuItem> */}
          </Menu>
        </Box>
      }
    />
  );
}
