import { useState, createContext } from 'react';
import Box from '@mui/material/Box';
import i18next from 'i18next';
import { toast } from 'react-hot-toast'; 
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import actionsFlow from '../../utils/functionsActionsFlow';

export const NodeSelect = createContext();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 900,
    xl: 1200,
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function ModalConfirmDeleteNode({ args }) {
  const [open, setOpen] = useState(false);
  const { setIsOpen } = args;

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const deleteNode = () => {
    try {
      actionsFlow.deleteNode(args);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={{ color: '#000', width: '100%', height: '100%' }}>
        {i18next.t('flow-options.deleteModule')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h1"
              sx={{ textAlign: 'center', marginBottom: 5 }}
            >
              {i18next.t('deleteNodeModal.title')}
            </Typography>
            <DialogActions sx={{ justifyContent: 'center', gap: '1rem' }}>
              <Button onClick={handleClose} variant="contained" color="primary">
                {i18next.t('flow-Modals.exit')}
              </Button>
              <Button onClick={deleteNode} variant="contained" color="error">
                {i18next.t('deleteNodeModal.delete')}
              </Button>
            </DialogActions>
          </Box>
        </>
      </Modal>
    </div>
  );
}
