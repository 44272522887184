import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
// i18n
import { useTranslation } from 'react-i18next';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// api
import api from '../../../service/api';
// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function requestForgotPassword(data) {
    try {
      const response = await api.get(`/password/${data.email}`);
      if (response.status === 200) {
        navigate('/newpassword', { replace: true, state: { email: data.email } });
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  const emailRequired = t('validation.resetpassword.email-required');
  const emailInvalid = t('validation.resetpassword.email-validate');

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email(emailInvalid).required(emailRequired),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    requestForgotPassword(data);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label={t('forgotpassword.pleacehold-email')} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t('forgotpassword.button-forgotpassword')}
        </LoadingButton>
        <Button fullWidth size="large" variant="text" component={RouterLink} to="/login">
          {t('forgotpassword.button-back')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
