/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';

import { Grid, Modal, Typography, TextField, Box, FormControl, Button, Autocomplete, Checkbox } from '@mui/material';

import { useTranslation } from 'react-i18next';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import toast from 'react-hot-toast';

import { useQueryClient } from 'react-query';

import { useState, useEffect } from 'react';

import IconButton from '@mui/material/IconButton';

import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';
import { NameProjectsEnum } from '../../../../../enum/NameProjectsEnum';
import  {textfieldMaxLengthProductCode,textfieldMaxLengthProduct } from '../../../../../utils/textfieldMaxLength'

import api from '../../../../../service/api';

// Estilos -------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  // height: '50%',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
  position: 'relative',
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

const FormControlStyle = styled(FormControl)(() => ({
  display: 'flex',
  height: '90%',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ButtonStyle = styled(Button)(() => ({
  width: '30%',
  border: '1px solid #FECD0E',
}));

const BoldTypographyStyle = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: 'bold',
}));

const TagBox = styled(Box)(() => ({
  height: '1.3rem',
  width: 50,
  marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 8px #808080',
  minWidth: 'max-content',
}));

const TextFieldWithPositionedErrors = styled(TextField)(() => ({
  // '.css-16d5wub-MuiFormHelperText-root': { position: 'absolute', top: 15, right: 20 },
}));

// ---------------------------------------------------------------------------

export function AddProductsModal({ isModalOpen, setModalOpen, triggerPageRerender, tags, autoInputProduct }) {
  const [productNameValue, setProductNameValue] = useState(null);

  const [productCodeValue, setProductCodeValue] = useState(null);

  const [productTagValue, setProductTagValue] = useState({
    idtag: null,
    tagnome: '',
    tagcolor: '',
    tagstatus: null,
    tagtipo: 'Tag',
  });

  const [errorMessageTag, setErrorMessageTag] = useState('');

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [similarNameWarning, setSimilarNameWarning] = useState(false);

  const [equalNameError, setEqualNameError] = useState(false);

  const [emptyNameError, setEmptyNameError] = useState(false);

  const [similarCodeWarning, setSimilarCodeWarning] = useState(false);

  const [equalCodeError, setEqualCodeError] = useState(false);

  const [emptyCodeError, setEmptyCodeError] = useState(false);

  const [projectTagSKU, setProjectTagSKU] = useState([]);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const transformedTags = tags
    .filter((tag) => tag.tagtipo === 'Tag')
    .map((tag) => ({
      label: tag.tagnome,
      idtag: tag.idtag,
      tagcolor: tag.tagcolor,
      tagstatus: tag.tagstatus,
      tagtipo: tag.tagtipo,
    }));

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const submitFormHandler = () => {
    if (equalNameError || equalCodeError) return;
    if (productNameValue.trim() === '') {
      setEmptyNameError(true);
      return;
    }
    if (productCodeValue.trim() === '') {
      setEmptyCodeError(true);
      return;
    }
    if (productTagValue.idtag === null) {
      setErrorMessageTag(t('products.modals.add-products.errors.no-tag'));
      return;
    }
    setOpenConfirmModal(true);
  };

  const checkNameInputValidation = async (inputValue) => {
    if (inputValue.length > 0) {
      const hasProductWithNameEqual = await getAllNamesProduct(inputValue);

      if (hasProductWithNameEqual === NameProjectsEnum.similar) {
        setSimilarNameWarning(true);
      }
      if (hasProductWithNameEqual === NameProjectsEnum.equal) {
        setEqualNameError(true);
      }
      if (hasProductWithNameEqual === NameProjectsEnum.notExist) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
      }
      setEmptyNameError(false);
    }

    if (inputValue.length === 0) {
      setEqualNameError(false);
      setSimilarNameWarning(false);
      setEmptyNameError(true);
    }
  };

  const getAllNamesProduct = async (inputValue) => {
    const response = await api.get(`/productsname/${inputValue}`);
    return response.data.data.exists;
  };
  const getAllCodessProduct = async (inputValue) => {
    const response = await api.get(`/productscode/${inputValue}`);
    return response.data.data.exists;
  };

  const checkCodeInputValidation = async (inputValue) => {
    if (inputValue.length > 0) {
      const hasProducttWithCodeEqual = await getAllCodessProduct(inputValue);

      if (hasProducttWithCodeEqual === NameProjectsEnum.similar) {
        setSimilarCodeWarning(true);
      }
      if (hasProducttWithCodeEqual === NameProjectsEnum.equal) {
        setEqualCodeError(true);
      }
      if (hasProducttWithCodeEqual === NameProjectsEnum.notExist) {
        setEqualCodeError(false);
        setSimilarCodeWarning(false);
      }
      setEmptyCodeError(false);
    }

    if (inputValue.length === 0) {
      setEqualNameError(false);
      setSimilarNameWarning(false);
      setEmptyCodeError(true);
    }
  };

  const selectNameErrorMessage = () => {
    if (equalNameError) return t('products.modals.add-products.errors.already-has-name');
    if (similarNameWarning) return t('products.modals.add-products.errors.similar-name');
    if (emptyNameError) return t('products.modals.add-products.errors.no-name');
    return null;
  };

  const selectCodeErrorMessage = () => {
    if (equalCodeError) return t('products.modals.add-products.errors.already-has-code');
    if (similarCodeWarning) return t('products.modals.add-products.errors.similar-code');
    if (emptyCodeError) return t('products.modals.add-products.errors.no-code');
    return null;
  };

  useEffect(() => {
    if (productNameValue !== null) {
      checkNameInputValidation(productNameValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productNameValue]);

  useEffect(() => {
    if (productCodeValue !== null) {
      checkCodeInputValidation(productCodeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCodeValue]);

  function resetInputs() {
    setEqualCodeError(false);
    setSimilarCodeWarning(false);
    setEqualNameError(false);
    setSimilarNameWarning(false);
    setProductNameValue('');
    setProductCodeValue('');
    setProductTagValue({ idtag: null, tagnome: '', tagcolor: '', tagstatus: null, tagtipo: 'Tag' });
    setProjectTagSKU([]);
  }

  const confirmButtonHandler = () => {
    createNewProduct();
    resetInputs();
    setModalOpen(false);
    setOpenConfirmModal(false);
  };

  const closeModalHandler = () => {
    resetInputs();
    setModalOpen(false);
  };

  async function createNewProduct() {
    try {
      // eslint-disable-next-line no-unused-vars

      const newProduct = {
        produtonome: productNameValue,
        produtocodigo: productCodeValue,
        idtag: productTagValue.idtag,
        tagssku: projectTagSKU,
      };

      // eslint-disable-next-line no-unused-vars
      const response = await api.post('/product', newProduct);

      if (autoInputProduct) autoInputProduct(response.data.data.produto);
      queryClient.invalidateQueries(['products']);

      triggerPageRerender((prevState) => [...prevState, response.data.data.produto]);
      toast.success(t('products.modals.add-products.toast.success'));
    } catch (error) {
      console.log(error);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  const autocompleteTagInputHandler = (inputValue) => {
    setErrorMessageTag('');
    if (inputValue !== null) {
      setProductTagValue({
        tagnome: inputValue.label,
        idtag: inputValue.idtag,
        tagcolor: inputValue.tagcolor,
        tagstatus: inputValue.tagstatus,
        tagtipo: inputValue.tagtipo,
      });
    } else {
      setProductTagValue({
        tagnome: '',
        idtag: null,
        tagcolor: '',
        tagstatus: null,
        tagtipo: 'Tag',
      });
    }
  };

  const autocompleteTagSKUInputHandler = (inputValue) => {
    setProjectTagSKU(inputValue);
  };

  return (
    <>
      <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
        <GridStyle>
          <Typography variant="h3" align="center">
            {t('products.modals.add-products.heading')}
          </Typography>
          <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
            <IconButton size="large" onClick={closeModalHandler}>
              <Icon icon={closeIcon} />
            </IconButton>
          </Box>
          <FormControlStyle>
            <InputBoxStyle sx={{ gap: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextFieldWithPositionedErrors
                autoFocus
                error={equalNameError || emptyNameError}
                helperText={selectNameErrorMessage()}
                sx={{ width: '50%' }}
                id="product-name"
                label={t('products.modals.add-products.name-label')}
                variant="outlined"
                color="primary"
                value={productNameValue}
                onChange={(event) => setProductNameValue(event.target.value)}
                inputProps={{ maxLength: textfieldMaxLengthProduct }}
              />

              <TextFieldWithPositionedErrors
                error={equalCodeError || emptyCodeError}
                helperText={selectCodeErrorMessage()}
                onChange={(event) => setProductCodeValue(event.target.value)}
                sx={{ width: '50%' }}
                id="product-code"
                label={t('products.modals.add-products.code-label')}
                variant="outlined"
                color="primary"
                value={productCodeValue}
                inputProps={{ maxLength: textfieldMaxLengthProductCode }}
              />
              <Autocomplete
                onChange={(_, value) => autocompleteTagInputHandler(value)}
                noOptionsText={t('products.modals.add-products.no-tags')}
                id="produto-tag"
                options={transformedTags}
                isOptionEqualToValue={(option, value) => option.idtag === value.idtag}
                sx={{ width: '50%' }}
                renderInput={(params) => {
                  let selectedTag;
                  if (params.inputProps.value !== '') {
                    [selectedTag] = tags.filter((tag) => tag.tagnome === params.inputProps.value);
                  }
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <TextFieldWithPositionedErrors
                        error={errorMessageTag !== ''}
                        helperText={errorMessageTag !== '' && errorMessageTag}
                        {...params}
                        label="Tag"
                      />

                      {tags.map((tag) => {
                        if (params.inputProps.value === tag.tagnome) {
                          return (
                            <TagBox
                              key={tag.idtag}
                              sx={{
                                backgroundColor: selectedTag?.tagcolor,
                                marginLeft: '12px',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                paddingX: 1,
                                paddingY: 0.5,
                                borderRadius: '16px',
                                display: 'flex',
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: '#fff',
                                  textShadow: '1px 1px 1px #000',
                                }}
                              >
                                {selectedTag.tagnome}
                              </Typography>
                            </TagBox>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  );
                }}
                renderOption={(props, option) => (
                  <li key={option.idtag} {...props}>
                    <Box style={{ marginRight: 8 }}>
                      <TagBox
                        sx={{
                          backgroundColor: option.tagcolor,
                          marginLeft: '15px',
                          alignItems: 'center',
                          paddingX: 1,
                          paddingY: 0.5,
                          borderRadius: '16px',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            color: '#fff',
                            textShadow: '1px 1px 1px #000',
                          }}
                        >
                          {option.label}
                        </Typography>
                      </TagBox>
                    </Box>
                  </li>
                )}
              />
              <Autocomplete
                onChange={(_, value) => autocompleteTagSKUInputHandler(value)}
                value={projectTagSKU}
                multiple
                sx={{ width: '50%' }}
                id="tag-sku-checkboxes"
                isOptionEqualToValue={(option, value) => option.tagnome === value.tagnome}
                options={tags.filter((tag) => tag.tagtipo === 'Tag-SKU')}
                disableCloseOnSelect
                getOptionLabel={(option) => option.tagnome}
                renderTags={(value) =>
                  value.map((option) => (
                    <TagBox
                      key={option.idtag}
                      sx={{
                        backgroundColor: option.tagcolor,
                        alignItems: 'center',
                        paddingX: 1,
                        paddingY: 0.5,
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          color: '#fff',
                          textShadow: '1px 1px 1px #000',
                        }}
                      >
                        {option.tagnome}
                      </Typography>
                    </TagBox>
                  ))
                }
                renderInput={(params) => {
                  let selectedTag;
                  if (params.inputProps.value !== '') {
                    [selectedTag] = tags.filter((tag) => tag.tagnome === params.inputProps.value);
                  }
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <TextFieldWithPositionedErrors {...params} label="Tag-SKU" />
                      {tags.map((tag) => {
                        if (params.inputProps.value === tag.tagnome) {
                          return (
                            <TagBox
                              key={tag.idtag}
                              sx={{
                                backgroundColor: selectedTag?.tagcolor,
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                paddingX: 1,
                                paddingY: 0.5,
                                borderRadius: '16px',
                                display: 'flex',
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: '#fff',
                                  textShadow: '1px 1px 1px #000',
                                }}
                              >
                                {selectedTag.tagnome}
                              </Typography>
                            </TagBox>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li key={option.idtag} {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                    <Box>
                      <TagBox
                        sx={{
                          backgroundColor: option.tagcolor,
                          alignItems: 'center',
                          paddingX: 1,
                          paddingY: 0.5,
                          borderRadius: '16px',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            color: '#fff',
                            textShadow: '1px 1px 1px #000',
                          }}
                        >
                          {option.tagnome}
                        </Typography>
                      </TagBox>
                    </Box>
                  </li>
                )}
              />
            </InputBoxStyle>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
              <Button onClick={submitFormHandler} sx={{ width: '20%' }} variant="contained">
                {t('products.modals.add-products.create-product-button')}
              </Button>
            </Box>
          </FormControlStyle>
        </GridStyle>
      </ModalStyle>
      <ModalStyle open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
        <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 10, maxWidth: '90%' }}>
            <Typography variant="p" fontSize={30} textAlign={'center'}>
              {t('products.modals.add-products.confirm')} <BoldTypographyStyle>{productNameValue}</BoldTypographyStyle>,{' '}
              {t('products.modals.add-products.confirm-code')}{' '}
              <BoldTypographyStyle>{productCodeValue}</BoldTypographyStyle>{' '}
              {t('products.modals.add-products.confirm-tag')}{' '}
              <BoldTypographyStyle>{productTagValue.tagnome !== '' && productTagValue.tagnome}</BoldTypographyStyle>?
            </Typography>
            <InputBoxStyle>
              <ButtonStyle variant="contained" onClick={confirmButtonHandler}>
                {t('areas.new-area-modal.confirm-modal.confirm-button')}
              </ButtonStyle>
              <ButtonStyle onClick={() => setOpenConfirmModal(false)}>
                {t('areas.new-area-modal.confirm-modal.decline-button')}
              </ButtonStyle>
            </InputBoxStyle>
          </InputBoxStyle>
        </GridStyle>
      </ModalStyle>
    </>
  );
}
