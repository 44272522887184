import doc from '../assets/doc-text.svg';
import pdf from '../assets/pdf.svg';
import docx from "../assets/file-doc.svg";
import excel from "../assets/file-excel.svg";

export const checkTypeFile = (filePath) => {
  if(filePath.includes('.pdf')){
    return pdf
  }
  if(filePath.includes('.doc') || filePath.includes('.docx')){
    return docx
  }
  if(filePath.includes('.xlsx') || filePath.includes('.xls')){
    return excel
}
return doc
}