import { useEffect, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { Box, Avatar, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { light } from '../execution/components/defaultMentionStyle';

export const MetionsComments = ({ User, idprojeto, sendComent, idmoduloacao }) => {
  const [comentario, setComentario] = useState();
  const [focus, setFocus] = useState(false);

  const { t } = useTranslation();

  useEffect(()=>{
    setFocus(true)
  },[])

  const queryClient = useQueryClient();

  const projectAnnexCache = queryClient.getQueryData(['projectFlow', idprojeto]);

  const createMentionUsers = () =>
  projectAnnexCache?.areausuario.usuario.map((user) => ({ display: user.usuarionome, id: user.idusuario }));

  return (
    <Box sx={{ marginTop: 2, display: 'flex', gap: '1rem' }}>
      <Avatar alt={User.current?.usuarionome} src={User.current?.iconpath} />{' '}
      <Box sx={{ width: 900 }}>
        <MentionsInput
          style={light}
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
          placeholder={t('tabsTimeline.commentPlaceholder')}
          autoFocus={focus}
        >
          <Mention
            displayTransform={(id, login) => `@${login}`}
            trigger="@"
            data={createMentionUsers}
            markup="@[__display__:__id__]"
          />
        </MentionsInput>
      </Box>
      <Button
        variant="contained"
        sx={{ width: '10%', marginLeft: '2%', marginTop: 1 }}
        onClick={() => {
          sendComent(idmoduloacao, comentario);
          setComentario('');
        }}
      >
        {t('tabsTimeline.sendComment')}
      </Button>
    </Box>
  );
};
