import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import {
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Stack,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Paper,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import infoIcon from '@iconify/icons-mdi/information-variant';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-eva/search-fill';
import { StyledContainer } from '../styled-global/StyledGlobal';
import OpenDialogTags from '../modal/modal-tags/OpenDialogTags';
import { TagsContext } from '../../pages/Tags';
import DialogNewTags from '../modal/modal-tags/OpenDialogNewTags ';

// store
import { usePermission } from '../../store/usePermission';
import Page from '../Page';

// ------------------------------------------------------------------------

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearchBar = styled(OutlinedInput)(({ theme }) => ({
  width: 270,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 330, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

//-------------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  const t = stabilizedThis.map((el) => el[0]);

  return t;
}

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const headCells = [
    {
      id: 'tagnome',
      numeric: false,
      disablePadding: false,
      label: `${t('tags.headCell.labelName')}`,
    },
    {
      id: 'tagstatus',
      numeric: false,
      disablePadding: false,
      label: `${t('tags.headCell.labelStatus')}`,
    },
    {
      id: 'tagtipo',
      numeric: false,
      disablePadding: false,
      label: `${t('tags.headCell.labelType')}`,
    },
    {
      id: 'tagcolor',
      numeric: false,
      disablePadding: false,
      label: `${t('tags.headCell.labelColor')}`,
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ borderBottom: `1px solid ${theme.palette.table_filler}` }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation();
  const { numSelected, filterTag, setFilterTag } = props;

  return (
    <Page title={t('sidebar.tag')}>
      <StyledToolbar
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
          }),
          display: 'flex',
          justifyContent: 'space-between',
          gap: 5,
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <StyledSearchBar
            value={filterTag}
            onChange={(event) => setFilterTag(event.target.value)}
            placeholder={t('tags.search-label')}
            startAdornment={
              <InputAdornment position="start">
                <Icon icon={searchIcon} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
            endAdornment={
              <Tooltip title={t('tags.search-info')} disableInteractive>
                <InputAdornment position="start">
                  <Icon icon={infoIcon} width={20} height={20} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              </Tooltip>
            }
          />
        )}
      </StyledToolbar>
    </Page>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('sessao');
  const [selected, setSelected] = useState([]);
  const [filterTag, setFilterTag] = useState('');
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tagsContext = useContext(TagsContext);
  // eslint-disable-next-line no-unused-vars
  const { tags, setTags } = tagsContext;
  // eslint-disable-next-line no-unused-vars
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);
  const { t } = useTranslation();

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const tagView = userPermissions.includes('tagViewing');
      const tagAdm = userPermissions.includes('tagAdministrating');
      setPermissionView(tagView);
      setPermissionAdmin(tagAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tags.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      const filteredTags = array.filter((tag) => tag.tagnome.toLowerCase().indexOf(query.toLowerCase()) !== -1);

      return filteredTags;
    }

    return stabilizedThis.map((el) => el[0]);
  }

  const noTags = tags.length === 0;

  const filteredTags = applySortFilter(tags, getComparator(order, orderBy), filterTag);

  const isNotFound = !filteredTags.length && !!filterTag;

  return (
    <Page title={t('sidebar.tag')}>
      <StyledContainer>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            {t('tags.title')}
          </Typography>
          {permissionAdmin === true ? <DialogNewTags /> : ''}
        </Stack>
        <Card sx={{ overflow: 'visible' }}>
          <EnhancedTableToolbar filterTag={filterTag} setFilterTag={setFilterTag} />
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={tags.length}
              />
              <TableBody>
                {stableSort(filteredTags, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow tabIndex={-1} key={row.idtag}>
                        <TableCell component="th" id={labelId} scope="row" padding="normal" key={row.idtag}>
                          {row.tagnome}
                        </TableCell>
                        <TableCell align="left">
                          {row.tagstatus === 1 ? `${t('tags.active')}` : `${t('tags.deactivated')}`}
                        </TableCell>
                        <TableCell align="left">{row.tagtipo}</TableCell>

                        <TableCell align="left">
                          <Card
                            variant="outlined"
                            sx={{
                              backgroundColor: `${row.tagcolor}`,
                              width: '50%',
                              height: '1rem',
                              boxShadow: '2px 1px 10px #808080',
                            }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <OpenDialogTags
                            tagnome={row.tagnome}
                            tagtipo={row.tagtipo}
                            tagstatus={row.tagstatus}
                            tagcolor={row.tagcolor}
                            idtag={row.idtag}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          {t('projects.search-bar.not-found')}
                        </Typography>

                        <Typography variant="body2">
                          {t('projects.search-bar.not-found-text')} &nbsp;
                          <strong>&quot;{filterTag}&quot;</strong>.
                          <br /> {t('projects.search-bar.not-found-text-complement')}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {noTags && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Typography>{t('tags.no-tags')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredTags.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('tags.table-rows')}
          />
        </Card>
      </StyledContainer>
    </Page>
  );
}
