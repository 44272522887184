import { Box, TextField, Autocomplete, Checkbox } from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'react-i18next';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect } from 'react';
import Scrollbar from './Scrollbar';

export default function ConnectAreasAndUsers({
  areas,
  setSelectedAreas,
  selectedAreas,
  users,
  setSelectedUsers,
  selectedUsers,
  style,
  setAreasUsersData,
  privacidade,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);
  const SUPER_USER_ID = 1;

  useEffect(() => {
    const unitedData = { usuario: [], area: [] };
    const idUserArray = [accountCache.idusuario];

    unitedData.usuario = selectedUsers.map((user) => user.idusuario);
    if (privacidade === true) {
      unitedData.usuario =
        idUserArray[0] !== SUPER_USER_ID && unitedData.usuario.length > 0
          ? unitedData.usuario.concat(idUserArray)
          : unitedData.usuario;
      unitedData.usuario = [...new Set(unitedData.usuario)];
    }
    unitedData.area = selectedAreas.map((area) => area.idarea);

    const areasAndUsersJSON = JSON.stringify(unitedData);

    setAreasUsersData(areasAndUsersJSON);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAreas, selectedUsers]);

  const scrollBarStyle = {
    // height: '100%',
    paddingTop: '5px',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, alignContent: 'stretch', ...style }}>
      <Scrollbar sx={scrollBarStyle}>
        <Autocomplete
          sx={{ width: { md: 400, xl: '100%' } }}
          require
          multiple
          id="areas"
          value={selectedAreas || null}
          options={areas}
          isOptionEqualToValue={(option, value) => option.areanome === value.areanome}
          disableCloseOnSelect
          getOptionLabel={(option) => option.areanome}
          onChange={(_, newValue) => setSelectedAreas(newValue)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.areanome}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={t('connect-areas-users.areas')} />}
        />
      </Scrollbar>
      <Scrollbar sx={scrollBarStyle}>
        <Autocomplete
          sx={{
            width: { md: 400, xl: '100%' },
          }}
          multiple
          id="users"
          options={users}
          value={selectedUsers}
          isOptionEqualToValue={(option, value) => option.idusuario === value.idusuario}
          disableCloseOnSelect
          getOptionLabel={(option) => option.usuarionome}
          onChange={(event, newValue) => setSelectedUsers(newValue)}
          renderOption={(props, option, { selected }) => (
            <li key={option.idusuario} {...props}>
              <Checkbox
                key={option.idusuario}
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.usuarionome}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={t('connect-areas-users.users')} />}
        />
      </Scrollbar>
    </Box>
  );
}
