import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { ProjectStatus } from '../../../enum/ProjectStatusEnum';
import Label from '../../Label';
import { StyledTagBox } from '../../styled-global/StyledGlobal';

export const InfosForTimeline = ({ project }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Box sx={{ width: '70%' }}>
          <Typography gutterBottom variant="h5">
            {t('flow-timeline.info-geral')} :
          </Typography>
          <Divider />
          <Box>
            <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
              {t('tabsTimeline.nameProject')} <strong>{project?.projetonome}</strong>
            </Typography>
            <Divider />
          </Box>
          <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }} component={'pre'}>
            {t('tabsTimeline.descProject')} <strong>{project?.projetodescricao}</strong>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography gutterBottom variant="body1">
            {t('tabsTimeline.dateInit')} {project?.projetocriacao.split(' ')[0]}
          </Typography>
          <Typography gutterBottom variant="body1">
            {t('tabsTimeline.deadline')}{' '}
            {project?.projetodtconclusao === 'NaN/NaN/NaN NaN:NaN:NaN'
              ? t('tabsTimeline.noDate')
              : project?.projetodtconclusao.split(' ')[0]}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
          {t('tabsTimeline.initUserProject')} <strong>{project?.projetoiniciador.usuarionome}</strong>
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 'none', gap: '0.5rem' }}>
        <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
          {t('tabsTimeline.statusProject')}
        </Typography>
        {project?.projetostatus !== undefined && project?.projetostatus !== null && (
          <Label
            variant="ghost"
            color={
              (project?.projetostatus === ProjectStatus.Noflow && 'default') ||
              (project?.projetostatus === ProjectStatus.NoStarted && 'default') ||
              (project?.projetostatus === ProjectStatus.InProgress && 'info') ||
              (project?.projetostatus === ProjectStatus.Overdue && 'error') ||
              (project?.projetostatus === ProjectStatus.Complete && 'success') ||
              (project?.projetostatus === ProjectStatus.Canceled && 'error') ||
              (project?.projetostatus === ProjectStatus.Paused && 'warning')
            }
          >
            {(project?.projetostatus === ProjectStatus.Noflow && t('projects.project-status.no-flow')) ||
              (project?.projetostatus === ProjectStatus.NoStarted && t('projects.project-status.not-inicialized')) ||
              (project?.projetostatus === ProjectStatus.InProgress && t('projects.project-status.in-progress')) ||
              (project?.projetostatus === ProjectStatus.Overdue && t('projects.project-status.overdue')) ||
              (project?.projetostatus === ProjectStatus.Complete && t('projects.project-status.concluded')) ||
              (project?.projetostatus === ProjectStatus.Canceled && t('projects.project-status.canceled')) ||
              (project?.projetostatus === ProjectStatus.Paused && t('projects.project-status.paused'))}
          </Label>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 'none', gap: '0.5rem' }}>
        {project?.projetojustificacancel && (
          <>
            <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
              {t('flow-Modals-changeStatusProject.args-cancel')}
              {': '}
            </Typography>
            <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
              {project?.projetojustificacancel}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: 'none', gap: '0.5rem' }}>
        <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
          {t('tabsTimeline.tagProject')}{' '}
        </Typography>
        {project?.tag ? (
          <StyledTagBox tagColor={project?.tag !== undefined && project?.tag.tagcolor}>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#fff',
                textShadow: '1px 1px 1px #000',
              }}
            >
              {project?.tag !== undefined && project?.tag.tagnome}
            </Typography>
            <Divider />
          </StyledTagBox>
        ) : (
          <Typography>{t('tabsTimeline.nottagProject')}</Typography>
        )}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5, alignItems: 'center' }}>
        <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
          {t('tabsTimeline.productProject')}
        </Typography>
        {project?.product && <strong>{project?.product.produtonome}</strong>}

        {!project?.product && (
          <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
            {t('tabsTimeline.notproductProject')}
          </Typography>
        )}

        <Divider />
      </Box>
      <Divider />
      <Box>
        {project?.product?.produtocodigo && (
          <Typography gutterBottom variant="body1" sx={{ margin: '1rem 0' }}>
            {t('tabsTimeline.codeproductProject')} <strong>{project?.product.produtocodigo}</strong>
          </Typography>
        )}
        {project?.product?.produtocodigo && <Divider />}
      </Box>
    </>
  );
};
