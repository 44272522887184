import { useNavigate } from 'react-router-dom';
// @mui
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
  Paper,
  Tooltip,
  useMediaQuery,
  CircularProgress,
  Avatar
} from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIcon from '@iconify/icons-eva/arrow-ios-forward-fill';
// i18n
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// utils
import { useEffect, useState } from 'react';
import { fToNow } from '../../../utils/formatTime';
// components

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  // list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ isLoading, title, subheader, list, ...other }) {
  const [maxNewInCard, setMaxNewsInCard] = useState(3);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOpenVejaMais = () => {
    navigate('/news', { replace: true });
  };

  const down900pxHeight = useMediaQuery('(max-height: 900px)');
  const down800pxHeight = useMediaQuery('(max-height: 800px)');
  const down666pxHeight = useMediaQuery('(max-height: 666px)');

  useEffect(() => {
    if (down900pxHeight) setMaxNewsInCard(2);
    if (down800pxHeight) setMaxNewsInCard(1);
    if (!down900pxHeight && !down800pxHeight) setMaxNewsInCard(3);
  }, [down900pxHeight, down800pxHeight]);

  if (!list && isLoading) {
    return (
      <Paper elevation={3} sx={{ position: 'relative', minHeight: 205 }}>
        <Box sx={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%, -50%)' }}>
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  if (list && !isLoading && list.length <= 0) {
    return (
      <Paper elevation={3} sx={{ height: 430, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <br />
        <Typography variant="h4" align="center" color="primary">
          {t('home.no-news')}
        </Typography>
        <br />
      </Paper>
    );
  }

  return (
    <Card {...other}>
      {list && !isLoading && list?.length > 0 && (
        <>
          <Box>
            <CardHeader title={title} subheader={subheader} />

            <Box sx={{ p: down666pxHeight ? 2 : 3, pr: 0, gap: 1.5, display: 'flex', flexDirection: 'column' }}>
              {list?.map((news, index) => {
                if (index > maxNewInCard - 1) return null;
                return <NewsItem key={news.id} news={news} />;
              })}
            </Box>
          </Box>

          <Divider />
          <Box sx={{ padding: down800pxHeight ? '5px' : '16px', textAlign: 'right' }}>
            <Button size="small" color="inherit" endIcon={<Icon icon={arrowIcon} />} onClick={handleOpenVejaMais}>
              {t('home.update.button')}
            </Button>
          </Box>
        </>
      )}
    </Card>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  // eslint-disable-next-line no-unused-vars
  const { id, projeto, execdescricao, moduloacaodata, idusuario, usuarioicon, usuarionome, modulotxt, moduloestagio } =
    news;

  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ height: 82 }}>
      <Tooltip title={usuarionome} arrow>
        <Avatar
          alt={usuarionome}
          src={usuarioicon}
          sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0, objectFit: 'cover' }}
        />
      </Tooltip>
      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Typography color="inherit" variant="subtitle2" sx={{ '&:hover': { textDecoration: 'underline' } }} noWrap>
          {projeto} - {i18next.t('newsAll.stage')} {moduloestagio} - {modulotxt}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {execdescricao}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(moduloacaodata)}
      </Typography>
    </Stack>
  );
}
