import { useState, useEffect, createContext } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import TableTags from '../components/tables/TableTags';
// api
import api from '../service/api';
// store
// import { usePermission } from '../store/usePermission';

// ----------------------------------------------------------------------
// Provide
export const TagsContext = createContext();

// ----------------------------------------------------------------------

export default function Tags() {
  const [open, setOpen] = useState(true);
  const [tags, setTags] = useState([]);
  // // eslint-disable-next-line no-unused-vars
  // const [permissionView, setPermissionView] = useState(false);
  // // eslint-disable-next-line no-unused-vars
  // const [permissionAdmin, setPermissionAdmin] = useState(false);
  // const getUserPermissions = usePermission((state) => state.getUserPermission);

  const { t } = useTranslation();

  const fetchTagData = async () => {
    try {
      const response = await api.get('/tag');
      setTags(response.data.data.tags);
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  // useEffect(() => {
  //   const getPermission = async () => {
  //     const userPermissions = await getUserPermissions();
  //     const userView = userPermissions.includes('tagViewing');
  //     const userAdm = userPermissions.includes('tagAdministrating');
  //     setPermissionView(userView);
  //     setPermissionAdmin(userAdm);
  //   };
  //   getPermission();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    fetchTagData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={t('sidebar.tag')} sx={{ overflow: 'hidden' }}>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <TagsContext.Provider value={{ tags, setTags }}>
        <TableTags />
      </TagsContext.Provider>
    </Page>
  );
}
