// i18n
import i18next from 'i18next';
// icon
import { Icon } from '@iconify/react';
import homeIcon from '@iconify/icons-material-symbols/home';
import myJobsIcon from '@iconify/icons-mdi/briefcase';
import repositoryIcon from '@iconify/icons-mdi/archive';
import tagIcon from '@iconify/icons-mdi/tag';
import productsIcon from '@iconify/icons-eva/shopping-bag-fill';
import projectsIcon from '@iconify/icons-mdi/sitemap';
import areasIcon from '@iconify/icons-bx/area';
import usersIcon from '@iconify/icons-eva/people-fill';
import userAccountIcon from '@iconify/icons-mdi/account-box-multiple';
import settingsIcon from '@iconify/icons-eva/settings-2-fill';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: i18next.t('sidebar.home'),
    path: '/dashboard/app',
    icon: getIcon(homeIcon),
  },
  {
    title: i18next.t('sidebar.my-jobs'),
    path: '/morejobs',
    icon: getIcon(myJobsIcon),
  },
  {
    title: i18next.t('sidebar.repo'),
    path: '/repository',
    icon: getIcon(repositoryIcon),
  },
  {
    title: i18next.t('sidebar.tag'),
    path: '/tag',
    icon: getIcon(tagIcon),
  },
  {
    title: i18next.t('sidebar.products'),
    path: '/productsTable',
    icon: getIcon(productsIcon),
  },
  {
    title: i18next.t('sidebar.projects'),
    path: '/projects',
    icon: getIcon(projectsIcon),
  },
  // {
  //   title: i18next.t('sidebar.areas'),
  //   path: '/manageareas',
  //   icon: getIcon(areasIcon),
  // },
  // {
  //   title: i18next.t('sidebar.user'),
  //   path: '/user',
  //   icon: getIcon(usersIcon),
  // },
  // {
  //   title: i18next.t('sidebar.manageacess'),
  //   path: '/manageruser',
  //   icon: getIcon(userAccountIcon),
  // },
  {
    title: i18next.t('sidebar.report'),
    path: '/report',
    icon: getIcon('eva:file-text-fill'),
  },
  // {
  //   title: i18next.t('sidebar.settings'),
  //   path: '/config',
  //   icon: getIcon(settingsIcon),
  // },
];

const adminAndManagerNav = [
  {
    title: i18next.t('sidebar.areas'),
    path: '/manageareas',
    icon: getIcon(areasIcon),
  },
];

const adminNav = [
  {
    title: i18next.t('sidebar.user'),
    path: '/user',
    icon: getIcon(usersIcon),
  },
  {
    title: i18next.t('sidebar.manageacess'),
    path: '/manageruser',
    icon: getIcon(userAccountIcon),
  },
  {
    title: i18next.t('sidebar.settings'),
    path: '/config',
    icon: getIcon(settingsIcon),
  },
];

export { navConfig, adminAndManagerNav, adminNav };
