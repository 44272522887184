import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import Page from '../components/Page';
import ProductsTable from '../components/tables/ProductsTable';

// ----------------------------------------------------------------------

export default function Products() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <Page title={t('sidebar.products')}>
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} />
      <ProductsTable />
    </Page>
  );
}
