import { useState, createContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { FormGroup, InputLabel, Switch } from '@mui/material';
import toast from 'react-hot-toast';
import api from '../../../../service/api';
import ConnectAreasAndUsers from '../../../ConnectAreasAndUsers';
import ModalCopyModule from './Modal/ModalCopyModule';
import ModalAlerta from './Modal/ModalAlerta';
import ModalEmail from './Modal/ModalEmail';
import { textfieldMaxLengthModuleName, textfieldMaxLengthModuleDesc } from '../../../../utils/textfieldMaxLength';

export const NodeSelect = createContext();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 900,
    xl: 1200,
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
const stylesFlex = {
  display: 'flex',
  justifyContent: 'start',
  width: '25%',
  gap: '0.3rem',
};
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#FECD0E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const unionPropsOr = { UNIAO: { logic: 'OR' } };
const unionPropsAnd = { UNIAO: { logic: 'AND' } };
const formPropsOff = { FORM: { standard: 'OFF' } };
const formPropsOn = { FORM: { standard: 'ON' } };

export default function BasicModal({ infos = [], addModuleProps, name, desc, args, modal }) {
  const [open, setOpen] = useState(false);
  const [moduledesc, setModuledesc] = useState(desc);
  const [moduletxt, setModuletxt] = useState(name);
  const [users, setUsers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [areasUsers, setAreasUsers] = useState(null);
  const [checked, setChecked] = useState(false);
  const [checkedForm, setCheckedForm] = useState(false);
  const [propsUnion, setPropsUnion] = useState(unionPropsAnd);
  const [propsStandardForm, setPropsStandardForm] = useState(formPropsOff);
  const { setIsOpen } = args;
  const { nodeSelectCheck, idprojeto, project } = args;
  const { t } = useTranslation();

  useEffect(() => {
    const load = async () => {
      const responseModule = await api.get(`modules/module/${nodeSelectCheck.id}/${idprojeto}`);
      const { module } = responseModule.data.data;
      if (module.modulopropriedades === null && module.modulopropriedades === undefined) {
        //
      } else if (module.modulotipo === 'UNIAO') {
        const propsModule = module.modulopropriedades;
        if (propsModule !== null && propsModule !== undefined) {
          if (propsModule.UNIAO.logic === 'OR') setChecked(false);
          else setChecked(true);
          setPropsUnion(propsModule);
        }
      } else if (module.modulotipo === 'INICIAL') {
        const propsModule = module.modulopropriedades;
        if (propsModule.FORM.standard === 'ON') setCheckedForm(true);
        else setCheckedForm(false);
        setPropsStandardForm(propsModule);
      }
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inicializadorForAutocomplete = [
    {
      idusuario: project.current.projetoiniciador.idusuario,
      usuarionome: project.current.projetoiniciador.usuarionome,
    },
  ];

  const handleOpen = () => setOpen(true);

  const saveProperties = () => {
    if (nodeSelectCheck.categoryNode === 'ACAO') {
      const responsible = JSON.parse(areasUsers);
      if (responsible.area.length <= 0 && responsible.usuario.length <= 0) {
        toast.error(`${t('flow-Modals.without-responsible')}`, {
          duration: 5000,
        });
        return;
      }
      if (
        nodeSelectCheck.typeNode === 'FORM' &&
        (nodeSelectCheck.form === undefined || nodeSelectCheck.form === null)
      ) {
        toast.error(`${t('configRef.notFormConfig')}`, {
          duration: 5000,
        });
        return;
      }
    }
    if (nodeSelectCheck.typeNode === 'INICIAL')
      addModuleProps(moduledesc, moduletxt, args, propsStandardForm, areasUsers);
    else addModuleProps(moduledesc, moduletxt, args, propsUnion, areasUsers);
    setOpen(false);
    setIsOpen(false);
  };

  const handleChangeLogic = (event) => {
    if (event.target.checked) setPropsUnion(unionPropsAnd);
    else setPropsUnion(unionPropsOr);
    setChecked(event.target.checked);
    /*  if (event.target.checked) setPropsUnion(unionPropsOr);
     else setPropsUnion(unionPropsAnd);
     setChecked(event.target.checked); */
  };

  const logicUnion = () => {
    if (nodeSelectCheck.typeNode === 'UNIAO') {
      return (
        <FormGroup aria-label="position" row>
          <InputLabel id="demo-multiple-name-label" sx={{ marginRight: '2%' }}>
            {`${i18next.t('logicUnion.label')} ${''}`}
          </InputLabel>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{i18next.t('logicUnion.or')}</Typography>
            <AntSwitch
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={handleChangeLogic}
              checked={checked}
            />
            <Typography>{i18next.t('logicUnion.and')}</Typography>
          </Stack>
        </FormGroup>
      );
    }
    return null;
  };

  const handleChangeStandardForm = (event) => {
    if (event.target.checked) setPropsStandardForm(formPropsOn);
    else setPropsStandardForm(formPropsOff);
    setCheckedForm(event.target.checked);
  };

  const standardForm = () => {
    if (nodeSelectCheck.typeNode === 'INICIAL') {
      return (
        <FormGroup aria-label="position" row>
          <InputLabel id="demo-multiple-name-label" sx={{ marginRight: '2%' }}>
            {`${i18next.t('formBase.label')} ${''}`}
          </InputLabel>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{i18next.t('formBase.off')}</Typography>
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={handleChangeStandardForm}
              checked={checkedForm}
            />
            <Typography>{i18next.t('formBase.on')}</Typography>
          </Stack>
        </FormGroup>
      );
    }
    return null;
  };

  const copyModule = () => {
    if (nodeSelectCheck.categoryNode === 'ACAO') {
      return <ModalCopyModule key={'modalCopy'} args={args} />;
    }
    return null;
  };

  const alertModule = () => {
    if (nodeSelectCheck.typeNode === 'ALERTA') {
      return <ModalAlerta key={'modalAlert'} args={args} />;
    }
    return null;
  };

  const emailModule = () => {
    if (nodeSelectCheck.typeNode === 'EMAIL') {
      return <ModalEmail key={'modalEmail'} args={args} />;
    }
    return null;
  };

  const getAreasAndUsers = async () => {
    setAreas(project.current.areausuario.area);
    setUsers(project.current.areausuario.usuario);
  };

  const getselectAreasAndUsers = async (id, idprojeto) => {
    try {
      const response = await api.get(`/modules/resp/${id}/${idprojeto}`);
      setSelectedAreas([...response.data.data.responsible.area]);
      setSelectedUsers([...response.data.data.responsible.usuario]);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } 
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const setAresAndUsers = () => {
    if (nodeSelectCheck.categoryNode === 'ACAO') {
      return (
        <>
          <Typography variant="h4" sx={{ textAlign: 'center', marginTop: 1 }}>
            {i18next.t('flow-Modals.area-user-resp')}
          </Typography>
          <ConnectAreasAndUsers
            areas={areas}
            selectedAreas={selectedAreas}
            isOptionEqualToValue={false}
            setSelectedAreas={setSelectedAreas}
            users={
              users.filter((user) => user.idusuario === inicializadorForAutocomplete[0].idusuario).length > 0
                ? users
                : users.concat(inicializadorForAutocomplete)
            }
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            setAreasUsersData={setAreasUsers}
            style={{ marginTop: 3 }}
          />
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    getAreasAndUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getselectAreasAndUsers(nodeSelectCheck.id, idprojeto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeSelectCheck.id]);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen} sx={{ color: '#757575', width: '100%', height: '100%' }}>
        {i18next.t('flow-options.properties')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <IconButton
            onClick={handleClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h2"
              component="h1"
              sx={{ textAlign: 'center', marginBottom: 5 }}
            >
              {i18next.t('flow-Modals.module-infos')}
            </Typography>
            <Box sx={{ display: 'flex', gap: '2rem', marginBotton: '1rem' }}>
              {infos.map((info) => (
                <TextField
                  key={info.name}
                  margin="dense"
                  id={info.name}
                  label={info.name}
                  type="text"
                  variant="outlined"
                  defaultValue={info.value}
                  disabled
                />
              ))}
            </Box>

            <Box sx={{ display: 'flex', gap: '.5rem', flexDirection: 'column', marginTop: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={i18next.t('flow-Modals.nameModule')}
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={name === undefined ? '' : name}
                onChange={(e) => {
                  setModuletxt(e.target.value);
                }}
                inputProps={{ maxLength: textfieldMaxLengthModuleName }}
              />
              <TextField
                margin="dense"
                id="name"
                label={i18next.t('flow-Modals.descriptionModule')}
                type="text"
                fullWidth
                variant="outlined"
                defaultValue={desc === undefined ? '' : desc}
                onChange={(e) => {
                  setModuledesc(e.target.value);
                }}
                inputProps={{ maxLength: textfieldMaxLengthModuleDesc }}
              />
            </Box>

            {setAresAndUsers()}

            {copyModule()}

            {logicUnion()}

            {standardForm()}

            <NodeSelect.Provider value={{ nodeSelectCheck, idprojeto, project }}>
              <Box sx={stylesFlex}>
                {alertModule()}
                {emailModule()}
                {modal}
              </Box>
            </NodeSelect.Provider>
            <DialogActions sx={{ justifyContent: 'end' }}>
              <Button onClick={saveProperties} variant="contained" color="success">
                {i18next.t('flow-Modals.confirm-button')}
              </Button>
            </DialogActions>
          </Box>
        </>
      </Modal>
    </div>
  );
}
