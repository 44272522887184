import React, { useState, useContext, useMemo } from 'react';
import i18next from 'i18next';
// import { useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import DialogTitle from '@mui/material/DialogTitle';
import { Icon } from '@iconify/react';
import baselineFilePresent from '@iconify/icons-ic/baseline-file-present';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
import { NodeSelectTimeline } from '../BasicModalTimeline';
import ModalViewAnnex from '../../../../ModalViewAnnex';
import AnnexWithDownload from './AnnexWithDownload';
import Scrollbar from '../../../../Scrollbar';

export default function ModalRefAnexo({ annexs, userCurrent }) {
  const [open, setOpen] = useState(false);
  const [path, setpath] = useState('');
  const [imageFilename, setImageFilename] = useState();
  const [annexIdModulo, setannexIdModulo,] = useState('');
  const [annexIdModuloAnnex, setannexIdModuloAnnex,] = useState('');
  const [openModalPdf, setModalPdf] = useState(false);
  // const { state } = useLocation();
  // const { roleAdmin } = state;
  const nodeSelectTimeline = useContext(NodeSelectTimeline);
  const { project } = nodeSelectTimeline;



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line no-unused-vars
  const onDoubleClickHandler = (selectedDocument) => {
    const baseUrlAndPathfile = `${`/${selectedDocument.moduloanexocaminhopdf}`}`;
    toast.dismiss();
    setModalPdf(true);
    setpath(baseUrlAndPathfile);
    setannexIdModulo(selectedDocument.idmodulo)
    setannexIdModuloAnnex(selectedDocument.idmoduloanexo)
    setImageFilename(selectedDocument.moduloanexocaminhopdf);
  };

  // eslint-disable-next-line consistent-return
  const checkUserPriv = (arrPrivUser) => {
    if (userCurrent !== null && userCurrent !== undefined) {
      const arrUser = arrPrivUser.usuario;
      const arrArea = arrPrivUser.area;

      const checkUser = arrUser.some((user) => user === userCurrent?.idusuario);
      const checkArea = arrArea.some((user) => user === userCurrent?.idusuario);

      if (!checkUser && !checkArea) {
        return false;
      }
      if (checkUser || checkArea) {
        return true;
      }
    }
  };

  const checkArrayisEmpty = (arrPrivUser, arrPrivAreas) => {
    if (arrPrivUser.length <= 0 && arrPrivAreas.length <= 0) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-refAnnex.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon={baselineFilePresent} fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClickClose}
        maxWidth="1200px"
        fullWidth
        sx={{ margin: '0 auto', width: { md: '100%', xl: '60%' } }}
      >
        <>
            <IconButton
              onClick={handleClickClose}
              id="Botão fechar modal"
              sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle>{i18next.t('flow-Modals-refAnnex.title')}</DialogTitle>
          <Scrollbar sx={{ paddingLeft: 2, paddingRight: 2, maxHeight: 735, marginTop: 1, paddingBottom: 0 }}>
            <DialogContent>
              <ModalViewAnnex
                path={path}
                openModalPdf={openModalPdf}
                setModalPdf={setModalPdf}
                filename={imageFilename}
                id={imageFilename}
                idModule={annexIdModulo}
                idModuleAnnex={annexIdModuloAnnex}
                idProject={project?.idprojeto}
              />
              <FormGroup sx={{ display: 'flex', flexDirection: 'row', height: '15%', gap: '1rem' }}>
                {useMemo(
                  () =>
                    annexs.map((imagem, index) => {
                      if (
                        // true
                        checkUserPriv(imagem.moduloanexoprivacidade) ||
                        checkArrayisEmpty(imagem.moduloanexoprivacidade.usuario, imagem.moduloanexoprivacidade.area) ||
                        project.projetoiniciador.idusuario === userCurrent?.idusuario ||
                        userCurrent.roles[0].perfilnome === 'Administrador'
                      ) {
                        return (
                          <AnnexWithDownload
                            key={`annexx_with_download_${imagem.moduloanexonome}`}
                            index={index}
                            imagem={imagem}
                            onDoubleClickHandler={onDoubleClickHandler}
                          />
                        );
                      }
                      return <></>;
                    }),
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                  [annexs]
                )}
                {annexs.length <= 0 && <Typography>{i18next.t('notAnnex')}</Typography>}
              </FormGroup>
            </DialogContent>
          </Scrollbar>
            <DialogActions>
              <Button variant="contained" onClick={handleClickClose}>
                {i18next.t('flow-Modals.exit')}
              </Button>
            </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
