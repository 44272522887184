import { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Paper,
  IconButton,
  Avatar,
  Tooltip,
  CircularProgress,
  Switch,
} from '@mui/material';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import lockFill from '@iconify/icons-eva/lock-fill';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import circleIcon from '@iconify/icons-eva/play-circle-outline';
import stopCircleIcon from '@iconify/icons-eva/stop-circle-outline';
import editIcon from '@iconify/icons-eva/edit-outline';
import plusIcon from '@iconify/icons-eva/plus-fill';
import { useQueryClient, useQueries, useQuery } from 'react-query';

import format from 'date-fns/format';
import i18next from 'i18next';
import { useTheme } from '@emotion/react';
import Label from '../Label';
import { StyledContainer, StyledTagBox } from '../styled-global';
import { ProjectsTableListHead, ProjectsListToolbar } from './table-components/projects';
import EditProjectsModal from './table-components/projects/modals/EditProjectsModal';
import { ConfirmProjectStatusModal } from './table-components/projects/modals/ConfirmProjectStatusModal';
import { ProjectStatus } from '../../enum/ProjectStatusEnum';
import CancelProject from './table-components/projects/modals/CancelProject';
import ProjectDetailsModal from './table-components/projects/modals/ProjectDetailsModal';
import NewProjectModal from './table-components/projects/modals/NewProjectModal';
// api
import api from '../../service/api';
// store
import { usePermission } from '../../store/usePermission';
import FilterStatusProjects from './table-components/projects/FilterStatusProjects';

// ----------------------------------------------------------------------

export default function ProjectsTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [projects, setProjects] = useState([]);

  const [order, setOrder] = useState('desc');
  const [isNotFound, setIsNotFound] = useState(false);
  const [orderBy, setOrderBy] = useState('projetocriacao');
  const [tags, setTags] = useState([]);
  const [filterProject, setFilterProject] = useState('');
  const [filterProjectByStatus, setFilterProjectByStatus] = useState(0);
  const [areaFilter, setAreaFilter] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [cancelProjectModalOpen, setCancelProjectModalOpen] = useState(false);
  const [propertiesConfirm, setPropertiesConfirm] = useState({});
  const [statusProject, setStatusProject] = useState(-1);
  const [project, setProject] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectDetailsLoading, setProjectDetailsLoading] = useState({ state: false, projectId: null });
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [productsOn, setProductsOn] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const idFilter = useRef('projetocriacao');

  const getUser = async () => {
    try {
      // const responseUsers = await api.get('/users');
      const responseUsers = await api.get('/usersall');
      return responseUsers.data.data.users;
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
    return null;
  };
  const getAreas = async () => {
    try {
      const responseUsers = await api.get('/areas');
      return responseUsers.data.data.areas;
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
    return null;
  };

  const results = useQueries([
    { queryKey: ['users'], queryFn: getUser },
    {
      queryKey: ['areas'],
      queryFn: getAreas,
    },
  ]);

  // const currentUser = JSON.parse(localStorage.getItem('User'));
  const { search } = useLocation();
  const projectIdURL = search.replace(/\D/g, '');

  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: 'usuarionome', label: t('projects.initiator-label'), alignRight: false },
    { id: 'projetonome', label: t('projects.heading-label'), alignRight: false },
    { id: 'projetocriacao', label: t('projects.date'), alignRight: false },
    { id: 'idtag', label: 'Tag', alignRight: false },
    { id: 'projetostatus', label: 'Status', alignRight: false },
  ];

  // ----------------------------------------------------------------------
  let isLanguageEnglish = false;

  if (i18next.language === 'en') isLanguageEnglish = true;

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const projectView = userPermissions.includes('projectViewing');
      const projectAdm = userPermissions.includes('projectAdministrating');
      setPermissionView(projectView);
      setPermissionAdmin(projectAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Função que verificar o perfil do usuário para permissionar a home da aplicação
  const ProjectPermission = async () => {
    const isAdmin = accountCache?.roles.some((role) => role.perfilnome === 'Administrador');
    setIsAdmin(isAdmin);
  };

  const getTags = async () => {
    try {
      const tagsResponse = await api.get(`/tag`);
      setTags(tagsResponse.data.data.tags);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  const getProjects = async (page, rowsPerPage, filter, order, filterProject, checked) => {
    try {
      if (search && search.includes('projetct=')) {
        const response = await api.get(`/projectfind/${projectIdURL}`);
        setProject(...response.data.data.projects.projects);
        return response.data.data.projects;
      }
      if (search && search.includes('status')) {
        const response = await api.get(
          `/projectstotal/0/${rowsPerPage}/${
            rowsPerPage * page
          }/${filter}/${order}/${checked}/${filterProjectByStatus}/${areaFilter}/${filterProject}`
        );
        return response.data.data.projects;
      }
      if (!search) {
        const projectsResponse = await api.get(
          `/projectstotal/0/${rowsPerPage}/${
            rowsPerPage * page
          }/${filter}/${order}/${checked}/${filterProjectByStatus}/${areaFilter}/${filterProject}`
        );

        return projectsResponse.data.data.projects;
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
    return null;
  };

  const { data: projects, isLoading: isProjectsLoading } = useQuery(
    ['projects', page, rowsPerPage, idFilter.current, order, filterProject, filterProjectByStatus, areaFilter, checked],
    () => getProjects(page, rowsPerPage, idFilter.current, order, filterProject, checked),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const handleRequestSort = (event, property) => {
    idFilter.current = property;
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const checkSearchIdAndSetProject = async () => {
    if (search && projects?.projects?.length > 0) {
      setProject(...projects.projects);
      if (
        project.projetodatasolicitacaocancelamento !== null &&
        project.projetodatasolicitacaocancelamento !== undefined &&
        project.projetodatasolicitacaocancelamento.length > 0 &&
        project.projetostatus === ProjectStatus.Paused
      ) {
        setCancelProjectModalOpen(true);
      }
    }
  };

  const requestProductsOn = async () => {
    try {
      const productsOnResponse = await api.get('producton/');

      setProductsOn(productsOnResponse.data.data.products);
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      } if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  };

  useEffect(() => {
    if (projects?.projects.length === 0 && filterProject.length > 0) {
      setIsNotFound(true);
    }
    if (projects?.projects.length > 0 && filterProject.length > 0) {
      setIsNotFound(false);
    }
  }, [filterProject, projects?.projects.length]);

  const noProjects = projects?.projects?.length === 0;

  useEffect(() => {
    ProjectPermission();
    requestProductsOn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountCache]);

  useEffect(() => {
    getTags();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSearchIdAndSetProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const handleFilterByProject = (event) => {
    setPage(0);
    setFilterProject(event.target.value);
  };

  const checkButtomStartProject = (project) => {
    if (
      project.projetostatus === ProjectStatus.NoStarted &&
      project.projetobase === 0 &&
      (project.projetoiniciador.idusuario === accountCache?.idusuario || isAdmin)
    ) {
      return (
        <Tooltip title={t('flow-Modals-changeStatusProject.start-flow')}>
          <IconButton
            onClick={() => {
              setPropertiesConfirm({
                title: t('flow-Modals-changeStatusProject.initProject'),
                content: t('flow-Modals-changeStatusProject.initProjectContent'),
              });
              setStatusProject(ProjectStatus.InProgress);
              setProject(project);
              setConfirmModalOpen(true);
            }}
          >
            <Icon icon={circleIcon} width={30} height={30} color={'#229a16'} />
          </IconButton>
        </Tooltip>
      );
    }
    if (
      (project.projetostatus === ProjectStatus.InProgress || project.projetostatus === ProjectStatus.Overdue) &&
      project.acesso === '1'
    ) {
      return (
        <Tooltip title={t('flow-Modals-changeStatusProject.cancel-flow')}>
          <IconButton
            onClick={() => {
              setPropertiesConfirm({
                title: t('flow-Modals-changeStatusProject.cancelProject'),
                content: t('flow-Modals-changeStatusProject.cancelProjectContent'),
              });
              setStatusProject(ProjectStatus.Paused);
              setProject(project);
              setConfirmModalOpen(true);
            }}
          >
            <Icon icon={stopCircleIcon} width={30} height={30} color={'#ff4842'} />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  };

  const checkAdminOrInicializador = (projetoiniciador) => {
    if (projetoiniciador.idusuario === accountCache?.idusuario || isAdmin === true) {
      return true;
    }
    return false;
  };

  const checkProjectStatusForShowFlowTimelineButton = (project) => {
    if (
      project.projetostatus === ProjectStatus.InProgress ||
      project.projetostatus === ProjectStatus.Canceled ||
      project.projetostatus === ProjectStatus.Paused ||
      project.projetostatus === ProjectStatus.Overdue ||
      project.projetostatus === ProjectStatus.Complete
    ) {
      return true;
    }
    return false;
  };

  const goToflow = (project) => {
    if (checkProjectStatusForShowFlowTimelineButton(project) && project.acesso === '1') {
      return (
        <Tooltip title={t('workflow')}>
          <IconButton
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              const { idprojeto } = project;

              navigate(`/projects/${idprojeto}/timeline`, { state: { idprojeto, isAdmin } });
            }}
          >
            <SystemUpdateAltOutlinedIcon />
          </IconButton>
        </Tooltip>
      );
    }
    if (checkProjectStatusForShowFlowTimelineButton(project) && project.acesso !== '1') {
      return (
        <Tooltip title={t('notAccessProject')}>
          <Icon icon={lockFill} fontSize={30} color="text.secondary" />
        </Tooltip>
      );
    }
    return null;
  };
  const goToArgsForCancelProject = (project) => {
    if (
      project.projetodatasolicitacaocancelamento !== null &&
      project.projetodatasolicitacaocancelamento !== undefined &&
      project.projetostatus === ProjectStatus.Paused &&
      (isAdmin || project.projetoiniciador.idusuario === accountCache?.idusuario)
    ) {
      return (
        <Tooltip title={t('argsForCancelProject')}>
          <IconButton
            sx={{ color: 'text.secondary' }}
            onClick={() => {
              setProject(project);
              setCancelProjectModalOpen(true);
            }}
          >
            <AnnouncementIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  };

  const goToEditProject = (projectId, projetoiniciador, projectStatus, projetobase, project) => {
    if (
      checkAdminOrInicializador(projetoiniciador) &&
      projectStatus !== ProjectStatus.Complete &&
      projectStatus !== ProjectStatus.Canceled &&
      projectStatus !== ProjectStatus.Paused
    ) {
      return (
        <Tooltip title={t('projects.edit-project')} disableInteractive>
          <IconButton
            onClick={() => {
              setEditModalOpen(true);
              setProject(project);
            }}
            sx={{ color: 'text.secondary' }}
          >
            <Icon icon={editIcon} width={24} height={24} />
          </IconButton>
        </Tooltip>
      );
    }
    if (checkAdminOrInicializador(projetoiniciador) && projetobase === 1) {
      return (
        <Tooltip title={t('projects.edit-project')} disableInteractive>
          <IconButton
            onClick={() => {
              setEditModalOpen(true);
              setProject(project);
            }}
            sx={{ color: 'text.secondary' }}
          >
            <Icon icon={editIcon} width={24} height={24} />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  };

  const requestProjectDetails = async (projectId) => {
    try {
      const responseProject = await api.get(`projects/details/${projectId}`);
      return responseProject;
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }
      console.log(error);
      return error;
    }
  };

  const requestProductTag = (productTagId) => {
    const [filteredTag] = tags.filter((tag) => tag.idtag === productTagId);
    return filteredTag;
  };

  const requestAreasName = (projectAreas) => {
    const fetchedAreas = projectAreas.map((idarea) => {
      const [filteredArea] = results[1]?.data.filter((area) => area.idarea === idarea && area.areanome);
      return filteredArea.areanome;
    });
    return fetchedAreas.join(', ');
  };

  // eslint-disable-next-line consistent-return
  const requestUsersName = (projectUsers) => {
    if (projectUsers.length > 0) {
      const fetchedUsers = projectUsers.map((iduser) => {
        const [filteredUser] = results[0]?.data.filter((user) => user.idusuario === iduser && user.usuarionome);
        return filteredUser?.usuarionome;
      });
      return fetchedUsers.join(', ');
    }
    return '';
  };

  useEffect(() => {
    if (search && search.includes('status') && search.includes('area')) {
      const statusUrl = search.match(/status=([^&]*)/)[1];
      setFilterProjectByStatus(Number(statusUrl));
      const area = search.match(/area=([^&]*)/)[1];
      setAreaFilter(area);
    }
  }, [search]);

  const handleFilteredByStatus = (event) => {
    if (search && search.includes('projetct=')) {
      navigate('/projects');
      queryClient.invalidateQueries(['projects']);
    }
    setPage(0);
    setFilterProjectByStatus(Number(event.target.value));
  };

  const checkIsFIlterSelectedAndShowBorders = (value) => {
    if (filterProjectByStatus === value) {
      return ' 4px solid #FECD0E';
    }
    return 'none';
  };
  const checkIsProjectIsBase = (projectBase) => {
    if (projectBase === 1) {
      return (
        <Tooltip title={t('projects.base-project')} sx={{ cursor: 'default' }} disableInteractive>
          <Icon icon="fa-solid:copy" fontSize={20} sx={{ cursor: 'default' }} />
        </Tooltip>
      );
    }
    return null;
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <StyledContainer>
      <NewProjectModal
        isModalOpen={newProjectModalOpen}
        setModalOpen={setNewProjectModalOpen}
        products={productsOn}
        setProducts={setProductsOn}
        tags={tags}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <ProjectDetailsModal
        isModalOpen={detailsModalOpen}
        setModalOpen={setDetailsModalOpen}
        projectData={selectedProject}
        editProject={goToEditProject}
        flow={goToflow}
        startProject={checkButtomStartProject}
        cancelProject={goToArgsForCancelProject}
      />
      <EditProjectsModal
        isModalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        project={project}
        AllTags={tags}
        rerequestProjects={getTags}
        page={page}
        rowsPerPage={rowsPerPage}
        setDetailsModalOpen={setDetailsModalOpen}
      />
      <ConfirmProjectStatusModal
        isModalOpen={confirmModalOpen}
        setModalOpen={setConfirmModalOpen}
        propertiesConfirm={propertiesConfirm}
        status={statusProject}
        idprojeto={project?.idprojeto}
        project={project}
        admin={isAdmin}
      />
      <CancelProject
        setCancelProjectModalOpen={setCancelProjectModalOpen}
        cancelProjectModalOpen={cancelProjectModalOpen}
        project={project}
        status={statusProject}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4" gutterBottom>
          {t('projects.page-heading')}
        </Typography>
        {permissionAdmin && (
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusIcon} />}
            onClick={() => setNewProjectModalOpen(true)}
          >
            {t('projects.new-project')}
          </Button>
        )}
      </Stack>
      <Card sx={{ width: '100%' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ProjectsListToolbar filterProject={filterProject} onFilterProject={handleFilterByProject} />
          <Stack direction="row" spacing={2} alignItems="center">
            <FilterStatusProjects areaGroupName={areaFilter} setAreaGroupName={setAreaFilter} setPage={setPage} />
            <Button
              variant="contained"
              color="grayButton"
              value={0}
              onClick={handleFilteredByStatus}
              sx={{
                border: checkIsFIlterSelectedAndShowBorders(0),
              }}
            >
              All
            </Button>
            <Button
              variant="contained"
              color="info"
              value={1}
              onClick={handleFilteredByStatus}
              sx={{
                border: checkIsFIlterSelectedAndShowBorders(1),
              }}
            >
              {t('ongoingProjects')}
            </Button>
            <Button
              variant="contained"
              color="proximo"
              value={2}
              onClick={handleFilteredByStatus}
              sx={{
                border: checkIsFIlterSelectedAndShowBorders(2),
              }}
            >
              {t('dashboardProject.expirationProjects')}
            </Button>
            <Button
              variant="contained"
              color="error"
              value={3}
              onClick={handleFilteredByStatus}
              sx={{
                border: checkIsFIlterSelectedAndShowBorders(3),
              }}
            >
              {t('dashboardProject.overdueProjects')}
            </Button>
            <Button
              variant="contained"
              color="success"
              value={4}
              onClick={handleFilteredByStatus}
              sx={{
                border: checkIsFIlterSelectedAndShowBorders(4),
              }}
            >
              {t('dashboardProject.finishedProjects')}{' '}
            </Button>

            <Stack justifyContent="flex-end" direction="row" spacing={1} alignItems="center">
              <Typography component={'span'}>{t('projectsFilter.allprojects')}</Typography>
              <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
              <Typography component={'span'}>{t('projectsFilter.myProjects')}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <TableContainer sx={{ minWidth: 800, minHeight: 460 }}>
          <Table sx={{ minHeight: 450, position: 'relative' }}>
            {isProjectsLoading && (
              <TableBody
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate (-50%, 50%)',
                }}
              >
                <TableRow>
                  <TableCell sx={{ border: 'none' }}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!noProjects && !isProjectsLoading && (
              <>
                <ProjectsTableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={projects?.total}
                  onRequestSort={handleRequestSort}
                />

                <TableBody
                  sx={{ borderBottom: '1px solid', borderBottomColor: theme.palette.table_filler, minHeight: '100%' }}
                >
                  {projects?.projects.map((row, index) => {
                    // {filteredProjects.current?.map((row, index) => {
                    const { idprojeto, projetonome, projetostatus, idtag, projetoiniciador, projetocriacao } = row;
                    const [projectTag] = tags.filter((tag) => tag.idtag === idtag);

                    const rowForModal = row;

                    if (projectTag) {
                      row.projectTag = projectTag;
                    }
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{
                          cursor: permissionAdmin ? 'pointer' : 'default',
                          height: '0%',
                        }}
                        onClick={async () => {
                          try {
                            setProjectDetailsLoading({ state: true, projectId: idprojeto });
                            const projetcDetails = await requestProjectDetails(idprojeto);
                            const areas = await requestAreasName(rowForModal.projetovinculo.area);
                            const users = await requestUsersName(rowForModal.projetovinculo.usuario);

                            rowForModal.projetodtconclusao = projetcDetails.data.data.project.projetodtconclusao;

                            if (rowForModal.idproduto) {
                              rowForModal.product = projetcDetails.data.data.project.product;
                              const productTag = requestProductTag(rowForModal.product.idtag);
                              rowForModal.product.productTag = productTag;
                            }

                            rowForModal.roleAdmin = isAdmin;
                            rowForModal.areas = areas;
                            rowForModal.users = users;

                            setSelectedProject(rowForModal);
                            setDetailsModalOpen(true);
                            setProjectDetailsLoading({ state: false, projectId: null });
                          } catch (error) {
                            if (error.response.status === 401) {
                              // toast.error(`${error.response.data.data.errors[0]}`);
                              toast.error(`${t('not-Access')}`);
                            }
                            console.log(error);
                            toast.error(t('projects.project-details.error-loading'));
                            setProjectDetailsLoading({ state: false, projectId: null });
                          }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Tooltip title={projetoiniciador && projetoiniciador.usuarionome}>
                              <Avatar
                                alt={projetoiniciador && projetoiniciador.usuarionome}
                                src={projetoiniciador && projetoiniciador.iconpath}
                              />
                            </Tooltip>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" sx={{ paddingLeft: 2 }}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {projetonome}
                            </Typography>
                            {projectDetailsLoading.state && projectDetailsLoading.projectId === idprojeto && (
                              <Box>
                                <CircularProgress size={20} />
                              </Box>
                            )}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {format(new Date(projetocriacao), !isLanguageEnglish ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ width: '100%' }}>
                            {projectTag !== undefined && tags.length > 0 ? (
                              <StyledTagBox tagColor={projectTag !== undefined && projectTag.tagcolor}>
                                <Typography
                                  sx={{
                                    textAlign: 'center',
                                    color: '#fff',
                                    textShadow: '1px 1px 1px #000',
                                  }}
                                >
                                  {projectTag !== undefined && projectTag.tagnome}
                                </Typography>
                              </StyledTagBox>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (projetostatus === -1 && 'default') ||
                              (projetostatus === 0 && 'default') ||
                              (projetostatus === 1 && 'info') ||
                              (projetostatus === 2 && 'error') ||
                              (projetostatus === 3 && 'success') ||
                              (projetostatus === 4 && 'error') ||
                              (projetostatus === 5 && 'warning')
                            }
                          >
                            {(projetostatus === -1 && t('projects.project-status.no-flow')) ||
                              (projetostatus === 0 && t('projects.project-status.not-inicialized')) ||
                              (projetostatus === 1 && t('projects.project-status.in-progress')) ||
                              (projetostatus === 2 && t('projects.project-status.overdue')) ||
                              (projetostatus === 3 && t('projects.project-status.concluded')) ||
                              (projetostatus === 4 && t('projects.project-status.canceled')) ||
                              (projetostatus === 5 && t('projects.project-status.paused'))}
                          </Label>
                        </TableCell>

                        {permissionAdmin && (
                          <TableCell
                            align="right"
                            // sx={{ display: 'flex', alignItems: 'center' }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {checkIsProjectIsBase(row.projetobase)}
                            {goToEditProject(idprojeto, projetoiniciador, projetostatus, row.projetobase, row)}
                            {goToflow(row)}
                            {checkButtomStartProject(row)}
                            {goToArgsForCancelProject(row)}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </>
            )}
            {isNotFound === true && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        {t('projects.search-bar.not-found')}
                      </Typography>

                      <Typography variant="body2">
                        {t('projects.search-bar.not-found-text')} &nbsp;
                        <strong>&quot;{filterProject}&quot;</strong>.
                        <br /> {t('projects.search-bar.not-found-text-complement')}
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {noProjects && !isProjectsLoading && filterProject.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Typography>{t('projects.no-projects-created')}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Number(projects?.total) ? Number(projects?.total) : 5}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('projects.table-footer')}
        />
      </Card>
    </StyledContainer>
  );
}
