import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useQueryClient,
  useMutation,
  // useQuery
} from 'react-query';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, Avatar, Typography, Box } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { LoadingButton } from '@mui/lab';
// components
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { AlertConfig } from '../../../components/AlertConfig';
// api
import api from '../../../service/api';
import validations from '../../../service/validations';

// ----------------------------------------------------------------------

export default function PerfilEdit() {
  // const { state.id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  // const { id } = state

  const [userNameMinimal, setUserNameMinimal] = useState();
  const [valido, setValido] = useState(true);
  const [nome, setNome] = useState();
  const [icon, setIcon] = useState();
  const [error, setError] = useState({
    bool: '',
    mensagem: '',
  });

  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  useEffect(() => {
    if (state === null) {
      navigate('/404');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function settings() {
      const settings = await validations('users');
      setUserNameMinimal(settings.userNameMinimal);
    }
    settings();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIcon(accountCache?.iconpath);
  }, [accountCache?.iconpath]);
  useEffect(() => {
    setNome(accountCache?.usuarionome);
  }, [accountCache?.usuarionome]);

  async function updateName(nome) {
    try {
      const response = await api.put(`/users/${state.id}`, {
        name: nome,
      });
      if (response.status === 200) {
        toast.success(t('validation.profileedit.success'));
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    } catch (error) {
      if (error.respose.status === 400) {
        setError({
          bool: 'true',
          mensagem: `${error.response.data.data.errors[0]}`,
        });
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        setError({
          bool: 'true',
          mensagem:`${toast.error(`${t('not-Access')}`)}`
        })
      } else {
        setError({
          bool: 'true',
          mensagem: `${error.response.data.data.errors[0]}`,
        });
      }
      console.log(error);
    }
  }

  const perfilNameEditMutation = useMutation((nome) => updateName(nome), {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
    },
  });
  const iconEditMutation = useMutation((form) => sendIcon(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
    },
  });

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(`${t('validationPerfil.email')}`)
      .required(`${t('validationPerfil.emailRequired')}`),
    nome: yup.string().required(`${t('validationPerfil.emailRequired')}`),
  });

  const buttonStyled = {
    ...{
      width: '100%',
      alignItems: 'center',
      marginTop: '10%',
      padding: '5%',
      marginLeft: '25%',
    },
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    // eslint-disable-next-line no-unused-vars
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // eslint-disable-next-line consistent-return
  const sendIcon = async (form, iconURL) => {
    try {
      await api.post(`/usersicon/`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success(`${t('validation.profileedit.uptade-photo.success')}`);
      return iconURL;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${t('validation.profileedit.uptade-photo.error')}`);
      }
    }
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];
    const iconURL = URL.createObjectURL(file);
    setIcon(iconURL);
    const formData = new FormData();
    formData.append('id', state.id);
    formData.append('icon', file);
    iconEditMutation.mutate(formData, iconURL);
    // sendIcon(formData)
  };

  // eslint-disable-next-line consistent-return
  const validaNome = (nome) => {
    const regex = /^[a-zA-Zà-úÀ-Ú ]+$/;
    if (regex.test(nome) && nome.length >= userNameMinimal) {
      return true;
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom sx={{ marginLeft: '65%', width: '300px' }}>
          {t('validation.profileedit.update-profile')}
        </Typography>

        <Typography sx={{ color: 'text.secondary', mb: 5, marginLeft: '55%', width: '300px' }}>
          {t('validation.profileedit.update-infos')}
        </Typography>
      </Box>

      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.preventDefault();
          if (validaNome(nome)) {
            setValido(true);
            // updateName(nome);
            perfilNameEditMutation.mutate(nome);
          } else {
            setValido(false);
          }
        }}
      >
        <Stack spacing={3} alignItems="center" width="150%">
          <Avatar alt={accountCache?.usuarionome} src={icon} sx={{ width: 150, height: 150 }} />
          {/* <Avatar src={accountCache?.iconpath} alt="Foto" sx={{ width: 150, height: 150 }} /> */}
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{ marginTop: '0%', zIndex: '2' }}
          >
            <input hidden accept="image/*" type="file" onChange={handleChange} />
            <PhotoCamera />
          </IconButton>

          <AlertConfig error={error} />

          <RHFTextField name="email" value={accountCache?.usuarioemail} disabled />
          <RHFTextField
            name="nome"
            defaultValue={accountCache?.usuarionome}
            error={!valido}
            helperText={valido ? '' : `${t('validation.register.name-short')} ${userNameMinimal}`}
            value={nome}
            onChange={(event) => setNome(event.target.value)}
          />
        </Stack>

        <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting} sx={buttonStyled}>
          {t('validation.profileedit.update')}
        </LoadingButton>
      </FormProvider>
    </>
  );
}
