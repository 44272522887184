import { styled } from '@mui/material/styles';
import { Grid, Modal, Typography, Box, Button, Stack, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ProjectStatus } from '../../../../../enum/ProjectStatusEnum';
import api from '../../../../../service/api';
import { textfieldMinLength } from '../../../../../utils/textfieldMinLength'

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));



const schema = yup.object({
  argsForChangeStatus: yup
    .string()
    .required(`${i18next.t('flow-Modals-changeStatusProject.args-empty')}`)
    .min(textfieldMinLength, `${i18next.t('flow-Modals-changeStatusProject.args-minLength', { textfieldMinLength })}`),
});

export const ConfirmProjectStatusModal = ({
  isModalOpen,
  setModalOpen,
  propertiesConfirm,
  status,
  admin,
  idprojeto,
  project,
}) => {
  const [argument, setArgument] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  const checkUserIsAdminOrStarter = () => {
    if (status !== ProjectStatus.InProgress) {
      if (admin) {
        return ProjectStatus.Canceled;
      }
      if (accountCache?.idusuario === project.projetoiniciador.idusuario) {
        return ProjectStatus.Canceled;
      }
      return ProjectStatus.Paused;
    }
    return ProjectStatus.InProgress;
  };


  const handleChangeStatus = async (data) => {
    try {
      const body = {
        status: checkUserIsAdminOrStarter(),
      };

      if (status === ProjectStatus.Paused) {
        if (!data?.argsForChangeStatus?.length) {
          toast.error(t('requiredArgsForCancelProject'));
          return;
        }

        body.argument = data.argsForChangeStatus;
      }

      const projectsResponse = await api.put(`projectstatus/${idprojeto}`, body);
      if (projectsResponse.status === 201) {
        if (status === ProjectStatus.Paused) {
          toast.success(t('flow-Modals-changeStatusProject.toast-paused'));
        } else if (status === ProjectStatus.InProgress) {
          toast.success(t('flow-Modals-changeStatusProject.toast-success'));
        }
        if (location.pathname.split('/')[3] === 'timeline') {
          setTimeout(() => {
            navigate('/projects');
          }, 2000);
        } else {
          queryClient.invalidateQueries(['projects']);
        }
      }
      setArgument('');
      setModalOpen(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }else{

        toast.error(t('flow-Modals-changeStatusProject.toast-fail'));
      }
    }
  };

  const closeModalHandler = () => {
    setModalOpen(false);
    setArgument('');
  };

  const textfieldCanceledProject = () => {
    if (status === ProjectStatus.Paused) {
      return (
        <TextField
          key="textfield-canceled-project"
          id="textfield-canceled-project"
          label={t('flow-Modals-changeStatusProject.args-cancel')}
          fullWidth
          margin="dense"
          required
          multiline
          rows={4}
          name="argsForChangeStatus"
          value={argument}
          {...register('argsForChangeStatus')}
          onChange={(e) => setArgument(e.target.value)}
          helperText={errors.argsForChangeStatus?.message}
        />
      );
    }

    return null;
  };

  return (
    <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
      <GridStyle sx={{ position: 'relative', height: 'max-content' }}>
        <Typography variant="h3" align="center">
          {propertiesConfirm.title}
        </Typography>
        <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
          <IconButton size="large" onClick={() => closeModalHandler()}>
            <Icon icon={closeIcon} />
          </IconButton>
        </Box>
        <Typography variant="h5" align="center">
          {propertiesConfirm.content}
        </Typography>
        {textfieldCanceledProject()}

        <Stack sx={{ margin: '5% auto ', flexDirection: 'row', justifyContent: 'center', gap: '3rem' }}>
          <Button onClick={closeModalHandler} variant="contained">
            {t('flow-Modals.exit')}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={argument.length ? handleSubmit(handleChangeStatus) : handleChangeStatus}
          >
            {t('flow-Modals.confirm-button')}
          </Button>
        </Stack>
      </GridStyle>
    </ModalStyle>
  );
};
