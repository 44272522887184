/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, TextField, Box, Autocomplete, Checkbox } from '@mui/material';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';
import { StyledButton } from '../../../../styled-global';
import api from '../../../../../service/api';

// Estilos -------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '25rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));
// ---------------------------------------------------------------------------

export default function BondModal({ isModalOpen, setModalOpen, area }) {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [selectedUsersError, setSelecetdUsersError] = useState('');
  const { t } = useTranslation();

  const requestUsers = async () => {
    try {
      const responseUsers = await api.get(`/usersall`);
      setUsers(responseUsers.data.data.users);
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      }else{
        
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  };

  useEffect(() => {
    requestUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requestUsersInArea = async () => {
      if (area) {
        const responseUsersInArea = await api.get(`usersinarea/${area.idarea}`);

        const bondedUsersArray = responseUsersInArea.data.data.users;

        setSelectedUsers(bondedUsersArray);
        setOriginalUsers(bondedUsersArray);
      }
    };
    requestUsersInArea();
  }, [area, isModalOpen]);

  const setBondedUsersHandler = () => {
    if (originalUsers.length < selectedUsers.length) {
      const addedUsers = selectedUsers.filter((user) => !originalUsers.includes(user));
      const idUsers = addedUsers.map((user) => user.idusuario);
      addUsersInArea(idUsers);
    }

    if (originalUsers.length > selectedUsers.length) {
      const removedUsers = originalUsers.filter((user) => !selectedUsers.includes(user));
      const idUsers = removedUsers.map((user) => user.idusuario);
      deleteUsersInArea(idUsers);
    }

    if (originalUsers.length === selectedUsers.length) {
      const addedUsers = selectedUsers.filter((user) => !originalUsers.includes(user));
      const addedUsersId = addedUsers.map((user) => user.idusuario);

      const removedUsers = originalUsers.filter((user) => !selectedUsers.includes(user));
      const removedUsersId = removedUsers.map((user) => user.idusuario);

      const originalUsersId = originalUsers.map((user) => user.idusuario);

      addedUsers.forEach((addedUser) => {
        if (!originalUsersId.includes(addedUser.idusuario)) {
          deleteUsersInArea(removedUsersId);
          addUsersInArea(addedUsersId);
        }
      });
    }
  };

  const addUsersInArea = async (idUsers) => {
    try {
      const id = area.idarea;
      const responseUserArea = await api.post(`/areainslistausers/${id}`, {
        listauser: idUsers,
      });

      if (responseUserArea.data.status === 'success') {
        if (idUsers.length > 1) toast.success(t('areas.bond-modal.toast-success-multiple'));
        if (idUsers.length === 1) toast.success(t('areas.bond-modal.toast-success'));
        closeModalHandler();
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(error.message);
      }
    }
  };

  const deleteUsersInArea = async (idUsers) => {
    try {
      const deleteResponse = await api.delete(`usersinarea/${area.idarea}`, {
        data: {
          idusers: idUsers,
        },
      });

      if (deleteResponse.data.status === 'success') {
        if (idUsers.length > 1) toast.success(t('areas.bond-modal.toast-delete-success-multiple'));
        if (idUsers.length === 1) toast.success(t('areas.bond-modal.toast-delete-success'));
        closeModalHandler();
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(error.message);
      }
    }
  };

  const closeModalHandler = () => {
    setSelecetdUsersError('');
    // setSelectedUsers([]);
    setModalOpen(false);
  };

  return (
    <>
      <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
        <GridStyle
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" align="center">
            {t('areas.bond-modal.heading')}
          </Typography>
          <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
            <IconButton size="large" onClick={closeModalHandler}>
              <Icon icon={closeIcon} />
            </IconButton>
          </Box>
          <Autocomplete
            sx={{ width: '50%' }}
            multiple
            id="users"
            noOptionsText={t('areas.bond-modal.no-options')}
            options={users}
            value={selectedUsers}
            isOptionEqualToValue={(option, value) => option.idusuario === value.idusuario}
            disableCloseOnSelect
            getOptionLabel={(option) => option.usuarionome}
            onChange={(event, newValue) => setSelectedUsers(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.usuarionome}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                error={selectedUsersError !== ''}
                helperText={selectedUsersError !== '' && selectedUsersError}
                {...params}
                label={t('connect-areas-users.users')}
              />
            )}
          />
          <StyledButton contained onClick={setBondedUsersHandler}>
            {t('areas.bond-modal.confirm-button')}
          </StyledButton>
        </GridStyle>
      </ModalStyle>
    </>
  );
}
