import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
} from '@mui/material';
import toast from 'react-hot-toast';
// i18next
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-fill';
// utils
// import { permission } from '../../utils/permissions';
import transformToTitleCase from '../../utils/transformToTitleCase';
// components
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import { AreasListHead, AreasMoreMenu, AreasListToolbar } from './table-components/areas';
import { StyledContainer } from '../styled-global';
import { AddAreaModal, EditAreasModal, ConfirmChangeStatusModal, BondModal } from './table-components/areas/modals';
import Loading from '../Loading';
// api
import api from '../../service/api';
// store
import { usePermission } from '../../store/usePermission';

// ----------------------------------------------------------------------

export default function ManageAreasTable() {
  const [areas, setAreas] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setisLoading] = useState(true);
  const [addAreaModalOpen, setAddAreaModalOpen] = useState(false);
  const [editAreasModal, setEditAreasModal] = useState(false);
  const [bondModalOpen, setBondModalOpen] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('areanome');
  const [editArea, setEditArea] = useState(undefined);
  const [areaId, setAreaId] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterAreaName, setFilterAreaName] = useState('');
  const [confirmChangeStatusModalOpen, setConfirmChangeStatusModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [permissionView, setPermissionView] = useState(false);
  const [permissionAdmin, setPermissionAdmin] = useState(false);
  const getUserPermissions = usePermission((state) => state.getUserPermission);
  const { t } = useTranslation();

  // ----------------------------------------------------------------------

  const TABLE_HEAD = [
    { id: 'areanome', label: t('areas.table.area-label'), alignRight: false },
    { id: 'Squad', label: 'Squad', alignRight: false },
    { id: 'areastatus', label: 'Status', alignRight: false },
  ];

  // ----------------------------------------------------------------------

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return filter(array, (_user) => _user.areanome.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = areas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - areas.length) : 0;

  const filteredAreas = applySortFilter(areas, getComparator(order, orderBy), filterAreaName);

  const noAreas = areas.length === 0;
  const isNotFound = !filteredAreas.length && !!filterAreaName;

  async function requestApiManageAreas() {
    try {
      const responseAreas = await api.get(`/areas`);
      const areasBeforeTransformation = responseAreas.data.data.areas;
      const newAreas = areasBeforeTransformation.map((area) => ({
        idarea: area.idarea,
        areanome: area.areanome,
        areaagrupamento: area.areaagrupamento,
        areastatus: area.areastatus === 0 ? 'desativado' : 'ativo',
      }));
      setAreas(newAreas);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data[0]}`);
      }
    }
  }

  async function changeAreaStatus(areaName, newAreaStatus) {
    try {
      // eslint-disable-next-line no-unused-vars
      const responseAreaStatus = await api.put(`/areas/${areaName}`, {
        areastatus: newAreaStatus,
      });
      requestApiManageAreas();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        toast.error(t('areas.no-permission-toast'));
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function updateAreaName(newName, id) {
    try {
      // eslint-disable-next-line no-unused-vars
      const responseAreaNameStatus = await api.put(`/area/${id}`, {
        name: transformToTitleCase(newName),
      });
      requestApiManageAreas();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function updateGroupArea(newName, id) {
    try {
      // eslint-disable-next-line no-unused-vars
      const responseAreaNameStatus = await api.put(`/area/${id}`, {
        areaagrupamento: transformToTitleCase(newName),
      });
      requestApiManageAreas();
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  useEffect(() => {
    const getPermission = async () => {
      const userPermissions = await getUserPermissions();
      const areaView = userPermissions.includes('areaViewing');
      const areaAdm = userPermissions.includes('areaAdministrating');
      setPermissionView(areaView);
      setPermissionAdmin(areaAdm);
    };
    getPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestApiManageAreas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAreas]);

  const confirmChangeStatusHandler = (selectedArea, selectedStatus) => {
    let newStatus;
    if (selectedStatus === 'ativo') {
      newStatus = 0;
    }
    if (selectedStatus === 'desativado') {
      newStatus = 1;
    }
    changeAreaStatus(selectedArea, newStatus);
    setConfirmChangeStatusModalOpen(false);
  };

  const editAreaHandler = (selectedAreaId) => {
    setEditAreasModal(true);
    setEditArea(...areas.filter((area) => area.idarea === selectedAreaId));
  };

  const editAreaNameHandler = (newName, id) => {
    updateAreaName(newName, id);
  };

  const editGroupAreaHandler = (newName, id) => {
    updateGroupArea(newName, id);
  };

  const openEditAreasHandler = (id) => {
    editAreaHandler(id);
    setAreaId(id);
  };

  const handleFilterByAreaName = (event) => {
    setPage(0);
    setFilterAreaName(event.target.value);
  };

  const openConfirmChangeStatusModal = (areaid) => {
    setAreaId(areaid);
    setEditArea(...areas.filter((area) => area.idarea === areaid));
    setConfirmChangeStatusModalOpen(true);
  };

  const openBondModalHandler = (areaid) => {
    setBondModalOpen(true);
    setEditArea(...areas.filter((area) => area.idarea === areaid));
  };

  return (
    <StyledContainer>
      <Loading loading={isLoading} />
      <AddAreaModal
        isModalOpen={addAreaModalOpen}
        setModalOpen={() => setAddAreaModalOpen(false)}
        currentAreas={areas}
        updateAreasArray={() => requestApiManageAreas()}
      />
      <EditAreasModal
        isModalOpen={editAreasModal}
        setModalOpen={() => setEditAreasModal(false)}
        areaToBeEdited={editArea}
        areaId={areaId}
        editAreaNameFunction={editAreaNameHandler}
        editGroupAreaFunction={editGroupAreaHandler}
        currentAreas={areas}
      />
      <ConfirmChangeStatusModal
        isModalOpen={confirmChangeStatusModalOpen}
        setModalOpen={setConfirmChangeStatusModalOpen}
        itemToBeEdited={editArea}
        confirmButtonHandler={confirmChangeStatusHandler}
      />
      <BondModal isModalOpen={bondModalOpen} setModalOpen={() => setBondModalOpen(false)} area={editArea} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          {t('areas.title')}
        </Typography>
        {permissionAdmin === true ? (
          <Button onClick={() => setAddAreaModalOpen(true)} variant="contained" startIcon={<Icon icon={plusIcon} />}>
            {t('areas.new-area-button')}
          </Button>
        ) : (
          ''
        )}
      </Stack>

      <Card>
        <AreasListToolbar filterName={filterAreaName} onFilterName={handleFilterByAreaName} />
        <Scrollbar>
          <TableContainer>
            <Table>
              <AreasListHead
                order={order}
                orderBy={orderBy}
                numSelected={selected.length}
                headLabel={TABLE_HEAD}
                rowCount={areas.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredAreas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { idarea, areanome, areastatus, areaagrupamento } = row;
                  const isItemSelected = selected.indexOf(areanome) !== -1;

                  return (
                    <TableRow
                      hover
                      key={areanome}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell align="left">{areanome}</TableCell>
                      <TableCell align="left">{areaagrupamento}</TableCell>
                      <TableCell align="left">
                        <Label variant="ghost" color={(areastatus === 'desativado' && 'error') || 'success'}>
                          {areastatus === 'desativado'
                            ? sentenceCase(t('areas.table.status-disabled'))
                            : sentenceCase(t('areas.table.status-active'))}
                        </Label>
                      </TableCell>

                      <TableCell align="right">
                        {permissionAdmin === true ? (
                          <AreasMoreMenu
                            status={areastatus}
                            changeStatusFunction={() => openConfirmChangeStatusModal(idarea)}
                            openEditAreasModal={() => openEditAreasHandler(idarea)}
                            openBondModal={() => openBondModalHandler(idarea)}
                          />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          {t('areas.table.search-bar.not-found')}
                        </Typography>

                        <Typography variant="body2">
                          {t('areas.table.search-bar.not-found-text')} &nbsp;
                          <strong>&quot;{filterAreaName}&quot;</strong>.
                          <br /> {t('areas.table.search-bar.not-found-text-complement')}
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {noAreas && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Typography variant="p">{t('areas.table.has-no-area')}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAreas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('areas.table.rows-per-page')}
        />
      </Card>
    </StyledContainer>
  );
}
