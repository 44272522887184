import { useState } from 'react';
// layouts
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// components
import Page from '../components/Page';
import FlowForTimeline from '../components/ReactFlow/execution/FlowForTimeline';

// ----------------------------------------------------------------------

export default function Timeline() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  return (
    <Page title="Timeline">
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} navigateBack />
      <FlowForTimeline />
    </Page>
  );
}
