/* eslint-disable react/prop-types */
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, TextField, Box, FormControl, Autocomplete, Checkbox } from '@mui/material';
import { useState, useEffect, useLayoutEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-outline';
import { StyledButton } from '../../../../styled-global';
import { NameProjectsEnum } from '../../../../../enum/NameProjectsEnum';
import  {textfieldMaxLengthProductCode,textfieldMaxLengthProduct } from '../../../../../utils/textfieldMaxLength'
import api from '../../../../../service/api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Estilos -------------------------------------------------------------------

const ModalStyle = styled(Modal)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  height: '30rem',
  width: '50rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '16px',
  padding: 24,
}));

const InputBoxStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: 32,
}));

const FormControlStyle = styled(FormControl)(() => ({
  display: 'flex',
  height: '90%',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const TextFieldWithPositionedErrors = styled(TextField)(() => ({
  '.css-16d5wub-MuiFormHelperText-root': { position: 'absolute', top: 15, right: 20 },
}));

const TagBox = styled(Box)(() => ({
  height: '1.3rem',
  width: 50,
  marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 8px #808080',
  minWidth: 'max-content',
}));

// ---------------------------------------------------------------------------

export function EditProductsModal({
  isModalOpen,
  setModalOpen,
  itemToBeEdited,
  itemId,
  editProductFunction,
  tags,
}) {
  // OBS: As TAGS que estão sendo trazidas a este componente compõe todas as tags, inclusive as Tags-SKU

  const [productNameValue, setProductNameValue] = useState(itemToBeEdited.produtonome);
  const [productCodeValue, setProductCodeValue] = useState(itemToBeEdited.produtocodigo);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errorMessageTag, setErrorMessageTag] = useState('');
  const [errorMessageTagSKU, setErrorMessageTagSKU] = useState('');
  const [productTagValue, setProductTagValue] = useState({
    idtag: null,
    tagnome: '',
    tagcolor: '',
    tagstatus: null,
    tagtipo: 'Tag',
  });
  // const [originalTag, setOriginalTag] = useState({
  //   idtag: null,
  //   tagnome: '',
  //   tagcolor: '',
  //   tagstatus: null,
  //   tagtipo: 'Tag',
  // });
  const [tagsTypeTag, setTagsTypeTag] = useState([]);
  const [productTagSKU, setProductTagSKU] = useState([]);
  const [originalTagSKU, setOriginalTagSKU] = useState([]);

  const [similarNameWarning, setSimilarNameWarning] = useState(false);

  const [equalNameError, setEqualNameError] = useState(false);

  const [emptyNameError, setEmptyNameError] = useState(false);

  const [similarCodeWarning, setSimilarCodeWarning] = useState(false);

  const [equalCodeError, setEqualCodeError] = useState(false);

  const [emptyCodeError, setEmptyCodeError] = useState(false);

  const { t } = useTranslation();

  // useEffect para atualizar o valor dos inputs
  useLayoutEffect(() => {
    setProductNameValue(itemToBeEdited.produtonome);
    setProductCodeValue(itemToBeEdited.produtocodigo);

    setOriginalTagSKU(itemToBeEdited.tags);

    if (itemToBeEdited.idtag !== '') {
      const [productTag] = tags.filter((tag) => tag.idtag === itemToBeEdited.idtag);
      setProductTagValue(productTag);
      // setOriginalTag(productTag);
    }

    const tagsTypeTagArr = tags.filter((tag) => tag.tagtipo === 'Tag');

    setTagsTypeTag(tagsTypeTagArr);
    setProductTagSKU(itemToBeEdited.tags);
  }, [itemToBeEdited, tags, isModalOpen]);

  // Validação dos inputs
  useEffect(() => {
    if (productTagValue.idtag === null) {
      setErrorMessageTag(t('products.modals.edit-products.errors.no-tag'));
    } else {
      setErrorMessageTag('');
    }

    if (productTagSKU.idtag === []) {
      setErrorMessageTagSKU(t('products.modals.edit-products.errors.no-tagsku'));
    } else {
      setErrorMessageTagSKU('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productNameValue, productCodeValue, itemToBeEdited, productTagValue, productTagSKU]);

  const applyChangesHandler = () => {
    if (
      equalNameError ||
      equalCodeError ||
      errorMessageTag !== '' ||
      errorMessageTag !== '' ||
      errorMessageTagSKU !== ''
    )
      return;
    if (productNameValue.trim() === '') {
      setEmptyNameError(true);
      return;
    }
    if (productCodeValue.trim() === '') {
      setEmptyCodeError(true);
      return;
    }
    if (productTagValue.idtag === null) {
      setErrorMessageTag(t('products.modals.edit-products.errors.no-tag'));
      return;
    }

    setOpenConfirmModal(true);
  };

  // CONTINUAR FAZENDO O FETCH DO PRODUTO COM A TAG
  const confirmButtonHandler = () => {
    const removedTagSKU = [];
    const addedTagSKU = [];
    if (originalTagSKU.length > productTagSKU.length) {
      const filteredTagSKU = originalTagSKU.filter((tagSKU) => !productTagSKU.includes(tagSKU));
      removedTagSKU.push(...filteredTagSKU);
    }

    if (originalTagSKU.length < productTagSKU.length) {
      const filteredTagSKU = productTagSKU.filter((tagSKU) => !originalTagSKU.includes(tagSKU));
      addedTagSKU.push(...filteredTagSKU);
    }

    if (originalTagSKU.length === productTagSKU.length) {
      const removedTagsSKU = originalTagSKU.filter((tagSKU) => !productTagSKU.includes(tagSKU));
      const addedTagsSKU = productTagSKU.filter((tagSKU) => !originalTagSKU.includes(tagSKU));

      const addedTagsSKUId = addedTagsSKU.map((tagSKU) => tagSKU.idtag);
      const originalTagsSKUId = originalTagSKU.map((tagSKU) => tagSKU.idtag);

      addedTagsSKUId.forEach((addedTagSKUId) => {
        if (!originalTagsSKUId.includes(addedTagSKUId)) {
          addedTagSKU.push(...addedTagsSKU);
          removedTagSKU.push(...removedTagsSKU);
        }
      });
    }

    editProductFunction(
      productNameValue,
      productCodeValue,
      productTagValue.idtag,
      removedTagSKU,
      addedTagSKU,
      itemToBeEdited.produtostatus,
      itemId
    );

    clearInputs();
    setModalOpen(false);
    setOpenConfirmModal(false);
  };

  const closeModalHandler = () => {
    clearInputs();
    setModalOpen(false);
  };

  const clearInputs = () => {
    setProductNameValue('');
    setProductCodeValue('');
    setProductTagValue({
      idtag: null,
      tagnome: '',
      tagcolor: '',
      tagstatus: null,
      tagtipo: 'Tag',
    });
    setProductTagSKU([]);
  };

  const tagInputChageHandler = (value) => {
    if (value === null) {
      setProductTagValue({
        idtag: null,
        tagnome: '',
        tagcolor: '',
        tagstatus: null,
        tagtipo: 'Tag',
      });
    } else {
      setProductTagValue(value);
    }
  };

  const autocompleteTagSKUInputHandler = (inputValue) => {
    setProductTagSKU(inputValue);
  };

  const checkNameInputValidation = async (inputValue) => {
    if (inputValue.length > 0) {
      if (inputValue.trim() === itemToBeEdited.produtonome.trim()) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
        setEmptyNameError(false);
        return;
      }

      const hasProjectWithNameEqual = await getAllNamesProduct(inputValue);

      if (hasProjectWithNameEqual === NameProjectsEnum.similar) {
        setSimilarNameWarning(true);
      }
      if (hasProjectWithNameEqual === NameProjectsEnum.equal) {
        setEqualNameError(true);
      }
      if (hasProjectWithNameEqual === NameProjectsEnum.notExist) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
      }
      setEmptyNameError(false);
    }

    if (inputValue.length === 0) {
      setEqualNameError(false);
      setSimilarNameWarning(false);
      setEmptyNameError(true);
    }

  };

  const getAllNamesProduct = async (inputValue) => {
    const response = await api.get(`/productsname/${inputValue}`);
    return response.data.data.exists;
  };
  const getAllCodessProduct = async (inputValue) => {
    const response = await api.get(`/productscode/${inputValue}`);
    return response.data.data.exists;
  };

  const checkCodeInputValidation = async (inputValue) => {
    if (inputValue.length > 0) {
      if (inputValue.trim() === itemToBeEdited.produtocodigo.trim()) {
        setEqualCodeError(false);
        setSimilarCodeWarning(false);
        setEmptyCodeError(false);
        return;
      }

      const hasProducttWithCodeEqual = await getAllCodessProduct(inputValue);

      if (hasProducttWithCodeEqual === NameProjectsEnum.similar) {
        setSimilarCodeWarning(true);
      }
      if (hasProducttWithCodeEqual === NameProjectsEnum.equal) {
        setEqualCodeError(true);
      }
      if (hasProducttWithCodeEqual === NameProjectsEnum.notExist) {
        setEqualCodeError(false);
        setSimilarCodeWarning(false);
      }
      setEmptyCodeError(false);
    }

      if (inputValue.length === 0) {
        setEqualNameError(false);
        setSimilarNameWarning(false);
        setEmptyCodeError(true);
      }
  };

  const selectNameErrorMessage = () => {
    if (equalNameError) return t('products.modals.add-products.errors.already-has-name');
    if (similarNameWarning) return t('products.modals.add-products.errors.similar-name');
    if (emptyNameError) return t('products.modals.add-products.errors.no-name');
    return null;
  };

  const selectCodeErrorMessage = () => {
    if (equalCodeError) return t('products.modals.add-products.errors.already-has-code');
    if (similarCodeWarning) return t('products.modals.add-products.errors.similar-code');
    if (emptyCodeError) return t('products.modals.add-products.errors.no-code');
    return null;
  };


  useEffect(() => {
    // checkNameInputValidation(productNameValue);
    checkCodeInputValidation(productCodeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCodeValue]);

  useEffect(() => {
    if (itemToBeEdited.produtonome !== undefined && itemToBeEdited.produtonome !== null) {
      checkNameInputValidation(productNameValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productNameValue]);

  return (
    <>
      <ModalStyle open={isModalOpen} onClose={closeModalHandler}>
        <GridStyle sx={{ position: 'relative' }}>
          <Typography variant="h3" align="center">
            {t('products.modals.edit-products.heading')}
          </Typography>
          <Box sx={{ position: 'absolute', top: '0.5%', right: '0.5%', zIndex: 1000 }}>
            <IconButton size="large" onClick={() => closeModalHandler()}>
              <Icon icon={closeIcon} />
            </IconButton>
          </Box>
          <FormControlStyle>
            <InputBoxStyle sx={{ gap: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextFieldWithPositionedErrors
                autoFocus
                error={equalNameError || emptyNameError}
                helperText={selectNameErrorMessage()}
                sx={{ width: '80%' }}
                id="product-name"
                label={t('products.modals.add-products.name-label')}
                variant="outlined"
                color="success"
                value={productNameValue}
                onChange={(event) => setProductNameValue(event.target.value)}
                inputProps={{ maxLength: textfieldMaxLengthProduct }}
              />
              <TextFieldWithPositionedErrors
                error={equalCodeError || emptyCodeError}
                helperText={selectCodeErrorMessage()}
                sx={{ width: '80%' }}
                id="produto-codigo"
                label={t('products.modals.add-products.code-label')}
                variant="outlined"
                color="primary"
                value={productCodeValue}
                onChange={(event) => setProductCodeValue(event.target.value)}
                inputProps={{ maxLength: textfieldMaxLengthProductCode }}
              />
              {/* Input de edição da Tag */}
              <Autocomplete
                onChange={(_, value) => tagInputChageHandler(value)}
                value={productTagValue}
                noOptionsText={t('products.modals.add-products.no-tags')}
                id="produto-tag"
                options={tagsTypeTag}
                getOptionLabel={(option) => option.tagnome}
                isOptionEqualToValue={(option, value) => option.idtag !== value.idtag}
                sx={{ width: '80%' }}
                renderInput={(params) => {
                  let selectedTag;
                  if (params.inputProps.value !== '') {
                    [selectedTag] = tags.filter((tag) => tag.tagnome === params.inputProps.value);
                  }
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <TextFieldWithPositionedErrors
                        error={errorMessageTag !== ''}
                        helperText={errorMessageTag !== '' && errorMessageTag}
                        {...params}
                        label="Tag"
                      />

                      {tags.map((tag) => {
                        if (params.inputProps.value === tag.tagnome ) {
                          return (
                            <TagBox
                              key={tag.idtag}
                              sx={{
                                backgroundColor: selectedTag?.tagcolor,
                                marginLeft: '12px',
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                paddingX: 1,
                                paddingY: 0.5,
                                borderRadius: '16px',
                                display: 'flex',
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: '#fff',
                                  textShadow: '1px 1px 1px #000',
                                }}
                              >
                                {selectedTag.tagnome}
                              </Typography>
                            </TagBox>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  );
                }}
                renderOption={(props, option) => (
                  <li key={option.idtag} {...props}>
                    <Box style={{ marginRight: 8 }}>
                      <TagBox
                        sx={{
                          backgroundColor: option.tagcolor,
                          marginLeft: '15px',
                          alignItems: 'center',
                          paddingX: 1,
                          paddingY: 0.5,
                          borderRadius: '16px',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            color: '#fff',
                            textShadow: '1px 1px 1px #000',
                          }}
                        >
                          {option.tagnome}
                        </Typography>
                      </TagBox>
                    </Box>
                  </li>
                )}
              />
              <Autocomplete
                onChange={(_, value) => {
                  setErrorMessageTagSKU('');
                  autocompleteTagSKUInputHandler(value);
                }}
                value={productTagSKU}
                multiple
                sx={{ width: '80%' }}
                id="tag-sku-checkboxes"
                isOptionEqualToValue={(option, value) => option.tagnome === value.tagnome}
                options={tags.filter((tag) => tag.tagtipo === 'Tag-SKU' && tag.tagstatus === 1)}
                disableCloseOnSelect
                getOptionLabel={(option) => option.tagnome}
                renderTags={(value) =>
                  value.map((option) => (
                    <TagBox
                      key={option.idtag}
                      sx={{
                        backgroundColor: option.tagcolor,
                        alignItems: 'center',
                        paddingX: 1,
                        paddingY: 0.5,
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          color: '#fff',
                          textShadow: '1px 1px 1px #000',
                        }}
                      >
                        {option.tagnome}
                      </Typography>
                    </TagBox>
                  ))
                }
                renderInput={(params) => {
                  let selectedTag;
                  if (params.inputProps.value !== '') {
                    [selectedTag] = tags.filter((tag) => tag.tagnome === params.inputProps.value);
                  }
                  return (
                    <Box sx={{ position: 'relative' }}>
                      <TextFieldWithPositionedErrors
                        error={errorMessageTagSKU !== ''}
                        helperText={errorMessageTagSKU}
                        {...params}
                        label="Tag-SKU"
                      />
                      {tags.map((tag) => {
                        if (params.inputProps.value === tag.tagnome) {
                          return (
                            <TagBox
                              key={tag.idtag}
                              sx={{
                                backgroundColor: selectedTag?.tagcolor,
                                alignItems: 'center',
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                paddingX: 1,
                                paddingY: 0.5,
                                borderRadius: '16px',
                                display: 'flex',
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  color: '#fff',
                                  textShadow: '1px 1px 1px #000',
                                }}
                              >
                                {selectedTag.tagnome}
                              </Typography>
                            </TagBox>
                          );
                        }
                        return null;
                      })}
                    </Box>
                  );
                }}
                renderOption={(props, option, { selected }) => (
                  <li key={option.idtag} {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                    <Box>
                      <TagBox
                        sx={{
                          backgroundColor: option.tagcolor,
                          alignItems: 'center',
                          paddingX: 1,
                          paddingY: 0.5,
                          borderRadius: '16px',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: 'center',
                            color: '#fff',
                            textShadow: '1px 1px 1px #000',
                          }}
                        >
                          {option.tagnome}
                        </Typography>
                      </TagBox>
                    </Box>
                  </li>
                )}
              />
            </InputBoxStyle>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
              <StyledButton contained onClick={applyChangesHandler}>
                {t('areas.edit-area-modal.apply-button')}
              </StyledButton>
            </Box>
          </FormControlStyle>
        </GridStyle>
      </ModalStyle>
      <ModalStyle open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
        <GridStyle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'max-content' }}>
          <InputBoxStyle sx={{ flexDirection: 'column', justifyContent: 'center', gap: 10, maxWidth: '90%', flex: 1 }}>
            {
              <Typography variant="p" fontSize={25} textAlign={'center'}>
                Deseja editar o Produto?
              </Typography>
            }
            <InputBoxStyle>
              <StyledButton contained onClick={confirmButtonHandler}>
                {t('areas.edit-area-modal.confirm-modal.confirm-button')}
              </StyledButton>
              <StyledButton onClick={() => setOpenConfirmModal(false)}>
                {t('areas.edit-area-modal.confirm-modal.decline-button')}
              </StyledButton>
            </InputBoxStyle>
          </InputBoxStyle>
        </GridStyle>
      </ModalStyle>
    </>
  );
}
