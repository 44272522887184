import { Typography, Box, Card, styled, Button, Modal, TextField, Autocomplete, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useTranslation } from 'react-i18next';

import closeIcon from '@iconify/icons-material-symbols/close';

import format from 'date-fns/format';

import { Icon } from '@iconify/react';

import i18next from 'i18next';
import Label from '../../../../Label';

const StyledCard = styled(Card)(({ theme }) => ({
  width: 1000,
  height: 'max-content',
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down(1745)]: {
    flexDirection: 'row',
    width: 1300,
  },
}));

const StyledOutterBox = styled(Box)(() => ({
  padding: 10,
  width: '100%',
}));

const StyledRowBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 10,
  gap: 5,
}));

const TagBox = styled(Box)(() => ({
  height: '1.3rem',
  width: 50,
  marginLeft: 5,
  borderRadius: 16,
  boxShadow: '2px 1px 8px #808080',
  minWidth: 'max-content',
}));

const ButtonsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  paddingTop: 10,
  alignItems: 'center',
}));

export default function ProjectDetailsModal({
  isModalOpen,
  setModalOpen,
  projectData,
  editProject,
  flow,
  startProject,
  cancelProject,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const accountCache = queryClient.getQueryData(['account']);

  let isLanguageEnglish = false;
  if (i18next.language === 'en') isLanguageEnglish = true;

  let conclusionDate;

  if (projectData) {
    if (projectData.projetodtconclusao === null || projectData.projetodtconclusao?.split(' ')[0] === 'NaN/NaN/NaN')
      conclusionDate = t('projects.project-details.no-completion-date');
    else {
      conclusionDate = projectData.projetodtconclusao?.split(' ')[0];

      if (isLanguageEnglish) {
        const splittedDate = conclusionDate.split('/');
        conclusionDate = `${splittedDate[1]}/${splittedDate[0]}/${splittedDate[2]}`;
      }
    }
  }

  const checkAdminOrInicializador = () => {
    if (projectData) {
      const { idprojeto, projetoiniciador, roleAdmin } = projectData;
      if (projectData?.projetoiniciador?.idusuario === accountCache?.idusuario || projectData?.roleAdmin === true) {
        return (
          <Button
            sx={{ width: 200 }}
            variant="contained"
            to="#"
            onClick={() =>
              navigate(`/projects/${idprojeto}/workflow`, { state: { idprojeto, projetoiniciador, roleAdmin } })
            }
          >
            {t('projects.project-details.project-flow-button')}
          </Button>
        );
      }
    }
    return null;
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
    >
      <StyledCard id="Card pai">
        <IconButton
          onClick={() => setModalOpen(false)}
          id="Botão fechar modal"
          sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' } }}
        >
          <Icon icon={closeIcon} fontSize={32} />
        </IconButton>
        {projectData && (
          <>
            {/* 
            
            PROJETO 
            
            */}
            <StyledOutterBox id="Box pai de Projeto">
              <Typography variant="h5" textAlign="center">
                {t('projects.project-details.project-table-heading')}
              </Typography>
              <StyledRowBox>
                <TextField
                  id="Nome do projeto"
                  value={projectData.projetonome}
                  label={t('projects.project-details.project-name')}
                  variant="outlined"
                  color="primary"
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
                <TextField
                  id="Iniciador do projeto"
                  value={projectData.projetoiniciador.usuarionome}
                  label={t('projects.project-details.project-initiator')}
                  variant="outlined"
                  color="primary"
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
              </StyledRowBox>
              <StyledRowBox>
                <TextField
                  id="Data de criação"
                  value={format(new Date(projectData.projetocriacao), !isLanguageEnglish ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}
                  label={t('projects.project-details.creation-date')}
                  variant="outlined"
                  color="primary"
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
                <TextField
                  id="Data de conclusão"
                  maxRows={2}
                  multiline
                  value={conclusionDate}
                  label={t('projects.project-details.completion-date')}
                  variant="outlined"
                  color="primary"
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
              </StyledRowBox>
              <StyledRowBox>
                <TextField
                  id="Descrição do projeto"
                  value={
                    projectData.projetodescricao !== ''
                      ? projectData.projetodescricao
                      : t('projects.project-details.no-description')
                  }
                  label={t('projects.project-details.project-description')}
                  variant="outlined"
                  color="primary"
                  multiline
                  maxRows={2}
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
                <TextField
                  id="Projeto base"
                  value={
                    projectData.projetobase
                      ? t('projects.project-details.base-project-yes')
                      : t('projects.project-details.base-project-no')
                  }
                  label={t('projects.project-details.base-project')}
                  variant="outlined"
                  color="primary"
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
              </StyledRowBox>
              <StyledRowBox id="Status">
                <Autocomplete
                  noOptionsText={t('products.modals.add-products.no-tags')}
                  id="Tag do Projeto"
                  readOnly
                  sx={{ width: '100%', '.MuiAutocomplete-endAdornment': { display: 'none' } }}
                  defaultValue=" "
                  options={[]}
                  renderInput={(params) => (
                    <Box sx={{ position: 'relative' }}>
                      <TextField {...params} variant="outlined" color="primary" label="Status" />

                      <Label
                        sx={{
                          marginLeft: '12px',
                          alignItems: 'center',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          paddingX: 1,
                          paddingY: 0.5,
                          borderRadius: '16px',
                          display: 'flex',
                        }}
                        variant="ghost"
                        color={
                          (projectData.projetostatus === -1 && 'default') ||
                          (projectData.projetostatus === 0 && 'default') ||
                          (projectData.projetostatus === 1 && 'info') ||
                          (projectData.projetostatus === 2 && 'error') ||
                          (projectData.projetostatus === 3 && 'success') ||
                          (projectData.projetostatus === 4 && 'error') ||
                          (projectData.projetostatus === 5 && 'warning')
                        }
                      >
                        {(projectData.projetostatus === -1 && t('projects.project-status.no-flow')) ||
                          (projectData.projetostatus === 0 && t('projects.project-status.not-inicialized')) ||
                          (projectData.projetostatus === 1 && t('projects.project-status.in-progress')) ||
                          (projectData.projetostatus === 2 && t('projects.project-status.overdue')) ||
                          (projectData.projetostatus === 3 && t('projects.project-status.concluded')) ||
                          (projectData.projetostatus === 4 && t('projects.project-status.canceled')) ||
                          (projectData.projetostatus === 5 && t('projects.project-status.paused'))}
                      </Label>
                    </Box>
                  )}
                />
                {!projectData.projectTag && (
                  <TextField
                    id="Projeto sem Tag"
                    value="Este Projeto não possui Tag"
                    label="Tag"
                    variant="outlined"
                    color="primary"
                    multiline
                    maxRows={2}
                    inputProps={{ readOnly: true }}
                    sx={{ width: '100%' }}
                  />
                )}
                {projectData.projectTag && (
                  <Autocomplete
                    noOptionsText={t('products.modals.add-products.no-tags')}
                    id="Tag do Projeto"
                    readOnly
                    sx={{ width: '100%', '.MuiAutocomplete-endAdornment': { display: 'none' } }}
                    defaultValue={projectData.projectTag?.tagnome}
                    options={[]}
                    renderInput={(params) => (
                      <Box sx={{ position: 'relative' }}>
                        <TextField
                          {...params}
                          variant="outlined"
                          color="primary"
                          label="Tag"
                          value={projectData.projectTag.tagnome}
                        />

                        <TagBox
                          key={projectData.projectTag.idtag}
                          sx={{
                            backgroundColor: projectData.projectTag.tagcolor,
                            marginLeft: '12px',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            paddingX: 1,
                            paddingY: 0.5,
                            borderRadius: '16px',
                            display: 'flex',
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: 'center',
                              color: '#fff',
                              textShadow: '1px 1px 1px #000',
                            }}
                          >
                            {projectData.projectTag.tagnome}
                          </Typography>
                        </TagBox>
                      </Box>
                    )}
                  />
                )}
              </StyledRowBox>
              <StyledRowBox>
                <TextField
                  id="Áreas relacionadas"
                  value={projectData.areas !== '' ? projectData.areas : t('projects.project-details.no-areas')}
                  label={t('projects.project-details.areas')}
                  variant="outlined"
                  color="primary"
                  multiline
                  maxRows={2}
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
              </StyledRowBox>
              <StyledRowBox>
                <TextField
                  id="Usuários relacionados"
                  value={projectData.users !== '' ? projectData.users : t('projects.project-details.no-users')}
                  label={t('projects.project-details.users')}
                  variant="outlined"
                  color="primary"
                  multiline
                  maxRows={2}
                  inputProps={{ readOnly: true }}
                  sx={{ width: '100%' }}
                />
              </StyledRowBox>

              {projectData.projetobase ? (
                <ButtonsBox id="Botão de fluxo">
                  <Box>
                    {editProject(
                      projectData.idprojeto,
                      projectData.projetoiniciador,
                      projectData.projetostatus,
                      projectData.projetobase,
                      projectData
                    )}
                    {flow(projectData)}
                    {startProject(projectData)}
                    {cancelProject(projectData)}
                  </Box>
                  <Box>{checkAdminOrInicializador()}</Box>
                </ButtonsBox>
              ) : null}
            </StyledOutterBox>
            {/* 

PRODUTO

*/}
            {!projectData.projetobase && (
              <StyledOutterBox
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                id="Box pai de Produto"
              >
                <Box>
                  <Typography variant="h5" textAlign="center">
                    {t('projects.project-details.product-table-heading')}
                  </Typography>
                  {!projectData.product && (
                    <StyledRowBox>
                      <TextField
                        id="Sem Produto"
                        value={t('projects.project-details.no-product')}
                        variant="outlined"
                        color="primary"
                        inputProps={{ readOnly: true }}
                        sx={{ width: '100%' }}
                      />
                    </StyledRowBox>
                  )}
                  {projectData.product && (
                    <>
                      <StyledRowBox>
                        <TextField
                          id="Nome do produto"
                          value={projectData.product.produtonome}
                          label={t('projects.project-details.product-name')}
                          variant="outlined"
                          color="primary"
                          inputProps={{ readOnly: true }}
                          sx={{ width: '100%' }}
                        />
                        <TextField
                          id="Código do produto"
                          value={projectData.product.produtocodigo}
                          label={t('projects.project-details.product-code')}
                          variant="outlined"
                          color="primary"
                          inputProps={{ readOnly: true }}
                          sx={{ width: '100%' }}
                        />
                      </StyledRowBox>
                      <StyledRowBox>
                        <Autocomplete
                          id="Tag do Produto"
                          readOnly
                          sx={{ width: '100%', '.MuiAutocomplete-endAdornment': { display: 'none' } }}
                          defaultValue=" "
                          options={[projectData.product.productTag, ' ']}
                          renderInput={(params) => (
                            <Box sx={{ position: 'relative' }}>
                              <TextField
                                {...params}
                                variant="outlined"
                                color="primary"
                                label="Tag"
                                value={projectData.product.productTag.tagnome}
                              />

                              <TagBox
                                key={projectData.product.productTag.idtag}
                                sx={{
                                  backgroundColor: projectData.product.productTag?.tagcolor,
                                  marginLeft: '12px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  position: 'absolute',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  paddingX: 1,
                                  paddingY: 0.5,
                                  borderRadius: '16px',
                                }}
                              >
                                <Typography
                                  sx={{
                                    textAlign: 'center',
                                    color: '#fff',
                                    textShadow: '1px 1px 1px #000',
                                  }}
                                >
                                  {projectData.product.productTag.tagnome}
                                </Typography>
                              </TagBox>
                            </Box>
                          )}
                        />
                      </StyledRowBox>
                      <StyledRowBox>
                        <Autocomplete
                          defaultValue=" "
                          readOnly
                          sx={{
                            width: '100%',
                            '.MuiAutocomplete-endAdornment': { display: 'none' },
                            position: 'relative',
                          }}
                          id="tag-sku"
                          options={[...projectData.product.tags, ' ']}
                          renderInput={(params) => (
                            <>
                              <TextField multiline {...params} label="Tag-SKU" />
                              <Box sx={{ display: 'flex', position: 'absolute', bottom: 15, left: 8 }}>
                                {!projectData.product.tags.length && (
                                  <Typography ml={1.6}>{t('projects.project-details.no-tags-sku')}</Typography>
                                )}
                                {projectData.product.tags.map((tagSKU) => (
                                  <TagBox
                                    sx={{
                                      backgroundColor: tagSKU.tagcolor,
                                      paddingX: 1,
                                      paddingY: 0.5,
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    key={tagSKU.idtag}
                                  >
                                    <Typography
                                      sx={{
                                        textAlign: 'center',
                                        color: '#fff',
                                        textShadow: '1px 1px 1px #000',
                                      }}
                                    >
                                      {tagSKU.tagnome}
                                    </Typography>
                                  </TagBox>
                                ))}
                              </Box>
                            </>
                          )}
                        />
                      </StyledRowBox>
                    </>
                  )}
                </Box>
                {!projectData.projetobase ? (
                  <ButtonsBox id="Botão de fluxo">
                    <Box>
                      {editProject(
                        projectData.idprojeto,
                        projectData.projetoiniciador,
                        projectData.projetostatus,
                        projectData.projetobase,
                        projectData
                      )}
                      {flow(projectData)}
                      {startProject(projectData)}
                      {cancelProject(projectData)}
                    </Box>
                    <Box>{checkAdminOrInicializador()}</Box>
                  </ButtonsBox>
                ) : null}
              </StyledOutterBox>
            )}
          </>
        )}
      </StyledCard>
    </Modal>
  );
}
