import { useState, useEffect } from 'react';
import i18next from 'i18next';
// @mui
import { Container, Box } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// components
import { useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import Page from '../components/Page';
// sections
import { AppNewsUpdate, AppWidgetSummary, AppCurrentVisits } from '../sections/@dashboard/app';
// api
import api from '../service/api';
import MyJobs from '../sections/@dashboard/app/MyJobs';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [counterStatus, setCounterStatus] = useState({
    iniciado: 0,
    proxvencer: 0,
    ematraso: 0,
    finalizado: 0,
  });
  const [counterStatusArea, setCounterStatusArea] = useState({
    iniciado: [],
    proxvencer: [],
    ematraso: [],
    finalizado: [],
  });
  const [listNews, setListNews] = useState(undefined);
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const { t } = useTranslation();

  async function requestCounters() {
    try {
      const response = await api.get('/projectscounter');
      if (response.status === 200) {
        const contador = response.data.data.counterProjects;
        setCounterStatus(contador);
      } else {
        console.log('erro');
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      console.log(error);
    }
  }

  async function requestCountersArea() {
    try {
      const response = await api.get('/projectscounterarea');
      // eslint-disable-next-line prefer-destructuring

      const colors = [
        '#e7363a',
        '#b57102',
        '#7371fc',
        '#4f772d',
        '#457b9d',
        '#d84727',
        '#390099',
        '#2d86a1',
        '#ee4266',
        '#6c584c',
        '#C9D708',
        '#810478',
        '#90785D',
        '#FF8060',
        '#E84D60',
        '#536040',
        '#9e67bd',
        '#860713',
        '#E6CDCF',
        '#1ebbd7',
        '#71c7ec',
        '#005073',
        '#fa7e1e',
        '#feda75',
      ];

      const { counterProjectsArea } = response.data.data;
      const allLabelsAndColors = [];
      const existingLabels = {};

      Object.values(counterProjectsArea).forEach((projectArea) => {
        projectArea.forEach((project, index) => {
          if (!existingLabels[project.label]) {
            allLabelsAndColors.push({ label: project.label, color: colors[index] });
            existingLabels[project.label] = true;
          }
        });
      });

      if (response.status === 200) {
        Object.values(counterProjectsArea).forEach((projectArea) => {
          allLabelsAndColors.forEach((labelAndColor) => {
            projectArea.forEach((project) => {
              if (project.label === labelAndColor.label) project.color = labelAndColor.color;
            });
          });
        });

        setCounterStatusArea(counterProjectsArea);
      } else {
        console.log('erro');
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
      console.log(error);
    }
  }
  async function requestNews() {
    try {
      setIsNewsLoading(true);
      const qtd = 5;
      const response = await api.get(`/projectslastnews/${qtd}`);
      // eslint-disable-next-line prefer-destructuring
      if (response.status === 200) {
        setIsNewsLoading(false);
        const news = response.data.data.counterProjectsLastNews;
        setListNews(news);
      } else {
        setIsNewsLoading(false);
        console.log('erro');
      }
    } catch (error) {
      setIsNewsLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
  }

  async function requestMyJobs() {
    try {
      const response = await api.get('/projectsmyjobspaginado/3/0');
      return response.data.data.myjobs;
    } catch (error) {
      toast.error(t('my-jobs.error'));
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${t('not-Access')}`);
      } else {
        toast.error(`${error.response.data.data.errors[0]}`);
      }
    }
    return null;
  }

  useEffect(() => {
    requestCounters();
    requestCountersArea();
    requestNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: jobs, isLoading } = useQuery(['myjobsDash'], requestMyJobs, {
    refetchInterval: 1000 * 60 * 5,
  });

  return (
    <Page title={t('sidebar.home')}>
      <Container
        sx={{
          position: 'absolute',
          left: 60,
          minWidth: '96%',
          top: 85,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1.5,
            justifyContent: 'space-between',
            flexWrap: { md: 'wrap', xl: 'nowrap' },
          }}
        >
          <Box sx={{ width: { md: '49%', xl: '25%' } }}>
            <AppWidgetSummary title={t('home.status.start')} color="iniciado" total={+counterStatus.iniciado} />
            <AppCurrentVisits
              title={i18next.t('dashboardProject.startedProjects')}
              chartData={counterStatusArea.iniciado}
              status={1}
            />
          </Box>

          <Box sx={{ width: { md: '49%', xl: '25%' } }}>
            <AppWidgetSummary
              title={t('home.status.close-expiration')}
              color="proximo"
              total={+counterStatus.proxvencer}
            />
            <AppCurrentVisits
              title={i18next.t('dashboardProject.expirationProjects')}
              chartData={counterStatusArea.proxvencer}
              status={2}
            />
          </Box>

          <Box sx={{ width: { md: '49%', xl: '25%' } }}>
            <AppWidgetSummary title={t('home.status.overdue')} color="atrasado" total={+counterStatus.ematraso} />
            <AppCurrentVisits
              title={i18next.t('dashboardProject.overdueProjects')}
              chartData={counterStatusArea.ematraso}
              status={3}
            />
          </Box>

          <Box sx={{ width: { md: '49%', xl: '25%' } }}>
            <AppWidgetSummary title={t('home.status.finished')} color="finalizado" total={+counterStatus.finalizado} />
            <AppCurrentVisits
              title={i18next.t('dashboardProject.finishedProjects')}
              chartData={counterStatusArea.finalizado}
              status={4}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1.5, justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ width: '49%' }}>
            <AppNewsUpdate isLoading={isNewsLoading} title={t('home.update.title')} list={listNews} />
          </Box>
          <Box sx={{ width: '49%' }}>
            <MyJobs isLoading={isLoading} jobs={jobs} />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
