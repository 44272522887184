import { useState } from 'react';
// layputs
import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
// components
import Page from '../components/Page';
import HorizontalFlow from '../components/ReactFlow/creation/Flow';

// ----------------------------------------------------------------------

export default function Workflow() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <Page title="Products">
      <DashboardNavbar onOpenSidebar={() => setSideBarOpen(true)} />
      <DashboardSidebar isOpenSidebar={sideBarOpen} onCloseSidebar={() => setSideBarOpen(false)} navigateBack />
      <HorizontalFlow />
    </Page>
  );
}
