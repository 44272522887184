import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import i18next from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Icon } from '@iconify/react';
import arrowBackIcon from '@iconify/icons-material-symbols/arrow-back-rounded';
import { Box } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';
import toast from 'react-hot-toast';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import { useQueryClient } from 'react-query';
import { checkIfFileIsImage } from '../../../../../utils/checkIfFileIsImage';
import FileComponent from './FileComponent';
import AnnexForModal from './AnnexForModal';
import { NodeSelectTimeline } from '../BasicModalTimeline';
import { checkTypeFile } from '../../../utils/checkTypeFile';
import api, { baseURLFile } from '../../../../../service/api';
import ModalViewAnnex from '../../../../ModalViewAnnex';
import { userStore } from '../../../../../store/userStore';

export default function ModalSubstituicao() {
  const [open, setOpen] = useState(false);
  const [path, setpath] = useState('');
  const [imageFilename, setImageFilename] = useState();
  const [openModalPdf, setModalPdf] = useState(false);
  // const [projectAnnexCache?, setImagens] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [directory, setDirectory] = useState(null);
  const [repoFolder, setRepoFolder] = useState([]);
  const [time, setTime] = useState('');
  const [folderItems, setFolderItems] = useState([]);
  const [navigatedDirectory, setNavigatedDirectory] = useState([]);
  const [filesSelectedRepo, setFilesSelectedRepo] = useState([]);
  const [annexSelected, setAnnexSelected] = useState([]);
  const [idModule, setIdModule] = useState(undefined);
  const [idModuleAnnex, setIdModuleAnnex] = useState(undefined);
  const getUserPermissions = userStore((state) => state.getUser);
  const [roleUser, setRoleUser] = useState('');
  const User = useRef();

  const getUser = async () => {
    const userPermissions = await getUserPermissions();
    User.current = userPermissions;
    const admin = userPermissions.roles.some((role) => role.perfilnome === 'Administrador');
    setRoleUser(admin);
  };

  const nodeSelectTimeline = useContext(NodeSelectTimeline);
  const { idprojeto, project } = nodeSelectTimeline;

  const queryClient = useQueryClient();
  const projectAnnexCache = queryClient.getQueryData(['projectAnnex', idprojeto]);

  // const currentUser = JSON.parse(localStorage.getItem('User'));

  let timer = 0;

  const delay = 200;

  let prevent = false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    const emptyArr = [];
    setCheckedState(new Array(projectAnnexCache?.length).fill(false));
    setFilesSelectedRepo(emptyArr);
    setAnnexSelected(emptyArr);
    setOpen(false);
    setTime('');
  };

  const requestRepo = async () => {
    try {
      const response = await api.get(`repository/product/${project.product.idproduto}`);
      // const response = await api.get(`repository/product/20`);

      setRepoFolder(response.data.data.productRepository);
      setDirectory(response.data.data.productRepository.path);
      setNavigatedDirectory([]);
      setNavigatedDirectory((prevState) => [...prevState, response.data.data.productRepository.path]);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        // toast.error(`${error.response.data.data.errors[0]}`);
        toast.error(`${i18next.t('not-Access')}`);
      } else {
        toast.error(i18next.t('annexToast.erroRequest'));
      }
    }
  };

  const flattenFolderItems = (folderObj) => {
    folderObj.children?.forEach((child) => {
      setFolderItems((prevState) => [...prevState, child]);

      if (child.children) flattenFolderItems(child);
    });
  };

  useEffect(() => {
    setFolderItems([]);
    folderItems.push(flattenFolderItems(repoFolder));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repoFolder, directory]);

  const testFile = (image) => {
    if (checkIfFileIsImage(image.moduloanexocaminho)) return `${baseURLFile}/${image.pathfile}`;
    return checkTypeFile(image.pathfile);
    // return doc;
  };

  const onSingleClickHandler = (position) => {
    timer = setTimeout(() => {
      if (!prevent) {
        const updateCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
        setCheckedState(updateCheckedState);
      }
    }, delay);
  };

  // eslint-disable-next-line no-unused-vars
  const onDoubleClickHandler = (selectedDocument) => {
    clearTimeout(timer);
    prevent = true;

    setIdModule(selectedDocument.idmodulo);
    setIdModuleAnnex(selectedDocument.idmoduloanexo);
    const baseUrlAndPathfile = `${`/${selectedDocument.moduloanexocaminhopdf}`}`;
    toast.dismiss();
    setModalPdf(true);
    setpath(baseUrlAndPathfile);
    setImageFilename(selectedDocument.moduloanexonome);
    setTimeout(() => {
      prevent = false;
    }, delay);
  };

  useEffect(() => {
    requestRepo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateBackHandler = () => {
    setDirectory(navigatedDirectory[navigatedDirectory.indexOf(directory) - 1]);
  };

  useEffect(() => {
    setCheckedState(new Array(projectAnnexCache?.length).fill(false));
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAnnexCache?.length]);

  // eslint-disable-next-line consistent-return
  const checkUserPriv = (arrPrivUser) => {
    const checkUser = arrPrivUser.some((user) => user === User.current?.idusuario);
    if (!checkUser) {
      return false;
    }
    if (checkUser) {
      return true;
    }
  };

  const handleTime = (timeSelected) => {
    const date = timeSelected;
    const dateCurrent = new Date().toJSON().slice(0, 10);
    const dateInMs = new Date(date) - new Date(dateCurrent);
    const dateInDays = dateInMs / (1000 * 60 * 60 * 24);

    if (dateInDays >= 0) {
      setTime(timeSelected);
    } else {
      setTime('');
      toast.error(i18next.t('dateValid.notValidDate'));
    }
  };

  const selectFilesToSend = async () => {
    if (time.length === 0) toast.error(i18next.t('flow-Modals-replace.toast-noDate'));
    if (annexSelected.length > 0 && filesSelectedRepo.length > 0 && time.length > 0) {
      try {
        const response = await api.put(`/modules/subs/${idprojeto}`, {
          annexs: annexSelected,
          repositories: filesSelectedRepo,
          dtvalidity: time,
        });

        if (response.status === 200 || response.status === 201) {
          toast.success(i18next.t('flow-Modals-replace.toast-success'));
        }
        handleClickClose();
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          // toast.error(`${error.response.data.data.errors[0]}`);
          toast.error(`${i18next.t('not-Access')}`);
        } else {
          toast.error(i18next.t('flow-Modals-replace.toast-fail'));
        }
      }
    }
    if (annexSelected.length > 0 && filesSelectedRepo.length === 0 && time.length > 0) {
      try {
        const response = await api.put(`/modules/subs/${idprojeto}`, {
          annexs: annexSelected,
          repositories: [],
          dtvalidity: time,
        });

        if (response.status === 200 || response.status === 201) {
          toast.success(i18next.t('flow-Modals-replace.toast-success'));
        }
        handleClickClose();
      } catch (error) {
        console.log(error);
        if (error.response.status === 401) {
          // toast.error(`${error.response.data.data.errors[0]}`);
          toast.error(`${i18next.t('not-Access')}`);
        } else {
          toast.error(i18next.t('flow-Modals-replace.toast-fail'));
        }
      }
    }
  };

  const checkFileIsSelected = (newfile) => filesSelectedRepo.some((file) => file.name === newfile.name);

  const fileSelectedHandle = (newfile) => {
    const newArrayFileSelected = [...filesSelectedRepo];
    if (newfile.type === 'file') {
      if (!checkFileIsSelected(newfile)) {
        setFilesSelectedRepo((array) => array.concat(newfile));
      }
      if (checkFileIsSelected(newfile)) {
        const indexFile = newArrayFileSelected.findIndex((file) => file.name === newfile.name);
        newArrayFileSelected.splice(indexFile, 1);
        setFilesSelectedRepo(newArrayFileSelected);
      }
    }
  };

  const checkAnnexIsSelected = (newfile) => annexSelected.some((file) => file.idmoduloanexo === newfile.idmoduloanexo);

  const annexSelectedHandle = (newfile) => {
    const newArrayFileSelected = [...annexSelected];

    if (!checkAnnexIsSelected(newfile)) {
      setAnnexSelected((array) => array.concat(newfile));
    }
    if (checkAnnexIsSelected(newfile)) {
      const indexFile = newArrayFileSelected.findIndex((file) => file.idmoduloanexo === newfile.idmoduloanexo);
      newArrayFileSelected.splice(indexFile, 1);
      setAnnexSelected(newArrayFileSelected);
    }
  };

  const checkNumberOfFilesSelected = (newfile) => {
    const checkListTrueFiles = checkedState.filter((check) => check === true);
    if (
      (checkListTrueFiles.length > filesSelectedRepo.length && newfile.status === 'V') ||
      checkFileIsSelected(newfile)
    ) {
      fileSelectedHandle(newfile);
    }
    if (
      checkListTrueFiles.length <= filesSelectedRepo.length &&
      !checkFileIsSelected(newfile) &&
      newfile.type === 'file'
    ) {
      toast.error(i18next.t('flow-Modals-replace.toast-selected-fail'));
    }
    if (newfile.status === 'O' || newfile.status === 'P') {
      toast.error(i18next.t('flow-Modals-replace.noValidity-selected'));
    }
  };

  const removeLastFileSelected = () => {
    if (annexSelected.length < filesSelectedRepo.length) {
      filesSelectedRepo.pop();
    }
  };

  const checkItemInArrayAndUpdateCountBagde = (item) => {
    if (filesSelectedRepo.some((file) => file.name === item.name)) {
      return filesSelectedRepo.indexOf(item) + 1;
    }
    return 0;
  };
  const checkAnnexInArrayAndUpdateCountBagde = (item) => {
    if (annexSelected.some((file) => file.name === item.name)) {
      return annexSelected.indexOf(item) + 1;
    }
    return 0;
  };

  useEffect(() => {
    removeLastFileSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesSelectedRepo.length, annexSelected]);

  return (
    <Box sx={{ width: 1200 }}>
      <Tooltip title={i18next.t('flow-Modals-replace.tooltip')}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ marginTop: 2, width: 50, height: 50, backgroundColor: 'background.neutral', color: 'text.secondary' }}
        >
          <Icon icon="mdi:file-exchange" fontSize={60} />
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClickClose}
        maxWidth="1400px"
        fullWidth
        sx={{ margin: '0 auto', width: '100%' }}
      >
        <>
          <IconButton
            onClick={handleClickClose}
            id="Botão fechar modal"
            sx={{ position: 'absolute', top: 3, right: 3, '& :hover': { cursor: 'pointer' }, zIndex: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle>{i18next.t('flow-Modals-replace.title')}</DialogTitle>
          <DialogContent sx={{ overflow: 'hidden' }}>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '50%' }}>
                <Typography variant="h3">{i18next.t('flow-Modals-replace.titleFile')}</Typography>
                <FormGroup
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    height: 300,
                    gap: '2rem',
                    overflowY: 'scroll',
                  }}
                >
                  {useMemo(
                    () =>
                      projectAnnexCache?.map((imagem, index) => {
                        if (
                          // true
                          checkUserPriv(imagem.moduloanexoprivacidade.userslinked) ||
                          imagem.moduloanexoprivacidade.userslinked.length <= 0 ||
                          project.projetoiniciador.idusuario === User.current?.idusuario ||
                          roleUser
                        ) {
                          return (
                            <AnnexForModal
                              key={`annex-${index}`}
                              imagem={imagem}
                              index={index}
                              checkedState={checkedState}
                              onSingleClickHandler={onSingleClickHandler}
                              onDoubleClick={onDoubleClickHandler}
                              annexSelectedHandle={annexSelectedHandle}
                              checkAnnexInArrayAndUpdateCountBagde={checkAnnexInArrayAndUpdateCountBagde}
                              testFile={testFile}
                            />
                          );
                        }
                        return <></>;
                      }),
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [checkedState, projectAnnexCache]
                  )}
                </FormGroup>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  height: '100%',
                  marginLeft: '1rem',
                  minWidth: '50%',
                }}
              >
                <Typography variant="h3">{i18next.t('flow-Modals-replace.titleRepo')}</Typography>
                <Tooltip title={i18next.t('flow-Modals-replace.tooltipBack')} disableInteractive>
                  <span>
                    <IconButton
                      color="greyButton"
                      disabled={navigatedDirectory.length <= 1 || directory === 'products' || !directory}
                      onClick={navigateBackHandler}
                      sx={{ height: '100%', width: 50, borderRadius: 0 }}
                    >
                      <Icon icon={arrowBackIcon} sx={{ fontSize: 100 }} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    height: 300,
                    // gap: '1rem',
                    overflowY: 'scroll',
                  }}
                >
                  {useMemo(
                    () =>
                      folderItems &&
                      folderItems.map((item, index) => (
                        <Badge
                          badgeContent={checkItemInArrayAndUpdateCountBagde(item)}
                          color="primary"
                          key={`badge-folderitems-${index}`}
                          sx={{ marginTop: 2, marginRight: 1 }}
                        >
                          <FileComponent
                            key={index}
                            item={item}
                            setDirectory={setDirectory}
                            directory={directory}
                            setNavigatedDirectory={setNavigatedDirectory}
                            fileSelectedHandle={checkNumberOfFilesSelected}
                            checkFileIsSelected={checkFileIsSelected}
                          />
                        </Badge>
                      )),
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [folderItems, checkFileIsSelected, checkItemInArrayAndUpdateCountBagde]
                    // [folderItems]
                  )}

                  {folderItems.length === 0 && (
                    <Typography variant="h3">{i18next.t('flow-Modals-replace.date')}</Typography>
                  )}
                </Box>
                <Box>
                  <Typography variant="h5">{i18next.t('flow-Modals-time.text')}</Typography>
                  <TextField
                    key={`textfield-date`}
                    id={'date-id'}
                    label=""
                    margin="dense"
                    required
                    type="date"
                    value={time}
                    onChange={(event) => handleTime(event.target.value)}
                  />
                </Box>
                <ModalViewAnnex
                  path={path}
                  openModalPdf={openModalPdf}
                  setModalPdf={setModalPdf}
                  filename={imageFilename}
                  id={imageFilename}
                  idModule={idModule}
                  idModuleAnnex={idModuleAnnex}
                />
              </Box>
            </Box>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {i18next.t('flow-Modals-replace.helpText')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClickClose}>
              {i18next.t('flow-Modals.exit')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                selectFilesToSend();
              }}
              color="success"
            >
              {i18next.t('flow-Modals-choiceFiles.send')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </Box>
  );
}
