import { useTheme } from '@emotion/react';
import { Button, ListItem, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import DetectableOverflow from 'react-detectable-overflow';
import { Icon } from '@iconify/react';
import folderIcon from '@iconify/icons-material-symbols/folder';

export default function RepositoryProductField({ product, productClickHandler, selectedProduct }) {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const theme = useTheme();

  return (
    <ListItem key={product.idproduto} sx={{ padding: 0 }}>
      <Tooltip title={isOverflowing && product.produtonome} disableInteractive PopperProps={{ disablePortal: true }}>
        <Button
          startIcon={<Icon icon={folderIcon} />}
          color="greyButton"
          onClick={() => {
            productClickHandler(product.idproduto);
          }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            borderRadius: 0,
            backgroundColor: selectedProduct === product.idproduto && theme.palette.grey[400],
          }}
        >
          <Typography component="span" noWrap sx={{ textAlign: 'start', textTransform: 'none' }}>
            <DetectableOverflow onChange={() => setIsOverflowing(true)}>{product.produtonome}</DetectableOverflow>
          </Typography>
        </Button>
      </Tooltip>
    </ListItem>
  );
}
