/* eslint-disable no-restricted-syntax */
import i18next from 'i18next';
import toast from 'react-hot-toast';
import integrationsAPI from './functionsIntegrationAPI';
import api from '../../../service/api';

const executeValidateFlow = async (nodes, edges, idprojeto) => {
  const arrChecked = [];

  const customNodes = nodes.filter((nod) => nod.type === 'customnode');
  const actionNodes = customNodes.filter((nod) => nod.categoryNode === 'ACAO');
  const alertNodes = customNodes.filter((nod) => nod.typeNode === 'ALERTA');
  const emailNodes = customNodes.filter((nod) => nod.typeNode === 'EMAIL');
  const tagNodes = customNodes.filter((nod) => nod.typeNode === 'TAG');
  const timeNodes = customNodes.filter((nod) => nod.typeNode === 'TEMPO');
  const unionNodes = customNodes.filter((nod) => nod.typeNode === 'UNIAO');
  const repoNodes = customNodes.filter((nod) => nod.typeNode === 'REPO');
  const formNodes = customNodes.filter((nod) => nod.typeNode === 'FORM');

  arrChecked.push(await checkResponsible(actionNodes));
  arrChecked.push(await checkAlertModule(alertNodes));
  arrChecked.push(await checkEmailModule(emailNodes));
  arrChecked.push(await checkTagModule(tagNodes));
  arrChecked.push(await checkTimeModule(timeNodes));
  arrChecked.push(await checkUnionModule(unionNodes, idprojeto));
  arrChecked.push(await checkConnectionsModules(customNodes, edges));
  arrChecked.push(await checkConnectionUnion(customNodes, edges));
  arrChecked.push(await checkPrevRepositoryModule(repoNodes, idprojeto));
  arrChecked.push(await checkModuleForm(formNodes, idprojeto));

  return arrChecked.every((item) => item === true);
};

const checkResponsible = async (actionNodes) => {
  for await (const node of actionNodes) {
    let responsible = node.moduloresponsaveis;
    const stage = node.parentNode;
    if (responsible === undefined) {
      toast.error(`${i18next.t('flow-workflow.without-responsible', { stage })}`, {
        duration: 5000,
      });
      return false;
    }
    if (typeof responsible !== 'object') {
      responsible = JSON.parse(responsible);
    }
    if (responsible.area.length <= 0 && responsible.usuario.length <= 0) {
      toast.error(`${i18next.t('flow-workflow.without-responsible', { stage })}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const checkAlertModule = async (alertNodes) => {
  for await (const node of alertNodes) {
    const { properties } = node;
    if (properties === undefined || properties === null) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-alert')}`, {
        duration: 5000,
      });
      return false;
    }
    if (properties.ALERTA === undefined) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-alert')}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const checkEmailModule = async (emailNodes) => {
  for await (const node of emailNodes) {
    const { properties } = node;
    if (properties === undefined || properties === null) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-email')}`, {
        duration: 5000,
      });
      return false;
    }
    if (properties.EMAIL === undefined) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-email')}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const checkTagModule = async (tagNodes) => {
  for await (const node of tagNodes) {
    const { properties } = node;
    if (properties === undefined || properties === null) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-tag')}`, {
        duration: 5000,
      });
      return false;
    }
    if (properties.TAG === undefined) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-tag')}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const checkTimeModule = async (timeNodes) => {
  for await (const node of timeNodes) {
    const { properties } = node;
    if (properties === undefined || properties === null) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-time')}`, {
        duration: 5000,
      });
      return false;
    }
    if (properties.TEMPO === undefined) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-time')}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const checkUnionModule = async (unionNodes, idprojeto) => {
  const unionPropsAnd = { UNIAO: { logic: 'AND' } };
  unionNodes.forEach(async (node) => {
    const { properties } = node;
    if (properties === undefined || properties === null) {
      node.properties = unionPropsAnd;
      await integrationsAPI.updateModule(idprojeto, node);
    }
  });
  return true;
};

const checkConnectionsModules = async (customNodes, edges) => {
  for await (const node of customNodes) {
    const stage = node.parentNode;
    if (node.id !== 'a') {
      const edgeNode = edges.find((edg) => edg.target === node.id);
      if (edgeNode === undefined) {
        toast.error(`${i18next.t('flow-workflow.invalid-module-connection', { stage })}`, {
          duration: 5000,
        });
        return false;
      }
    }
  }
  return true;
};

const checkConnectionUnion = async (customNodes, edges) => {
  for await (const node of customNodes) {
    if (node.id !== 'a') {
      const edgeNodes = edges.filter((edg) => edg.target === node.id);
      if (edgeNodes.length > 1) {
        if (node.typeNode !== 'UNIAO') {
          toast.error(`${i18next.t('flow-workflow.invalid-connection-union')}`, {
            duration: 5000,
          });
          return false;
        }
      }
    }
  }
  return true;
};

const checkPrevRepositoryModule = async (repoNodes, idprojeto) => {
  for await (const nodeRepo of repoNodes) {
    const response = await api.get(`modulesreference/${nodeRepo.id}/${idprojeto}`);
    const nodesBranchs = response.data.data.modules;
    const annexNodes = nodesBranchs.find((nod) => nod.modulotipo === 'ANEXO');
    if (annexNodes === undefined) {
      toast.error(`${i18next.t('flow-workflow.invalid-prev-repository-module')}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const checkModuleForm = async (formNodes, idprojeto) => {
  for await (const node of formNodes) {
    const responseProject = await api.get(`modules/form/${node.id}/${idprojeto}`);
    const { form } = responseProject.data.data;
    if (form === null || !form.form) {
      toast.error(`${i18next.t('flow-workflow.invalid-module-form')}`, {
        duration: 5000,
      });
      return false;
    }
  }
  return true;
};

const validateFlow = {
  checkResponsible,
  checkAlertModule,
  checkEmailModule,
  checkTagModule,
  checkTimeModule,
  checkUnionModule,
  checkConnectionsModules,
  checkConnectionUnion,
  checkPrevRepositoryModule,
  executeValidateFlow,
  checkModuleForm,
};

export default validateFlow;
